import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@material-ui/core';
import { SettingsTabPanel } from '..';
import { AdminPerformanceFeedback } from './AdminPerformanceFeedback';
import { AdminPerformanceImprovementPlan } from './AdminPerformanceImprovementPlan';
import { GiveFeedbackIcon } from '../../../../config/svg/PersonalFeedbackSvg';
import { PipIcon, ThreeSixtyExperienceIcon } from '../../../../config/svg/CommonSvgIcon';
import '../../../../styles/pages/admin/settings/adminPerformanceDevelopment.scss';
import LinearLoader from '../../../Common/Loader';
import { AdminThreeSixtyExperience } from './AdminThreeSixtyExperience';

export const AdminPerformanceDevelopment: React.FC<any> = (props: any) => {
	const { tabSelected, a11yProps, handleTabChange } = props;
	const { t } = useTranslation();

	const [loader, setLoader] = useState<any>(false);

	const resetTabDetailsAndChange = (event: any, newValue: Number) => {
		handleTabChange(event, newValue);
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}

			<Box className='performance-development-area'>
				<Box className='admin-tabpanel-inner'>
					<Box className='admin-tabpanel-head'>
						<Box className='admin-tabpanel-head-left'>
							<Tabs
								className='okr-tab-button'
								value={tabSelected}
								onChange={resetTabDetailsAndChange}
								aria-label='Admin Tabs'
							>
								<Tab icon={<GiveFeedbackIcon />} label={t('feedbackLabel')} value={0} {...a11yProps(0)}></Tab>
								<Tab icon={<PipIcon />} label={t('prsImprovementPlan')} value={1} {...a11yProps(1)}></Tab>
								<Tab
									icon={<ThreeSixtyExperienceIcon />}
									label={<span dangerouslySetInnerHTML={{ __html: t('threeSixetyExperience') }}></span>}
									value={2}
									{...a11yProps(2)}
								></Tab>
							</Tabs>
						</Box>
						<Box className='admin-tabpanel-head-right'></Box>
					</Box>
					<SettingsTabPanel value={tabSelected} index={0}>
						<AdminPerformanceFeedback loader={loader} setLoader={setLoader} />
					</SettingsTabPanel>
					<SettingsTabPanel value={tabSelected} index={1}>
						<AdminPerformanceImprovementPlan loader={loader} setLoader={setLoader} />
					</SettingsTabPanel>
					<SettingsTabPanel value={tabSelected} index={2}>
						<AdminThreeSixtyExperience loader={loader} setLoader={setLoader} />
					</SettingsTabPanel>
				</Box>
			</Box>
		</>
	);
};
