import React, { useEffect, useRef, useState } from 'react';
import {
	Avatar,
	Box,
	Chip,
	Link,
	Tooltip,
	Typography,
	InputAdornment,
	Popper,
	Paper,
	ClickAwayListener,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { CalendarIcon, KpiIcon, ReportsTooltipIcon } from '../../../config/svg/CommonSvgIcon';
import '../../../styles/pages/user-goals/user-goals-listing.scss';
import '../../../styles/pages/user-goals/teams-goals-card.scss';
import { AlertInfoIcon } from '../../../config/svg/CommonSvg';
import {
	getSelfReviewList,
	saveGoalReviewSavedApi,
	setPerfomanceGoalAttachmentCount,
} from '../../../action/adminSettings';
import {
	checkUnSavePopUpVisible,
	formatFeedbackDate,
	getMonthDateYearTime,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../../config/enums';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import YetToBeginImage from '../../../images/assessment-yet-to-begin.svg';
import AlertDialog from '../../Common/Dialog';
import { setPerformanceGoalForRedirection } from '../../../action/common';
import { useSnackbar } from 'notistack';
import { ChangeStatusIcon, ManagerAssessmentIcon, SelfAssessmentIcon } from '../../../config/svg/AssessmentSvg';
import { ReviewGoalListing } from '../ReviewGoalListing';
import { TeamFinalRatingTabs } from '../TeamFinalRatingTabs';
import { getOkrMasterData } from '../../../action/myGoal';

export const SelfReview: React.FC<any> = (props) => {
	const { drUserData, fromTeamDetails, isDrSelfReviewData, reviewWorkFlowApprovalList } = props;

	const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);
	const { t } = useTranslation();
	const loggedInUserDetail = getUserDetails();

	const [categorySettingsOpen, setCategorySettingsOpen] = useState<any>({ open: false });
	const [currentTab, setCurrentTab] = useState<string>('');
	const dispatch = useDispatch();
	const [performanceGoalsList, setPerformanceGoalsList] = useState<any>({});
	const [expandedGoalList, setExpandedGoalList] = useState<number[]>([]);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, isDraft: false, status: 0 });
	const { redirectedPerformanceGoalDetails } = useSelector((state: any) => state.commonReducer);
	const { enqueueSnackbar } = useSnackbar();
	const ref = useRef<any>(null);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });
	const userDetails = getUserDetails();
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const { currentGoalReviewCycle } = useSelector((state: any) => state.adminSettingReducer);
	const [openReviewApproval, setOpenReviewApproval] = useState<boolean>(false);
	const [anchorElReviewApproval, setAnchorElReviewApproval] = useState<HTMLElement | null>(null);
	const minDateValue = new Date();
	minDateValue.setFullYear(minDateValue.getFullYear() - 20);
	const maxDateValue = new Date();
	maxDateValue.setFullYear(maxDateValue.getFullYear() + 20);
	useEffect(() => {
		if (redirectedPerformanceGoalDetails) {
			setTimeout(() => {
				dispatch(setPerformanceGoalForRedirection(null));
			}, 10000);
		}
		setPerformanceGoalsList({});
		setIsDataChanged(false);
		if (!okrMasterData) {
			dispatch(getOkrMasterData());
		}
	}, []);
	useEffect(() => {
		document.addEventListener('click', handleCheckUnSaveData, true);
		return () => {
			document.removeEventListener('click', handleCheckUnSaveData, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDataChanged]);
	useEffect(() => {
		if (currentGoalReviewCycle && currentGoalReviewCycle?.performanceGoalReviewCycleId) {
			getUserPerformanceGoalsList();
		}
	}, [currentGoalReviewCycle]);
	const handleCheckUnSaveData = (event: any) => {
		// if (ref3?.current?.contains(event?.target)) {
		// 	//
		// } else {
		const id = document.getElementById('createLinkPopupDetail');
		const id2 = document.getElementById('back-to-main');
		const class1 = document.getElementsByClassName('ck-body-wrapper');
		if (
			ref.current &&
			checkUnSavePopUpVisible(event) &&
			isDataChanged &&
			((!ref.current?.contains(event.target) &&
				!id?.contains(event.target) &&
				!(class1.length > 0 && class1[0].contains(event.target))) ||
				id2?.contains(event.target))
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({
					open: true,
					type: 'unSaveChange',
					message: t('unSavedItemAlert'),
					details: null,
					event: event,
				});
			}
		} else {
		}
		// }
	};
	const handleReviewApprovalOpen = (event: any) => {
		setAnchorElReviewApproval(event.currentTarget);
		setOpenReviewApproval(true);
	};
	const handleReviewApprovalClose = () => {
		setOpenReviewApproval(false);
		setAnchorElReviewApproval(null);
	};

	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'unSaveChange') {
				await setIsDataChanged(false);
				const { target } = modalProps?.event || {};
				if (target) {
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
				// resetTabDetails(false);
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	useEffect(() => {
		//notification redirection for conversation
		if (redirectedPerformanceGoalDetails && performanceGoalsList) {
			const { tabId, cycleId, employeeId, categoryId, conversationId, conversationsReplyId } =
				redirectedPerformanceGoalDetails || {};
			if (categoryId) {
				const performanceGoalsCategory = performanceGoalsList?.goalListingFormCategories?.find(
					(item: any) => item.performanceGoalLinkedFormCategoryId === categoryId
				);
				if (performanceGoalsCategory) {
					handleSettingsDrawerOpen(null, performanceGoalsCategory, 'conversation');
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectedPerformanceGoalDetails, performanceGoalsList]);

	const handleSettingsDrawerOpen = (event: any, performanceGoalSetListingFormDetails: any, tabToOpen?: string) => {
		setCategorySettingsOpen({ open: true, data: performanceGoalSetListingFormDetails });
		dispatch(setPerfomanceGoalAttachmentCount(0));
		if (tabToOpen) {
			setCurrentTab(tabToOpen);
		}
	};

	const getUserPerformanceGoalsList = async (status = 0) => {
		try {
			setLoader(true);
			let performanceGoalCycleId =
				currentGoalReviewCycle && currentGoalReviewCycle.performanceGoalReviewCycleId
					? currentGoalReviewCycle.performanceGoalReviewCycleId
					: 0;
			let requestParam: any = `performanceGoalCycleReviewId=${performanceGoalCycleId}&employeeId=${drUserData?.employeeId}`;
			let requestParamManager: any = `performanceGoalCycleReviewId=${performanceGoalCycleId}&employeeId=${
				drUserData?.employeeId
			}&isManagerView=${drUserData?.fromManager === true ? true : false}&managerId=${
				drUserData.reportingTo
			}&isFromManagerPage=${drUserData?.fromManager === true ? true : false}`;

			const response: any = await dispatch(getSelfReviewList(fromTeamDetails ? requestParamManager : requestParam));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setPerformanceGoalsList({ ...response?.data.entity, isFinalTab: status === 1 });
				//setPerformanceGoalsList({ ...response?.data.entity, isFinalRatingDone: true, isManagerRatingDone: true });
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {}
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1 && openDeleteDialog.status !== 3) {
			saveUserGoalClick(openDeleteDialog.isDraft, openDeleteDialog.status);
		}
		if (type === 1 && openDeleteDialog.status === 3) {
			clearForm();
		}
		setOpenDeleteDialog({ open: false, isDraft: false, status: 0 });
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.scrollHeight;
			topPos = topPos ? topPos - 50 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				//window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 300);
	};
	const validateForm = (isDraft = false, status = 0) => {
		let postGoalData: any[] = [];
		let formError: boolean = false;
		let expandListCopy = [...expandedGoalList];
		let goalCopy = { ...performanceGoalsList };
		let scrollToId = '';
		goalCopy.goalListingFormCategories.map((catData: any) => {
			catData.performanceGoalSetListingFormDetails.map((goalData: any) => {
				if (
					!goalData.comment ||
					goalData.comment.trim() === '' ||
					goalData.comment.trim() === '<p></p>' ||
					goalData.comment.trim() === '<p><br></p>'
				) {
					if (!isDraft) {
						goalData.isError = true;
						formError = true;
						const elementIndex = expandListCopy.indexOf(catData.performanceGoalLinkedFormCategoryId);
						if (elementIndex === -1) {
							expandListCopy.push(catData.performanceGoalLinkedFormCategoryId);
						}
						if (scrollToId === '') {
							scrollToId = 'performanceGoalLinkedFormDetailId' + goalData.performanceGoalLinkedFormDetailId;
						}
						goalData.isCommentError = true;
						goalData.isQuestionError = true;
					}
				}
				if (!isDraft && !goalData.score) {
					goalData.isRatingError = true;
					goalData.isQuestionError = true;
					goalData.isError = true;
					formError = true;
				}
				postGoalData.push({
					performanceGoalLinkedFormDetailId: goalData.performanceGoalLinkedFormDetailId,
					performanceGoalSetId: goalData.performanceGoalSetId,
					performanceGoalSetDetailId: goalData.performanceGoalSetDetailId,
					performanceGoalReviewWorkFlowTypeId: catData.performanceGoalLinkedFormCategoryId,
					score: goalData.score ? goalData.score : 0,
					comment: String(goalData.comment),
					description: String(goalData.description),
					status: status,
					isDescriptionChanged: goalData.isDescriptionChanged || false,
					descriptionDate: goalData.descriptionDate ? formatFeedbackDate(new Date(goalData.descriptionDate)) : null,
				});
			});
		});
		if (scrollToId !== '') {
			scrollTo(scrollToId);
		}
		setExpandedGoalList(expandListCopy);
		setPerformanceGoalsList(goalCopy);
		return { formError, postGoalData };
	};
	const saveUserGoalClick = async (isDraft = false, status: number) => {
		try {
			let formData: any = validateForm(isDraft, status);
			let postGoalData: any[] = formData.postGoalData;
			let formError: boolean = formData.formError;
			const requestParams = {
				performanceGoalRatingAssessmentId: 0,
				performanceGoalReviewCycleId: currentGoalReviewCycle?.performanceGoalReviewCycleId,
				employeeId: fromTeamDetails ? drUserData?.employeeId : userDetails?.employeeId,
				performanceGoalSetId: performanceGoalsList.performanceGoalSetId,
				performanceGoalReviewWorkFlowTypeId: fromTeamDetails ? 2 : 1,
				status: isDraft ? 1 : status,
				performanceGoalRatingAssessmentDetailRequest: postGoalData,
				reportingTo: fromTeamDetails ? loggedInUserDetail.employeeId : 0,
			};
			if (!formError || isDraft) {
				const response: any = await dispatch(saveGoalReviewSavedApi(requestParams));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					setPerformanceGoalsList(response?.data.entity);
					getUserPerformanceGoalsList();
					enqueueSnackbar(response?.data?.messageList.Ratingassessment, {
						variant: 'success',
						autoHideDuration: 3000,
					});
					window.scroll({ top: 0, behavior: 'smooth' });
				} else {
					window.scroll({ top: 0, behavior: 'smooth' });
				}
				setIsDataChanged(false);
			}
		} catch (error) {}
	};

	const clearForm = () => {
		let goalCopy = { ...performanceGoalsList };
		goalCopy.goalListingFormCategories.map((catData: any) => {
			catData.performanceGoalSetListingFormDetails.map((goalData: any) => {
				goalData.comment = '';
				delete goalData.rating;
				delete goalData.score;
				goalData.isCommentError = false;
				goalData.isQuestionError = false;
				goalData.isError = false;
			});
		});
		setPerformanceGoalsList(goalCopy);
		setIsDataChanged(false);
	};
	return (
		<>
			{loader ? (
				<Box className='loading-center-global'>
					<Typography>Loading...</Typography>
				</Box>
			) : currentGoalReviewCycle === null ? (
				<Box className='welcome-content goals-review-welcome' id='performance-goals-welcome'>
					<Box textAlign='center' className='pad-container'>
						<Typography variant='h2'>{t('goalsReviewYetToBeginTitle')}</Typography>
						<Typography>{t('goalsReviewYetToBeginSubTitle')}</Typography>
						<AnimatedIcon
							className=''
							width='388'
							type='image/svg+xml'
							alt='WelcomeImage'
							imagePath={YetToBeginImage}
						/>
					</Box>
				</Box>
			) : (
				<Box className='goals-review-list-area'>
					{fromTeamDetails ? (
						<Box className='user-goal-section-head' id='user-goal-section-head-id'>
							<Box className='user-goal-section-head-left'>
								<Box className='back-to-main' id='back-to-main'>
									<Typography variant='body2' onClick={() => props.backClicked(false)}>
										<Link id='back-btn'>
											<BackArrow /> {t('back')}
										</Link>
									</Typography>
								</Box>
								<Box className='user-info v-center'>
									<Box className='user-img'>
										{drUserData?.imagePath ? (
											<Avatar
												className='avatar-default'
												alt={`${drUserData?.firstName} ${drUserData?.lastName}`}
												src={drUserData?.imagePath}
											/>
										) : (
											<Avatar className='avatar-default'>
												{getUserName({
													firstName: drUserData?.firstName,
													lastName: drUserData?.lastName,
													fullName: drUserData?.fullname,
												})}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Typography variant='h4'>
											{drUserData.firstName} {drUserData.lastName}
										</Typography>
									</Box>
								</Box>
								{performanceGoalsList?.statusName && (
									<Chip
										className={
											performanceGoalsList?.status === Enums.ZERO
												? 'default-grey-chip'
												: performanceGoalsList?.status === Enums.ONE
												? 'draft-chip'
												: performanceGoalsList?.status === Enums.TWO
												? 'lagging-chip'
												: performanceGoalsList?.status === Enums.THREE
												? 'default-grey-chip'
												: performanceGoalsList?.status === Enums.FOUR
												? 'on-track-chip'
												: ''
										}
										label={performanceGoalsList?.statusName}
									/>
								)}
								{performanceGoalsList?.isAlertMessageVisible && (
									<Box className='goals-remaining-days'>
										<AlertInfoIcon />
										<Typography variant='h6'>{performanceGoalsList?.alterMessage}</Typography>
									</Box>
								)}
							</Box>
							<Box className='user-goal-section-head-right'>
								{/*<Tooltip title={t('goalSettingDuration')} arrow>
									<Box className='user-goals-setting-duration'>
										<CalendarIcon />
										<Typography variant='h6'>{`${getMonthDateYearTime(
											performanceGoalsList?.settingDurationStartDate
										)} - ${getMonthDateYearTime(performanceGoalsList?.settingDurationEndDate)}`}</Typography>
									</Box>
								</Tooltip>*/}

								{performanceGoalsList?.goalFormName && (
									<Box className='user-goal-form-name'>
										<KpiIcon />
										<Typography variant='h6'>{performanceGoalsList?.goalFormName}</Typography>
									</Box>
								)}
							</Box>
						</Box>
					) : (
						<Box className='user-goal-section-head'>
							<Box className='user-goal-section-head-left'>
								{performanceGoalsList?.goalListingFormCategories?.length > 0 && (
									<>
										<Typography variant='h2'>{t('selfReviewLevel')}</Typography>
									</>
								)}
							</Box>
							<Box className='user-goal-section-head-right'>
								{performanceGoalsList?.settingDurationStartDate && performanceGoalsList?.settingDurationEndDate && (
									<Tooltip title={t('goalSettingDuration')} arrow>
										<Box className='user-goals-setting-duration'>
											<CalendarIcon />
											<Typography variant='h6'>{`${getMonthDateYearTime(
												performanceGoalsList?.settingDurationStartDate
											)} - ${getMonthDateYearTime(performanceGoalsList?.settingDurationEndDate)}`}</Typography>
										</Box>
									</Tooltip>
								)}
								{performanceGoalsList?.goalFormName && (
									<Box className='user-goal-form-name'>
										<KpiIcon />
										<Typography variant='h6'>{performanceGoalsList?.goalFormName}</Typography>
									</Box>
								)}
							</Box>
						</Box>
					)}
					{performanceGoalsList && performanceGoalsList?.goalListingFormCategories?.length > 0 && (
						<Box className='goals-review-stats-area' id='goals-review-stats-area'>
							<Box className='goals-review-stats-box-area'>
								<Box className='self-review-box active'>
									<Box className='goals-review-stats-box'>
										<Box className='box-outer-head'>
											<Box className='box-head'>
												<SelfAssessmentIcon />
												<Box className='box-head-right'>
													<Box className='box-head-right-inner'>
														{performanceGoalsList?.selfReviewStatus === 0 ? (
															<OKRButton className={`btn-link give-review-btn`} text={t('giveReviewLevel')} icon={''} />
														) : performanceGoalsList?.selfReviewStatus === 1 ? (
															<OKRButton
																className={`btn-link give-review-btn`}
																text={t('resumeReviewLabel')}
																icon={''}
															/>
														) : performanceGoalsList?.selfReviewStatus === 2 ? (
															<Typography variant='h2'>{t('doneLevel')}</Typography>
														) : performanceGoalsList?.selfReviewStatus === 3 ? (
															<OKRButton
																className={`btn-link give-review-btn`}
																text={t('resumeReviewLabel')}
																icon={''}
															/>
														) : (
															// <Typography variant='h2'>{performanceGoalsList?.selfReviewScore}</Typography>
															<></>
														)}
														{performanceGoalsList?.selfReviewStatus === Enums.ONE ||
															(performanceGoalsList?.selfReviewStatus === Enums.THREE && (
																<Box className='goal-review-status'>
																	<Chip
																		className={
																			performanceGoalsList?.selfReviewStatus === Enums.ONE
																				? 'draft-chip'
																				: 'lagging-chip'
																		}
																		label={performanceGoalsList.selfReviewStatusName}
																	/>
																</Box>
															))}
													</Box>
													{performanceGoalsList?.selfScoreRating && (
														<Typography variant='h6' className='font-weight-normal'>
															{performanceGoalsList?.selfScoreRating}
														</Typography>
													)}
												</Box>
											</Box>
										</Box>
										{/* <Box className='box-setting-des'>
								        <Typography variant='h6' className='font-weight-normal'>
									       {t('performsubText')}
								        </Typography>
							        </Box> */}
										<Box className='box-content'>
											<Typography variant='h5'>{t('selfReviewLevel')}</Typography>
											<Typography variant='h6'>{t('rateOnAchievementsLevel')}</Typography>
										</Box>
									</Box>
									<Box className='goals-review-stats-box'>
										<Box className='box-outer-head'>
											<Box className='box-head'>
												<ManagerAssessmentIcon />
												{fromTeamDetails ? (
													<Box className='box-head-right'>
														<Box className='box-head-right-inner'>
															{performanceGoalsList?.managerReviewStatus === 0 ? (
																<OKRButton
																	className={`btn-link give-review-btn`}
																	text={t('giveReviewLevel')}
																	icon={''}
																/>
															) : performanceGoalsList?.managerReviewStatus === 1 ? (
																<OKRButton
																	className={`btn-link give-review-btn`}
																	text={t('resumeReviewLabel')}
																	icon={''}
																/>
															) : performanceGoalsList?.managerReviewStatus === 2 ? (
																<Typography variant='h2'>{t('doneLevel')}</Typography>
															) : (
																<></>
															)}
															{performanceGoalsList?.managerReviewStatus === Enums.ONE && (
																<Box className='goal-review-status'>
																	<Chip
																		className={
																			performanceGoalsList?.managerReviewStatus === Enums.ONE ? 'draft-chip' : ''
																		}
																		label={'Draft'}
																	/>
																</Box>
															)}
														</Box>
														{performanceGoalsList?.managerScoreRating && (
															<Typography variant='h6' className='font-weight-normal'>
																{performanceGoalsList?.managerScoreRating}
															</Typography>
														)}
													</Box>
												) : (
													<Box className='box-head-right'>
														<Box className='box-head-right-inner'>
															{performanceGoalsList?.managerReviewStatus === 0 ||
															performanceGoalsList?.managerReviewStatus === 1 ? (
																<Typography variant='h2' className='blank-text'>
																	--
																</Typography>
															) : performanceGoalsList?.managerReviewStatus === 2 ? (
																<Typography variant='h2'>{t('doneLevel')}</Typography>
															) : (
																<></>
															)}
														</Box>
													</Box>
												)}
											</Box>
										</Box>
										{/* <Box className='box-setting-des'>
										<Typography variant='h6' className='font-weight-normal'>
										{t('performsubText')}
										</Typography>
									</Box> */}
										<Box className='box-content'>
											<Typography variant='h5'>{t('managerSkipReviewLevel')}</Typography>
											<Typography variant='h6'>{t('achievementReviewAndDevelopment')}</Typography>
										</Box>
									</Box>
								</Box>
								{fromTeamDetails && (
									<Box className='goals-review-stats-box'>
										<Box className='box-outer-head'>
											<Box className='box-head'>
												<ChangeStatusIcon />
												<Box className='box-head-right'>
													<Box className='box-head-right-inner'>
														<Typography variant='h6' className='font-weight-normal'>
															{drUserData.performanceGoalReviewWorkFlowType}
														</Typography>
														<Box className='goal-review-status'>
															<Chip
																className={drUserData.statusName === 'Publish' ? 'on-track-chip' : 'lagging-chip'}
																label={drUserData.statusName}
															/>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
										<Box className='box-content'>
											<Typography variant='h5'>{t('Review Status')}</Typography>
											<Typography variant='h6'>{t('managerAbove')}</Typography>
										</Box>
									</Box>
								)}
							</Box>
							{performanceGoalsList?.isAlertMessageVisible ? (
								<Box className='goals-review-stats-notes goal-review-error-notes'>
									<AlertInfoIcon />
									<Typography variant='body2' className='review-days-remaining font-weight-normal'>
										<strong>{performanceGoalsList.alertMessage}</strong>
									</Typography>
									<Typography variant='body2' className='font-weight-normal'>
										{performanceGoalsList?.dateMessage}
									</Typography>
								</Box>
							) : (
								<Box className='goals-review-stats-notes'>
									<Typography variant='body2' className='font-weight-normal'>
										{performanceGoalsList?.dateMessage}
									</Typography>
								</Box>
							)}
						</Box>
					)}

					{fromTeamDetails ? (
						isDrSelfReviewData ? (
							<TeamFinalRatingTabs
								drUserData={drUserData}
								fromTeamDetails={fromTeamDetails}
								isDrSelfReviewData={false}
								performanceGoalsList={performanceGoalsList}
								setPerformanceGoalsList={setPerformanceGoalsList}
								getUserPerformanceGoalsList={getUserPerformanceGoalsList}
							/>
						) : (
							<ReviewGoalListing
								drUserData={drUserData}
								fromTeamDetails={fromTeamDetails}
								isDrSelfReviewData={false}
								performanceGoalsList={performanceGoalsList}
								setPerformanceGoalsList={setPerformanceGoalsList}
								getUserPerformanceGoalsList={getUserPerformanceGoalsList}
							/>
						)
					) : performanceGoalsList.isFinalRatingDone ? (
						<>
							<TeamFinalRatingTabs
								drUserData={{
									...drUserData,
									performanceGoalRatingAssessmentId: performanceGoalsList.performanceGoalRatingAssessmentId,
								}}
								fromTeamDetails={fromTeamDetails}
								isFinalRatingDone={true}
								isEditable={false}
								isDrSelfReviewData={false}
								performanceGoalsList={performanceGoalsList}
								setPerformanceGoalsList={setPerformanceGoalsList}
								getUserPerformanceGoalsList={getUserPerformanceGoalsList}
							/>
						</>
					) : (
						<>
							<ReviewGoalListing
								drUserData={drUserData}
								fromTeamDetails={fromTeamDetails}
								isDrSelfReviewData={isDrSelfReviewData}
								performanceGoalsList={performanceGoalsList}
								getUserPerformanceGoalsList={getUserPerformanceGoalsList}
								setPerformanceGoalsList={setPerformanceGoalsList}
							/>
						</>
					)}
				</Box>
			)}
			{openDeleteDialog.open && (
				<AlertDialog
					module='information'
					message={
						openDeleteDialog.status === 3
							? t('clearSelfReview')
							: openDeleteDialog.status === 2 && fromTeamDetails
							? t('submitManagerReview')
							: t('selfReviewSubmit')
					}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog}
				/>
			)}
			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
