import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Badge } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FinalRatingIcon2, PerformanceAssessmentIcon } from '../../config/svg/CommonSvgIcon';
import { ReviewGoalListing } from './ReviewGoalListing';
import { TeamReviewFinalRating } from './TeamReviewFinalRating';
import { TeamReviewTrainings } from './TeamReviewTrainings';
import { TeamReviewTrainingsSearch } from './TeamReviewTrainingsSearch';
import { TrainingData } from './TrainingData';
import { useSelector } from 'react-redux';

export const TeamFinalRatingTabPanel: React.FC<any> = (props: any) => {
	const { children, teamReviewUserListResponse, value, index, ...other } = props;
	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`team-final-rating-tabpanel-${index}`}
			aria-labelledby={`team-final-rating-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

TeamFinalRatingTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `team-final-rating-tab-${index}`,
		'aria-controls': `team-final-rating-tabpanel-${index}`,
	};
}

export const TeamFinalRatingTabs: React.FC<any> = (props) => {
	const {
		drUserData,
		fromTeamDetails,
		isEditable = true,
		isDrSelfReviewData,
		users,
		performanceGoalsList,
		setPerformanceGoalsList,
		getUserPerformanceGoalsList,
		teamReviewUserListResponse,
	} = props;
	const { performanceSelfReviewDrUserData } = useSelector((state: any) => state.adminSettingReducer);
	useEffect(() => {
		if (
			performanceSelfReviewDrUserData &&
			(performanceSelfReviewDrUserData?.fromApproval || performanceGoalsList?.isFinalTab)
		) {
			setTabSelected(1);
		}
	}, []);
	const { t } = useTranslation();
	const [tabSelected, setTabSelected] = useState<Number>(0);
	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
	};
	const handleNext = () => {
		//setTabSelected(1);
	};

	return (
		<Box className='user-tabpanel-inner'>
			<Box className='user-tabpanel-head'>
				<Box className='user-tabpanel-head-left'>
					<Tabs
						className='okr-tab-button'
						value={tabSelected}
						onChange={handleTabChange}
						aria-label='Team Final Review and Rating'
					>
						<Tab icon={<PerformanceAssessmentIcon />} label={t('reviewLabel')} value={0} {...a11yProps(0)} />
						<Tab
							icon={<FinalRatingIcon2 />}
							label={t('finalRatingRecommendationLabel')}
							value={1}
							{...a11yProps(1)}
							disabled={!performanceGoalsList.isManagerRatingDone}
						/>
					</Tabs>
				</Box>
				<Box className='user-tabpanel-head-right'></Box>
			</Box>
			<TeamFinalRatingTabPanel value={tabSelected} index={0}>
				<ReviewGoalListing
					drUserData={drUserData}
					fromTeamDetails={fromTeamDetails}
					isDrSelfReviewData={isDrSelfReviewData}
					fromReviewTab={true}
					handleNext={handleNext}
					performanceGoalsList={performanceGoalsList}
					setPerformanceGoalsList={setPerformanceGoalsList}
					getUserPerformanceGoalsList={getUserPerformanceGoalsList}
				/>
			</TeamFinalRatingTabPanel>

			<TeamFinalRatingTabPanel value={tabSelected} index={1}>
				<TrainingData
					drUserData={drUserData}
					fromTeamDetails={fromTeamDetails}
					isDrSelfReviewData={isDrSelfReviewData}
					users={users}
					isEditable={isEditable}
					getUserPerformanceGoalsList={getUserPerformanceGoalsList}
					performanceGoalsList={performanceGoalsList}
				/>
			</TeamFinalRatingTabPanel>
		</Box>
	);
};
