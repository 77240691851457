import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	List,
	Typography,
	Avatar,
	ListItem,
	FormControlLabel,
	Checkbox,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
	Link,
} from '@material-ui/core';
import {
	getEmployeesFinalRating,
	getMultipleAssessment,
	getTeamAssessmentList,
	resetTeamAssessmentList,
} from '../../../action/perform';
import { getUserDetails, getUserName } from '../../../config/utils';
import { getLocalStorageItem } from '../../../services/StorageService';
import { AssessedIcon, DownloadIcon, SortingIcon, TickIcon } from '../../../config/svg/CommonSvgIcon';
import {
	performanceAssessmentDetailedStatusReport,
	performanceAssessmentQuickStatusReport,
	showLoader,
} from '../../../action/common';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { ReportingToIcon } from '../../../config/svg/formElementIcons';
// import '../../../styles/pages/perform/my-teams-assessment.scss';
// import '../../../styles/pages/perform/performanceAssessment.scss';
import {
	AlertInfoIcon,
	DirectReportReviewIcon,
	FinalAssessmentIcon,
	PerformUserMgtIcon,
	UserMgtIcon,
} from '../../../config/svg/CommonSvg';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';
import { RequestOneOnOneIcon, RequestOneOnOneIcon2 } from '../../../config/svg/PersonalFeedbackSvg';
import { MultipleAssessment } from './MultipleAssessment';
import { MultipleFinalAssessment } from './MultipleFinalAssessment';
import { EmployeeAssessment } from '../EmployeeAssessment';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { UserSnapshotDrawer } from '../Snapshot/UserSnapshotDrawer';
import { supportModuleAlignOkr } from '../../Admin/AdminConstant';
import assessmentYetToBegin from '../../../images/assessment-yet-to-begin.svg';
import { getOkrMasterData } from '../../../action/myGoal';
import { TeamNomineeeAssessment } from '../ThreeSixtyAssessment/TeamNomineeeAssessment';
import { NominateMainDrawer } from '../NominateMainDrawer';
import { UserSnapshot } from '../../Common/UserSnapshot';
import { NoRecordPerformance } from '../../UserPerformance/NoRecordPerformance';

export const TeamsAssessment = (props: any) => {
	const { tabSelected, cycleData, handleOneOnOneClick, setLoader } = props;
	const { userSelectedCycle, currentEngageTab, dashboardCurrentTab } = useSelector((state: any) => state.commonReducer);
	const {
		teamAssessmentResponse,
		multipleAssessmentResponse,
		employeeAssessmentCycleList,
		performanceCycle,
		performanceAlert,
		teamAssessmentResponseStatus,
	} = useSelector((state: any) => state.performReducer);

	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);

	const { t } = useTranslation();
	const dispatch = useDispatch();
	let userDetail = getUserDetails();
	const [userList, setUserList] = useState<any>([]);
	const [sortOrder, setSortOrder] = useState('ASC');
	const [sortingColumn, setSortingColumn] = useState('firstName');
	const [showBulkOption, setShowBulkOption] = useState(false);
	const [showManagerAssessment, setShowManagerAssessment] = useState(false);
	const [checkedUser, setCheckedUser] = useState(0);
	const [openSnapshot, setOpenSnapshot] = useState(false);
	const [selectedUser, setSelectedUser] = useState({});
	const [showNote, setShowNote] = useState(false);
	const [showMultipleFinalAssessment, setShowMultipleFinalAssessment] = useState(false);
	const [showTeamAssessment, setShowTeamAssessment] = useState(true);
	const [performanceRoleId, setPerformanceRoleId] = useState(0);
	const [showFinalAssessmentBtn, setShowFinalAssessmentBtn] = useState(false);
	const [showAssessment, setShowAssessment] = useState(false);
	const [isFinalRating, setIsFinalRating] = useState(false);
	const [selectedUserAssessment, setSelectedUserAssessment] = useState<any>({});
	const [listFilter, setListFilters] = useState<any>([]);
	const [isDownloadIconDisabled, setIsDownloadIconDisabled] = useState<boolean>(false);
	const [performanceRatings, setPerformanceRatings] = useState<any>([]);
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');

	const [showNomineeThreeSixty, setShowNomineeThreeSixty] = useState<boolean>(false);
	const [threeSixtyNominee, setThreeSixtyNominee] = useState<any>({});
	const [openNominateDrawer, setOpenNominateDrawer] = useState<boolean>(false);
	const [reloadRequired, setReloadRequired] = useState<boolean>(false);
	const [nominatedUser, setNominatedUser] = React.useState<any>({});

	useEffect(() => {
		if (performanceCycle?.performanceCycleId) {
			dispatch(resetTeamAssessmentList());
			handleBack();
			dispatch(showLoader(true));
			// setUserList([]);
			getTeamAssessList();
			if (!Boolean(okrMasterData)) {
				dispatch(getOkrMasterData());
			}
			setReloadRequired(false);
		}
	}, [performanceCycle]);
	useEffect(() => {
		if (reloadRequired) {
			dispatch(resetTeamAssessmentList());
			handleBack();
			dispatch(showLoader(true));
			// setUserList([]);
			getTeamAssessList(performanceRoleId);
			if (!Boolean(okrMasterData)) {
				dispatch(getOkrMasterData());
			}
			setReloadRequired(false);
		}
	}, [reloadRequired]);

	useEffect(() => {
		if (okrMasterData?.performanceRatings) {
			setPerformanceRatings(okrMasterData.performanceRatings);
		}
	}, [okrMasterData]);

	useEffect(() => {
		setUserList([]);
	}, [performanceCycle]);

	useEffect(() => {
		if (teamAssessmentResponse?.assessmentDetailsResponse && teamAssessmentResponse?.assessmentDetailsResponse.length) {
			// dispatch(showLoader(false));
			let findDisabled = teamAssessmentResponse.assessmentDetailsResponse.find((item: any) => item.finalRatingId > 0);
			setShowNote(findDisabled);
			if (
				teamAssessmentResponse.performanceRoleEmployeeResponse &&
				teamAssessmentResponse.performanceRoleEmployeeResponse.length &&
				performanceRoleId === 0
			) {
				setPerformanceRoleId(
					teamAssessmentResponse.performanceRoleEmployeeResponse.length
						? teamAssessmentResponse.performanceRoleEmployeeResponse[0].performanceRoleId
						: 0
				);
			}
			setUserList(teamAssessmentResponse.assessmentDetailsResponse);
			setListFilters(teamAssessmentResponse?.performanceRoleEmployeeResponse);
		}
		// else {
		// 	setUserList([]);
		// }
	}, [teamAssessmentResponse]);

	useEffect(() => {
		if (userList.length > 0) {
			let findChecked = userList.filter((item: any) => item.isChecked);
			if (findChecked.length > 1) {
				if (teamAssessmentResponse.isSelfRatingPercentage) {
					let findStatus: any = userList.filter((user: any) => {
						if (user.isChecked && user.isManagerAssessmentCompleted && user.isSelfAssessmentCompleted) {
							return user;
						}
					});
					if (findStatus.length === findChecked.length) {
						setShowFinalAssessmentBtn(true);
					} else {
						setShowFinalAssessmentBtn(false);
					}
				} else {
					let findChecked = userList.filter((item: any) => item.isChecked);
					let findStatus = userList.filter((user: any) => {
						if (user.isChecked && user.isManagerAssessmentCompleted) {
							return user;
						}
					});
					if (findStatus.length === findChecked.length) {
						setShowFinalAssessmentBtn(true);
					} else {
						setShowFinalAssessmentBtn(false);
					}
				}
			}
		}
	}, [userList]);

	const showThreeSixtyForUser = (user: any) => {
		setThreeSixtyNominee(user);
		setShowNomineeThreeSixty(true);
	};
	const checkSupportPermission = (moduleId: any) => {
		const permission =
			tenantData && tenantData.modules.length && tenantData.modules.filter((item: any) => item === moduleId);
		if (permission.length) {
			return true;
		} else {
			return false;
		}
	};

	const getTeamAssessList = (performId?: any) => {
		let data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			performanceRoleId: performId || 0,
			reportingManagerId: userDetail.employeeId,
			sortingText: '',
			order: '',
			finder: '',
		};
		dispatch(showLoader(true));
		dispatch(getTeamAssessmentList(data));
	};

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	const onSelectAllClick = (e: any) => {
		let { checked } = e.target;
		let updateCheck =
			userList &&
			userList?.map((item: any) => {
				return {
					...item,
					isChecked: item.finalRatingId > 0 ? false : checked,
				};
			});
		setUserList(updateCheck);
		let checkedLength = updateCheck.filter((user: any) => user.isChecked);
		setCheckedUser(checkedLength.length);
		if (checked && userList.length >= 2) {
			setShowBulkOption(true);
		} else {
			setShowBulkOption(false);
		}
	};

	const onUserSelect = (e: any, index: number) => {
		let { checked } = e.target;
		let updateCheck = [...userList];
		updateCheck[index].isChecked = checked;
		let findChecked = updateCheck.filter((item: any) => item.isChecked).length;
		setCheckedUser(findChecked);
		if (findChecked > 1) {
			setShowBulkOption(true);
		} else {
			setShowBulkOption(false);
		}
		setUserList(updateCheck);
	};

	const handleIndeterminate = () => {
		let findChecked = userList.filter((item: any) => item.isChecked && item.finalRatingId === 0);
		let checkableItems = userList.filter((item: any) => item.finalRatingId === 1);
		if (findChecked.length > 0 && findChecked.length !== checkableItems.length) {
			return true;
		} else {
			return false;
		}
	};

	const handleAllChecked = () => {
		let findChecked = userList.filter((item: any) => item.isChecked && item.finalRatingId === 0);
		let checkableItems = userList.filter((item: any) => item.finalRatingId < 1);
		if (findChecked.length === checkableItems.length) {
			return true;
		} else {
			return false;
		}
	};

	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		setSortingColumn(column);
		let data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			performanceRoleId: 0,
			reportingManagerId: userDetail.employeeId,
			sortingText: column,
			order: newSortOrder,
			finder: '',
		};
		dispatch(showLoader(true));
		dispatch(getTeamAssessmentList(data));
	};

	const handleTeamFilter = (e: any, item: any) => {
		if (item.performanceRoleId !== performanceRoleId) {
			let data = {
				performanceCycleId: performanceCycle?.performanceCycleId,
				performanceRoleId: item.performanceRoleId,
				reportingManagerId: userDetail.employeeId,
				sortingText: sortingColumn,
				order: sortOrder,
				finder: '',
			};
			setPerformanceRoleId(item.performanceRoleId);
			dispatch(showLoader(true));
			dispatch(getTeamAssessmentList(data));
		}
	};

	const resetCheckList = () => {
		let updateList = userList.map((item: any) => {
			return {
				...item,
				isChecked: false,
			};
		});
		setUserList(updateList);
		setShowBulkOption(false);
	};

	const handleMultipleManagerAssessment = () => {
		dispatch(showLoader(true));
		let userFilter: any = [];
		let findChecked = userList.filter((item: any) => {
			if (item.isChecked) {
				userFilter.push(item.employeeDetailsResponse.employeeId);
			}
		});
		let data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			employeeIds: userFilter,
		};
		dispatch(getMultipleAssessment(data));
		setShowManagerAssessment(true);
		resetCheckList();
	};

	useEffect(() => {
		if (showManagerAssessment && multipleAssessmentResponse && multipleAssessmentResponse.length) {
			// setShowManagerAssessment(true);
			dispatch(showLoader(false));
		}
	}, [multipleAssessmentResponse]);

	const handleBack = () => {
		resetCheckList();
		setShowManagerAssessment(false);
		setShowTeamAssessment(true);
		setShowMultipleFinalAssessment(false);
	};

	const fetchFinalAssessment = (empId: number) => {
		let userFilter: any = [empId];
		let data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			employeeId: userFilter,
		};
		dispatch(getEmployeesFinalRating(data));
	};

	const handleMultipleFinalAssessment = (e: any) => {
		dispatch(showLoader(true));
		let userFilter: any = [];
		let empData = userList.filter((item: any) => {
			if (item.isChecked) {
				userFilter.push(item.employeeDetailsResponse.employeeId);
			}
		});
		let data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			employeeId: userFilter,
		};
		dispatch(getEmployeesFinalRating(data));
		setShowMultipleFinalAssessment(true);
		setShowManagerAssessment(false);
		setShowTeamAssessment(false);
		resetCheckList();
	};

	const handleSnapshot = (e: any, user: any) => {
		setSelectedUser(user);
		setOpenSnapshot(true);
	};

	const handleDrawerClose = (e: any, type: any) => {
		setOpenSnapshot(false);
	};
	const teamTakeAssessment = (e: any, user: any, finalRating = false) => {
		setSelectedUserAssessment(user);
		setShowAssessment(true);
		setIsFinalRating(finalRating);
		if (finalRating) {
			fetchFinalAssessment(user?.employeeDetailsResponse?.employeeId);
		}
	};

	const backClicked = (resetData = false) => {
		setSelectedUserAssessment({});
		setShowAssessment(false);
		if (resetData) {
			getTeamAssessList(performanceRoleId);
		}
	};

	const performanceAssessmentReport = async (type: number) => {
		try {
			/**
			 * type = 1 for Quick Report
			 * type = 2 for Detailed Report
			 */
			setIsDownloadIconDisabled(true);
			setLoader && setLoader(true);
			const performanceCycleId = performanceCycle?.performanceCycleId || 0;
			const requestParam = `empId=${userDetail?.employeeId}&performanceCycleId=${performanceCycleId}`;
			let response: any = null;
			if (type === 1) {
				response = await dispatch(performanceAssessmentQuickStatusReport(requestParam));
			} else if (type === 2) {
				response = await dispatch(performanceAssessmentDetailedStatusReport(requestParam));
			}
			if (response) {
				const defaultName =
					type === 1 ? 'PerformanceAssessmentQuickStatusReport.xlsx' : 'PerformanceAssessmentDetailedStatusReport.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			setLoader && setLoader(false);
			setIsDownloadIconDisabled(false);
		} catch (error) {
			console.error(error);
		}
	};
	const handleClickNominate = (event: any, user: any, isManaged: boolean) => {
		setOpenNominateDrawer(true);
		setNominatedUser({ ...user, isManaged });
	};

	const handleBackClicked = () => {
		setShowNomineeThreeSixty(false);
	};
	return (
		<>
			{!showNomineeThreeSixty && !showAssessment && showMultipleFinalAssessment && (
				<MultipleFinalAssessment
					teamAssessmentResponse={teamAssessmentResponse}
					getTeamAssessList={getTeamAssessList}
					handleBack={handleBack}
					checkSupportPermission={checkSupportPermission}
					performanceCycle={performanceCycle}
					performanceRoleId={performanceRoleId}
					{...props}
				/>
			)}
			{!showNomineeThreeSixty && showAssessment && (
				<>
					<EmployeeAssessment
						{...props}
						employeeId={selectedUserAssessment?.employeeDetailsResponse?.employeeId}
						fromTeamAssessment={true}
						backClicked={backClicked}
						isFinalRating={isFinalRating}
						handleOneOnOneClick={handleOneOnOneClick}
						performanceRoleId={performanceRoleId}
						getTeamAssessList={getTeamAssessList}
						selectedUserAssessment={selectedUserAssessment}
					/>
				</>
			)}

			{showNomineeThreeSixty && (
				<>
					<TeamNomineeeAssessment
						{...props}
						employeeId={threeSixtyNominee?.employeeId}
						fromTeamAssessment={true}
						showNomineeThreeSixty={showNomineeThreeSixty}
						backClicked={backClicked}
						setLoader={setLoader}
						handleBackClicked={handleBackClicked}
						isFinalRating={isFinalRating}
						handleOneOnOneClick={handleOneOnOneClick}
						performanceRoleId={performanceRoleId}
						getTeamAssessList={getTeamAssessList}
						selectedUserAssessment={selectedUserAssessment}
					/>
				</>
			)}
			{!showNomineeThreeSixty && !showAssessment && showTeamAssessment && (
				<>
					{showManagerAssessment ? (
						<MultipleAssessment
							handleBack={handleBack}
							userDetail={userDetail}
							multipleAssessmentResponse={multipleAssessmentResponse}
							performanceCycleId={performanceCycle?.performanceCycleId}
							getTeamAssessList={getTeamAssessList}
							performanceCycle={performanceCycle}
							performanceRoleId={performanceRoleId}
							performanceRatings={performanceRatings}
						/>
					) : (
						<>
							{!performanceCycle || (teamAssessmentResponseStatus && userList.length === 0) ? (
								<Box className='assessment-no-record'>
									<Box className='no-record-message'>
										<Typography variant='h2'>{t('assessmentYetToBegin1')}</Typography>
										<Typography>{t('assessmentYetToBegin2')}</Typography>
										<img width='470' alt={t('assessmentYetToBegin1')} src={assessmentYetToBegin} />
									</Box>
								</Box>
							) : (
								<>
									<Box className='my-teams-assessment-head'>
										<Box className='my-teams-assessment-head-left'>
											<Box className='my-teams-assessment-head-left-inner'>
												{performanceAlert?.isManagerAssessmentAlert ? (
													<Typography className='alert-text' variant='h6'>
														{performanceAlert.managerRemainingDaysMessage && (
															<>
																<AlertInfoIcon /> {performanceAlert.managerRemainingDaysMessage}
															</>
														)}
													</Typography>
												) : (
													<></>
												)}
												<List disablePadding>
													{listFilter.length > 0 &&
														listFilter.map((item: any, index: any) => (
															<ListItem key={index}>
																<OKRButton
																	disabled={performanceRoleId === item.performanceRoleId}
																	className={
																		performanceRoleId === item.performanceRoleId
																			? 'filter-grey-btn active'
																			: 'filter-grey-btn'
																	}
																	handleClick={(e) => handleTeamFilter(e, item)}
																	text={
																		<>
																			{item.performanceRoleName} ({item.employeeCount})
																		</>
																	}
																/>
															</ListItem>
														))}
												</List>
											</Box>
										</Box>
										{performanceCycle?.performanceCycleId ? (
											<Box className='my-teams-assessment-head-right'>
												<OKRButton
													disabled={isDownloadIconDisabled}
													handleClick={() => performanceAssessmentReport(2)}
													className='btn-circle-white'
													icon={<DownloadIcon />}
													title={t('downloadReport')}
												/>
											</Box>
										) : (
											<></>
										)}
									</Box>
									<TableContainer className='team-assessment-listing my-team-assessment-listing'>
										{userList && userList.length > 0 ? (
											<Table>
												<TableHead>
													<TableRow>
														<TableCell align='center' id={`team-assessment-cell-1`} className='user-col1 '>
															<Box className='cell-wrap'>
																<FormControlLabel
																	control={
																		<Checkbox
																			icon={<BorderCheckboxIcon />}
																			checkedIcon={<CheckedIcon />}
																			indeterminateIcon={<UnCheckedIcon />}
																			indeterminate={handleIndeterminate()}
																			checked={handleAllChecked()}
																			onChange={onSelectAllClick}
																			inputProps={{ 'aria-label': 'select Name' }}
																			disabled={
																				performanceCycle?.isCycleCompleted ||
																				!teamAssessmentResponse?.isManagerRatingEnabled
																			}
																		/>
																	}
																	label=''
																/>
																<Typography
																	variant='body2'
																	className={
																		sortingColumn === 'employeeDetailsResponse.firstName'
																			? `sort-title ${sortOrder}`
																			: 'sort-title asc'
																	}
																	onClick={(e) => sortUserList(e, 'employeeDetailsResponse.firstName')}
																	id={'sort-name'}
																>
																	<span className='sortable-label'>
																		<span className='txt-wrap'>{t('nameLabel')}</span> <SortingIcon />
																	</span>
																</Typography>
															</Box>
														</TableCell>
														{teamAssessmentResponse?.isSelfRatingPercentage && (
															<TableCell align='center' id={`team-assessment-cell-2`} className='user-col2 col-small'>
																<Box className='cell-wrap'>
																	<Typography
																		variant='body2'
																		className={
																			sortingColumn === 'isSelfAssessmentCompleted'
																				? `sort-title ${sortOrder}`
																				: 'sort-title asc'
																		}
																		onClick={(e) => sortUserList(e, 'isSelfAssessmentCompleted')}
																		id={'isSelfAssessmentCompleted'}
																	>
																		<span className='sortable-label'>
																			<span className='txt-wrap'>{t('selfAssessment')}</span> <SortingIcon />
																		</span>
																	</Typography>
																</Box>
															</TableCell>
														)}
														<TableCell align='center' id={`team-assessment-cell-3`} className='user-col3'>
															<Box className='cell-wrap'>
																<Typography
																	variant='body2'
																	className={
																		sortingColumn === 'isManagerAssessmentCompleted'
																			? `sort-title ${sortOrder}`
																			: 'sort-title asc'
																	}
																	onClick={(e) => sortUserList(e, 'isManagerAssessmentCompleted')}
																	id={'sort-name'}
																>
																	<span className='sortable-label'>
																		<span className='txt-wrap'>{t('managerAssessment')}</span> <SortingIcon />
																	</span>
																</Typography>
															</Box>
														</TableCell>
														{(teamAssessmentResponse?.isThreeSixtyEnabled ||
															teamAssessmentResponse?.isThreeSixtyVisible) && (
															<TableCell
																align='center'
																id={`team-assessment-cell-3-1`}
																className='user-col3-1 col-small'
															>
																<Box className='cell-wrap'>
																	<Typography variant='body2' className={'sort-title asc'} id={'sort-name'}>
																		<span className='sortable-label'>
																			<span
																				className='txt-wrap'
																				dangerouslySetInnerHTML={{ __html: t('assessment360degree') }}
																			></span>
																			<SortingIcon />
																		</span>
																	</Typography>
																</Box>
															</TableCell>
														)}
														{checkSupportPermission(supportModuleAlignOkr) &&
															teamAssessmentResponse?.isOkrProgressPercentage && (
																<TableCell align='center' id={`team-assessment-cell-4`} className='user-col4 col-small'>
																	<Box className='cell-wrap'>
																		<Typography
																			variant='body2'
																			className={
																				sortingColumn === 'okrScore' ? `sort-title ${sortOrder}` : 'sort-title asc'
																			}
																			onClick={(e) => sortUserList(e, 'okrScore')}
																			id={'sort-name'}
																		>
																			<span className='sortable-label'>
																				<span className='txt-wrap'>{t('committedOKRs')}</span> <SortingIcon />
																			</span>
																		</Typography>
																	</Box>
																</TableCell>
															)}
														<TableCell align='center' id={`team-assessment-cell-5`} className='user-col5 col-small'>
															<Box className='cell-wrap'>
																<Typography
																	variant='body2'
																	className={
																		sortingColumn === 'finalScore' ? `sort-title ${sortOrder}` : 'sort-title asc'
																	}
																	onClick={(e) => sortUserList(e, 'finalScore')}
																	id={'sort-name'}
																>
																	<span className='sortable-label'>
																		<span className='txt-wrap'>{t('finalAssessment')}</span> <SortingIcon />
																	</span>
																</Typography>
															</Box>
														</TableCell>
														<TableCell align='center' id={`team-assessment-cell-6`} className='user-col6'></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{userList &&
														userList.map((user: any, index: number) => (
															<Fragment key={`permission${index}`}>
																<TableRow
																	className={`${
																		user.finalRatingId > 0 ? 'team-assessment-row' : 'team-assessment-row'
																	}`}
																>
																	<TableCell className='user-col1'>
																		<Box className='cell-wrap'>
																			<FormControlLabel
																				control={
																					<Checkbox
																						icon={<BorderCheckboxIcon />}
																						checkedIcon={<CheckedIcon />}
																						name={'list' + index}
																						checked={user?.isChecked === undefined ? false : user?.isChecked}
																						onChange={(e) => onUserSelect(e, index)}
																						key={index}
																						disabled={
																							user.finalRatingId > 0 ||
																							performanceCycle?.isCycleCompleted ||
																							!teamAssessmentResponse?.isManagerRatingEnabled
																						}
																					/>
																				}
																				label=''
																			/>
																			<Box className='user-info'>
																				<Box className='user-img'>
																					{user.employeeDetailsResponse.imagePath ? (
																						<Avatar src={user.employeeDetailsResponse.imagePath} />
																					) : (
																						<Avatar>
																							{getUserName({
																								fullName: '',
																								firstName: user.employeeDetailsResponse.firstName,
																								lastName: user.employeeDetailsResponse.lastName,
																							})}
																						</Avatar>
																					)}
																				</Box>
																				<Box className='user-info-details'>
																					{(
																						user.employeeDetailsResponse.firstName +
																						user.employeeDetailsResponse.lastName
																					).length > 10 ? (
																						<Tooltip
																							title={
																								user.employeeDetailsResponse.firstName +
																								' ' +
																								user.employeeDetailsResponse.lastName
																							}
																							arrow
																						>
																							<Typography variant='h4'>
																								{(
																									user.employeeDetailsResponse.firstName +
																									' ' +
																									user.employeeDetailsResponse.lastName
																								).substring(0, 10)}
																								...
																							</Typography>
																						</Tooltip>
																					) : (
																						<Typography variant='h4'>{`${user.employeeDetailsResponse.firstName} ${user.employeeDetailsResponse.lastName}`}</Typography>
																					)}
																				</Box>
																			</Box>
																		</Box>
																	</TableCell>
																	{teamAssessmentResponse?.isSelfRatingPercentage && (
																		<TableCell className='user-col2 col-small'>
																			<Box className='cell-wrap'>
																				<Typography>
																					{user.isSelfAssessmentCompleted && user.isManagerAssessmentCompleted
																						? user.selfAssessmentScore
																						: user.isSelfAssessmentCompleted && !user.isManagerAssessmentCompleted
																						? 'Done'
																						: '--'}
																				</Typography>
																			</Box>
																		</TableCell>
																	)}
																	<TableCell className='user-col3 manage-assessment-col'>
																		<Box className='cell-wrap'>
																			{user.finalRatingId > 0 ? (
																				<>
																					<Typography>{user.managerAssessmentScore}</Typography>
																					<OKRButton
																						className={'btn-link edit-button'}
																						text={t('showBtn')}
																						handleClick={(e: any) => {
																							teamTakeAssessment(e, user);
																						}}
																					/>
																				</>
																			) : (
																				<>
																					{user.isManagerAssessmentCompleted ? (
																						<>
																							<Typography>{user.managerAssessmentScore}</Typography>
																							{user.finalRatingId === 0 &&
																								(performanceCycle?.isCycleCompleted ? (
																									<OKRButton
																										className={'btn-link edit-button'}
																										text={t('showBtn')}
																										handleClick={(e: any) => {
																											teamTakeAssessment(e, user);
																										}}
																										disabled={!teamAssessmentResponse?.isManagerRatingEnabled}
																									/>
																								) : performanceCycle?.isCycleCompleted ||
																								  !teamAssessmentResponse?.isManagerRatingEnabled ? (
																									<></>
																								) : (
																									<OKRButton
																										className={'btn-link edit-button'}
																										text={t('editLabel')}
																										handleClick={(e: any) => {
																											teamTakeAssessment(e, user);
																										}}
																										disabled={!teamAssessmentResponse?.isManagerRatingEnabled}
																									/>
																								))}
																						</>
																					) : (
																						<OKRButton
																							className={`${
																								performanceCycle?.isCycleCompleted ||
																								!teamAssessmentResponse?.isManagerRatingEnabled
																									? 'grey-btn'
																									: ''
																							} btn-link`}
																							text={t('takeAssessment')}
																							buttonType={
																								performanceCycle?.isCycleCompleted ||
																								!teamAssessmentResponse?.isManagerRatingEnabled
																									? 'disableButtonTooltip'
																									: null
																							}
																							handleClick={(e: any) => {
																								teamTakeAssessment(e, user);
																							}}
																							icon={''}
																							title={
																								performanceCycle?.isCycleCompleted
																									? 'Cannot take assessment as the performance cycle is over'
																									: !teamAssessmentResponse?.isManagerRatingEnabled
																									? 'Cannot take assessment as the last day for manager assessment is over'
																									: null
																							}
																							disabled={
																								performanceCycle?.isCycleCompleted ||
																								!teamAssessmentResponse?.isManagerRatingEnabled
																							}
																						/>
																					)}
																				</>
																			)}
																		</Box>
																	</TableCell>
																	{(teamAssessmentResponse?.isThreeSixtyEnabled ||
																		teamAssessmentResponse?.isThreeSixtyVisible) && (
																		<TableCell className='user-col3-1 three-sixty-column col-small'>
																			<Box className='cell-wrap'>
																				<Box className='inner-wrap'>
																					<Box className='btn-wrap-box'>
																						{user?.threeSixtyScore > 0 ? (
																							<Typography>{user?.threeSixtyScore}</Typography>
																						) : user?.threeSixtyScore === 0 && user?.nomineeTotalCount > 0 ? (
																							'--'
																						) : (
																							<OKRButton
																								className={'btn-link'}
																								text={t('Nominate')}
																								buttonType={
																									!teamAssessmentResponse?.isThreeSixtyVisible ||
																									performanceCycle?.isCycleCompleted
																										? 'disableButtonTooltip'
																										: null
																								}
																								title={
																									performanceCycle?.isCycleCompleted
																										? 'Cannot nominate as the performance cycle is over'
																										: !teamAssessmentResponse?.isThreeSixtyVisible
																										? 'Cannot nominate as the last day for 360 assessment is over'
																										: null
																								}
																								disabled={
																									!teamAssessmentResponse?.isThreeSixtyVisible ||
																									performanceCycle?.isCycleCompleted
																								}
																								handleClick={(e: any) => handleClickNominate(e, user, false)}
																							/>
																						)}
																					</Box>
																					{user?.nomineeTotalCount && user?.nomineeTotalCount > 0 ? (
																						<Box className='btn-wrap-box'>
																							<OKRButton
																								className={'btn-link view-button'}
																								title={
																									<>
																										<span
																											dangerouslySetInnerHTML={{ __html: t('viewAssessments') }}
																										></span>
																									</>
																								}
																								handleClick={(e: any) => {
																									showThreeSixtyForUser(user.employeeDetailsResponse);
																								}}
																								icon={<AssessedIcon />}
																								text={`${user?.nomineeApproveCount}/${user?.nomineeTotalCount}`}
																							/>
																							{teamAssessmentResponse?.isThreeSixtyVisible &&
																							!performanceCycle?.isCycleCompleted ? (
																								<OKRButton
																									className={'btn-link edit-button'}
																									text={t('manageText')}
																									handleClick={(e: any) => handleClickNominate(e, user, true)}
																								/>
																							) : (
																								''
																							)}
																						</Box>
																					) : (
																						<></>
																					)}
																				</Box>
																			</Box>
																		</TableCell>
																	)}
																	{checkSupportPermission(supportModuleAlignOkr) &&
																		teamAssessmentResponse?.isOkrProgressPercentage && (
																			<TableCell className='user-col4 blue-color-column col-small'>
																				<Box className='cell-wrap'>
																					<Typography>{user.okrScore}%</Typography>
																				</Box>
																			</TableCell>
																		)}
																	<TableCell className='user-col5 col-small'>
																		<Box className='cell-wrap'>
																			{teamAssessmentResponse.isFinalRatingEnabled ? (
																				user.isManagerAssessmentCompleted ? (
																					user.isPerformanceCycleDone ? (
																						<OKRButton
																							handleClick={(e: any) => {
																								teamTakeAssessment(e, user, true);
																							}}
																							className={`${performanceCycle?.isCycleCompleted && 'grey-btn'} btn-link`}
																							text={t('takeFinalAssessment')}
																							buttonType={
																								performanceCycle?.isCycleCompleted ? 'disableButtonTooltip' : null
																							}
																							icon={''}
																							title={
																								performanceCycle?.isCycleCompleted ||
																								!teamAssessmentResponse?.isFinalRatingEnabled
																									? 'Cannot take assessment as the performance cycle is over'
																									: null
																							}
																							disabled={
																								performanceCycle?.isCycleCompleted ||
																								!teamAssessmentResponse?.isFinalRatingEnabled
																							}
																						/>
																					) : (
																						<Box className='text-wrap-box'>
																							<Box className='btn-wrap-box '>
																								<Typography>{user.finalScore} </Typography>
																							</Box>
																							<Box className='btn-wrap-box '>
																								<span className='short-text'>{user.finalRating}</span>
																							</Box>
																						</Box>
																					)
																				) : (
																					''
																				)
																			) : (
																				<OKRButton
																					className={`${performanceCycle?.isCycleCompleted && 'grey-btn'} btn-link`}
																					text={t('takeFinalAssessment')}
																					buttonType={'disableButtonTooltip'}
																					icon={''}
																					title={'Cannot take assessment as the last day for final assessment is over'}
																					disabled={true}
																				/>
																			)}
																		</Box>
																	</TableCell>
																	<TableCell className='user-col6'>
																		<Box className='cell-wrap'>
																			{performanceCycle?.isCycleCompleted ? (
																				<></>
																			) : (
																				<OKRButton
																					className='icon-btn'
																					icon={<PerformUserMgtIcon />}
																					title={t('viewPerformanceSnapshot')}
																					//tooltipClassName='tooltip-layout3'
																					// disabled={user.finalRatingId > 0}
																					handleClick={(e) => handleSnapshot(e, user.employeeDetailsResponse)}
																				/>
																			)}
																			{teamAssessmentResponse && teamAssessmentResponse?.isOneToOneReview ? (
																				<OKRButton
																					className='icon-btn'
																					icon={<RequestOneOnOneIcon2 />}
																					title={t('requestOnetoOne')}
																					handleClick={(e) =>
																						handleOneOnOneClick(
																							e,
																							'MyAssessmentList',
																							user?.employeeDetailsResponse?.employeeId
																						)
																					}
																				/>
																			) : (
																				<></>
																			)}
																		</Box>
																	</TableCell>
																</TableRow>
															</Fragment>
														))}
												</TableBody>
											</Table>
										) : (
											<NoRecordPerformance message={'No data found team'} />
										)}
									</TableContainer>
									{showNote && !performanceCycle?.isCycleCompleted && (
										<Box className={showBulkOption ? 'user-note bulk-active' : 'user-note'}>
											<Box className='note-box'>
												<Typography variant='h6' className='font-weight-normal'>
													{t('teamAssessmentNote')}
												</Typography>
											</Box>
										</Box>
									)}

									{showBulkOption && (
										<Box className='bulk-user-edit-bar bulk-user-edit-bar-type1'>
											<Box className='bulk-user-selected'>
												<Typography variant='body1'>
													<TickIcon />
													{checkedUser} {t('selectedLabel')}
												</Typography>
											</Box>
											<Box className='bulk-user-action'>
												<List component='ul' disablePadding>
													<ListItem>
														<OKRButton
															icon={<DirectReportReviewIcon />}
															title={
																performanceCycle.status === 3 ? t('managerAssessment') : t('takeManagerAssessment')
															}
															handleClick={handleMultipleManagerAssessment}
															className='action-btn'
														></OKRButton>
													</ListItem>

													{showFinalAssessmentBtn ? (
														<ListItem>
															<OKRButton
																icon={<FinalAssessmentIcon />}
																title={t('takeFinalAssessment')}
																handleClick={(e) => handleMultipleFinalAssessment(e)}
																className='action-btn'
															></OKRButton>
														</ListItem>
													) : (
														<></>
													)}
												</List>
											</Box>
										</Box>
									)}
								</>
							)}
						</>
					)}
				</>
			)}

			{openNominateDrawer && (
				<NominateMainDrawer
					{...props}
					setNominatedUser={setNominatedUser}
					nominatedUser={nominatedUser}
					performanceNomineeId={performanceCycle?.performanceCycleId}
					setOpenNominateDrawer={setOpenNominateDrawer}
					setReloadRequired={setReloadRequired}
					openNominateDrawer={openNominateDrawer}
				/>
			)}
			{/* <UserSnapshotDrawer
				openSnapshot={openSnapshot}
				selectedUser={selectedUser}
				performanceCycle={performanceCycle}
				handleDrawerClose={handleDrawerClose}
				isTeamAssessment={true}
			/> */}
			{openSnapshot ? (
				<UserSnapshot
					openSnapshot={openSnapshot}
					selectedUser={selectedUser}
					performanceCycle={performanceCycle}
					handleDrawerClose={handleDrawerClose}
					isTeamAssessment={props.fromTeamAssessment ? true : false}
				/>
			) : (
				<></>
			)}
		</>
	);
};
