import React, { useState } from 'react';
import {
	Box,
	Typography,
	FormControlLabel,
	Radio,
	RadioGroup,
	Collapse,
	Avatar,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from '@material-ui/core';

import { CommentIcon, SelfReviewIcon } from '../../../config/svg/CommonSvg';

import CkEditor from '../CkEditor/CkEditor';
import { OKRButton } from '../OKRButton';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { PerformanceIcon } from '../../../config/svg/AssessmentSvg';
import { RequestOneOnOneIcon2 } from '../../../config/svg/PersonalFeedbackSvg';
import { getFullName, getUserName } from '../../../config/utils';

export const MultiChoiceRating = (props: any) => {
	const {
		t,
		section,
		sectionIndex,
		questionIndex,
		question,
		getQuestionNumber,
		isViewMode,
		handleCommentsToggle,
		handleChangeQuestionComment,
		handleChangeMultiChoiceQuestionValue,
		getSelectedMultiChoiceValue,
		isMultipleUserAssessment,
		employeeDetails = {},
		handleEmployeeActionClick,
		isActionMenuHidden = false,
		callingFrom = '', // AdminAssessmentView
	} = props;

	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);

	const handleClose = () => {
		setPopperMenuClicked(true);
		setAnchorEl(null);
	};
	const getDetailsBasedOnKey = (keyName: string) => {
		/*
			getDetailsBasedOnKey('performanceRoleLinkedFormDetailsId')
			getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')
			getDetailsBasedOnKey('questionCommentBox')
			getDetailsBasedOnKey('ratingAssessmentDetailId')
			getDetailsBasedOnKey('ratingAssessmentId')
			getDetailsBasedOnKey('ratingValue')
			getDetailsBasedOnKey('isCommentExpand')
			getDetailsBasedOnKey('starRatingHoverValue')
			getDetailsBasedOnKey('isQuestionError')
			getDetailsBasedOnKey('isQuestionErrorMsg')
			getDetailsBasedOnKey('isQuestionCommentError')
			getDetailsBasedOnKey('isQuestionCommentErrorMsg')
		*/
		const selectedKeyValue = isMultipleUserAssessment ? employeeDetails[keyName] : question[keyName];

		return isMultipleUserAssessment && keyName === 'ratingAssessmentId'
			? `${employeeDetails?.employeeId}_${sectionIndex}_${questionIndex}`
			: selectedKeyValue || '';
	};

	return (
		<>
			<Box className={`${isMultipleUserAssessment ? 'user-multiple-row' : 'user-assess-ques-panel light-blue-panel'}`}>
				<Box className='user-assess-ques-type user-assess-ques-radio'>
					{!isMultipleUserAssessment ? (
						<Box className='user-assess-ques-title'>
							{getDetailsBasedOnKey('isQuestionError') ? (
								<Box className='error-field'>
									<Typography variant='body2' className='helperText-bottom'>
										{getDetailsBasedOnKey('isQuestionErrorMsg')}
									</Typography>
								</Box>
							) : (
								<></>
							)}
							{/* {!isMultipleUserAssessment ? ( */}
							<Typography variant='h4' className='ques-title font-weight-normal'>
								<span>{getQuestionNumber(section, question, sectionIndex, questionIndex)}</span>
								{question?.questionsName || ''}
								{question?.isQuestionsMandatory ? <i style={{ color: 'red' }}>*</i> : <></>}
							</Typography>
							{/* ) : (
								<></>
							)} */}
						</Box>
					) : (
						<></>
					)}
					<Box className='user-assess-ques-des'>
						<Box className='user-assess-ques-row'>
							{isMultipleUserAssessment ? (
								<Box className='user-assess-ques-left'>
									<Box className='user-info'>
										{getDetailsBasedOnKey('isQuestionError') ? (
											<Box className='error-field'>
												<Typography variant='body2' className='helperText-bottom'>
													{getDetailsBasedOnKey('isQuestionErrorMsg')}
												</Typography>
											</Box>
										) : (
											<></>
										)}
										<Box className='user-img'>
											{employeeDetails?.imagePath ? (
												<Avatar className='avatar-default' src={employeeDetails.imagePath} />
											) : (
												<Avatar className='avatar-default'>
													{getUserName({
														fullName: '',
														firstName: employeeDetails?.firstName,
														lastName: employeeDetails?.lastName,
													})}
												</Avatar>
											)}
										</Box>
										<Box className='user-info-details'>
											<Typography variant='h4'>{getFullName(employeeDetails)}</Typography>
											{!isActionMenuHidden ? (
												<PopperMenu
													className={'user-menu-btn'}
													anchorEl={anchorEl}
													handleClose={handleClose}
													includeKebabMenu={true}
													menuClicked={popperMenuClicked}
													setPopperMenuClicked={setPopperMenuClicked}
													btnId={`more-btn-`}
													popperPlacement={'bottom-start'}
													popperClassName={'popper-menu'}
													// {...props}
													children={
														<>
															<ListItem
																id={`self_assessment_preview_${getDetailsBasedOnKey(
																	'performanceRoleLinkedFormDetailsId'
																)}_${getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')}`}
																onClick={(e) => {
																	handleClose();
																	handleEmployeeActionClick(
																		e,
																		1,
																		section,
																		sectionIndex,
																		question,
																		questionIndex,
																		employeeDetails
																	);
																}}
															>
																<ListItemAvatar>
																	<SelfReviewIcon />
																</ListItemAvatar>
																<ListItemText primary={t('Self Assessment')} />
															</ListItem>
															<ListItem
																id={`user_performance_snapshot_drawer_${getDetailsBasedOnKey(
																	'performanceRoleLinkedFormDetailsId'
																)}_${getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')}`}
																onClick={(e) => {
																	handleClose();
																	handleEmployeeActionClick(
																		e,
																		2,
																		section,
																		sectionIndex,
																		question,
																		questionIndex,
																		employeeDetails
																	);
																}}
															>
																<ListItemAvatar>
																	<PerformanceIcon />
																</ListItemAvatar>
																<ListItemText primary={t('performanceSnapshot')} />
															</ListItem>
															<ListItem
																id={`one_on_one_with_employee_${getDetailsBasedOnKey(
																	'performanceRoleLinkedFormDetailsId'
																)}_${getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')}`}
																onClick={(e) => {
																	handleClose();
																	handleEmployeeActionClick(
																		e,
																		3,
																		section,
																		sectionIndex,
																		question,
																		questionIndex,
																		employeeDetails
																	);
																}}
															>
																<ListItemAvatar>
																	<RequestOneOnOneIcon2 />
																</ListItemAvatar>
																<ListItemText primary={t('requestOnetoOneText')} />
															</ListItem>
														</>
													}
												></PopperMenu>
											) : (
												<></>
											)}
										</Box>
									</Box>
								</Box>
							) : (
								<></>
							)}
							<Box className='user-assess-ques-right'>
								<Box
									className={`assessment-radio-box ${
										getDetailsBasedOnKey('isQuestionError') ? 'assessment-radio-box-error' : ''
									}`}
								>
									{question?.performanceRoleLinkedFormScales &&
									question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails &&
									question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails?.length ? (
										<RadioGroup
											aria-labelledby='perform-score-buttons-label'
											onChange={(event: any) => {
												const { value } = event.target;
												handleChangeMultiChoiceQuestionValue(
													event,
													value ? parseInt(value) : value,
													question,
													questionIndex,
													section,
													sectionIndex
												);
											}}
										>
											{question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails?.map(
												(scale: any, scaleIndex: number) => {
													return (
														<FormControlLabel
															control={<Radio />}
															disabled={isViewMode}
															value={scale?.performanceRoleLinkedFormScaleDetailId}
															label={scale?.name || ''}
															checked={getSelectedMultiChoiceValue(
																getDetailsBasedOnKey('ratingValue')
																	? parseInt(getDetailsBasedOnKey('ratingValue'))
																	: getDetailsBasedOnKey('ratingValue'),
																scale?.performanceRoleLinkedFormScaleDetailId
															)}
														/>
													);
												}
											)}
										</RadioGroup>
									) : (
										<></>
									)}
								</Box>
								{callingFrom === 'AdminAssessmentView' && isViewMode ? (
									<Box className={'assess-comment-box'}>
										<OKRButton
											id='comment-btn'
											className='btn-small-text assess-comments-btn'
											icon={<CommentIcon />}
											disabled={true}
											text={
												<>
													{t('Comments')} {question?.isCommentBoxMandatory ? <i style={{ color: 'red' }}>*</i> : <></>}
												</>
											}
											handleClick={(e: any) => {}}
										/>
										<Box className='assess-comment-field editior-pane preview-overlay'>
											<CkEditor
												placeholder={t('writeYourComments')}
												showEmoticons={true}
												value={''}
												handleEditorChange={(value: string) => {}}
											/>
										</Box>
									</Box>
								) : question?.isCommentBoxAvailable && getDetailsBasedOnKey('questionCommentBox') && isViewMode ? (
									<Box className='view-only-comment long-text'>
										<Box
											dangerouslySetInnerHTML={{
												__html: getDetailsBasedOnKey('questionCommentBox') || '-',
											}}
										></Box>
									</Box>
								) : question?.isCommentBoxAvailable && !isViewMode ? (
									<Box
										className={`assess-comment-box ${
											getDetailsBasedOnKey('isQuestionCommentError') ? 'assess-comment-box-error' : ''
										}`}
									>
										{getDetailsBasedOnKey('isQuestionCommentError') ? (
											<Box className='error-field'>
												<Typography variant='body2' className='helperText-bottom'>
													{getDetailsBasedOnKey('isQuestionCommentErrorMsg')}
												</Typography>
											</Box>
										) : (
											<></>
										)}
										<OKRButton
											id='comment-btn'
											className='btn-small-text assess-comments-btn'
											icon={<CommentIcon />}
											text={
												<>
													{t('Comments')} {question?.isCommentBoxMandatory ? <i style={{ color: 'red' }}>*</i> : <></>}
												</>
											}
											handleClick={(e: any) =>
												handleCommentsToggle(
													e,
													getDetailsBasedOnKey('performanceRoleLinkedFormDetailsId'),
													question,
													questionIndex,
													section,
													sectionIndex
												)
											}
										/>
										<Collapse in={getDetailsBasedOnKey('isCommentExpand')} className='assessment-comments-field'>
											<Box className='assess-comment-field editior-pane'>
												{/* {isViewMode ? (
													<Box
														dangerouslySetInnerHTML={{
															__html: getDetailsBasedOnKey('questionCommentBox') || '',
														}}
													></Box>
												) : (  */}
												<CkEditor
													placeholder={t('writeYourComments')}
													showEmoticons={true}
													value={getDetailsBasedOnKey('questionCommentBox') || ''}
													handleEditorChange={(value: string) => {
														if (getDetailsBasedOnKey('questionCommentBox') !== value) {
															handleChangeQuestionComment(value, question, questionIndex, section, sectionIndex);
														}
													}}
												/>
												{/* )} */}
											</Box>
										</Collapse>
									</Box>
								) : (
									<></>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
