import React, { useEffect, useState } from 'react';
import { Box, Typography, Collapse, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

import { StarBorderIcon, StarFilledIcon } from '../../../config/svg/RatingSvg';
import { CommentIcon, SelfReviewIcon } from '../../../config/svg/CommonSvg';
import { OKRButton } from '../../Common/OKRButton';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { goalRemovePlugin } from '../../Common/CkEditor/CkEditorEnums';
import { ErrorIcon } from '../../../config/svg/formElementIcons';

const labels: { [index: number]: string } = {
	1: 'Not Meeting Expectations',
	2: 'Successful',
	3: 'Often Exceeding Expectations',
	4: 'Consistently Exceeding Expectations',
};

const labelslist: any[] = [
	{
		id: 1,
		value: 1,
		name: 'Not Meeting Expectations',
	},
	{
		id: 2,
		value: 2,
		name: 'Successful',
	},
	{
		id: 3,
		value: 3,
		name: 'Often Exceeding Expectations',
	},
	{
		id: 4,
		value: 4,
		name: 'Consistently Exceeding Expectations',
	},
];

export const RadioRating = (props: any) => {
	const {
		question,
		section,
		handleChangeStarQuestionValue,
		handleChangeStarQuestionTextValue,
		questionIndex,
		sectionIndex,
		handleChangeStarQuestionHoverValue,
		getLabelText,
		t,
		hideError = false,
		performanceGoalsList,
		scorekey = 'score',
		headerIcon = <SelfReviewIcon />,
		headerKey = 'selfReviewLevel',
		fromTeamDetails = false,
	} = props;
	const [hover, setHover] = useState<number>(-1);
	const [openComments, setOpenComments] = useState<boolean>(question.openComments || false);

	useEffect(() => {
		if (!performanceGoalsList?.isEditable) {
			setOpenComments(true);
		}
	}, []);
	const getDetailsBasedOnKey = (keyName: string) => {
		return question[keyName];
	};

	const handleCommentsToggle = () => {
		setOpenComments((prev) => !prev);
	};
	return (
		<Box className='goal-self-review-area'>
			<Box className='goal-self-review-title helperText-bottom'>
				{headerIcon}
				<Typography>{t(headerKey)}</Typography>
				{question.isRatingError && !hideError ? (
					<Box className='error-field'>
						<ErrorIcon />
						{t('ratingIsRequired')}
					</Box>
				) : (
					''
				)}
			</Box>
			<Box
				className={`goal-review-radio-box ${question.isRatingError && !hideError ? 'goal-review-radio-box-error' : ''}`}
			>
				<RadioGroup
					row={performanceGoalsList.isHorizontal}
					aria-label='workflowEmployee'
					name='workflowEmployee'
					value={`${question[scorekey]}`}
					onChange={(e) => {
						let value = e.target.value;
						const newRating = Number(value);
						handleChangeStarQuestionValue(e, newRating, question, questionIndex, section, sectionIndex);
						setOpenComments(true);
					}}
				>
					{Object.entries(labels).map(([value, label]) => (
						// <Box key={value} textAlign='center' className='goal-review-star-rating'>
						<FormControlLabel
							disabled={!performanceGoalsList?.isEditable || fromTeamDetails}
							value={value}
							control={<Radio />}
							title={label}
							label={label}
						/>
						// </Box>
					))}
				</RadioGroup>
			</Box>
			<Box
				className={`goal-review-comment-box ${
					question.isCommentError && !hideError ? 'goal-review-comment-box-error' : ''
				}`}
			>
				{question.isCommentError && !hideError && (
					<Box className='error-field'>
						<ErrorIcon />
						<Typography variant='body2'>{t('commentIsRequired')}</Typography>
					</Box>
				)}
				{!fromTeamDetails && (
					<OKRButton
						id='comment-btn'
						className='btn-small-text goal-review-comments-btn'
						icon={<CommentIcon />}
						text={
							<>
								{t('commentsText')} {question?.isCommentBoxMandatory ? <i style={{ color: 'red' }}>*</i> : <></>}
							</>
						}
						handleClick={(e: any) => {
							handleCommentsToggle();
						}}
					/>
				)}
				<Collapse in={openComments} className={`${fromTeamDetails ? 'team-class' : ''} goal-review-comments-field`}>
					<Box className='goal-review-comments-editor-panel editior-pane preview-overlay'>
						{!performanceGoalsList?.isEditable ? (
							<Box
								className='long-text'
								dangerouslySetInnerHTML={{ __html: question.comment ? question.comment : '--' }}
							></Box>
						) : (
							<CkEditor
								placeholder={t('writeYourComments')}
								showEmoticons={true}
								value={question.comment}
								handleEditorChange={(value: string) => {
									handleChangeStarQuestionTextValue({}, value, question, questionIndex, section, sectionIndex);
								}}
								enableTagging={false}
								uploadImage={false}
								removedPlugins={goalRemovePlugin}
								removedPluginsList={[...goalRemovePlugin, 'insertTable']}
							/>
						)}
					</Box>
				</Collapse>
			</Box>
		</Box>
	);
};
