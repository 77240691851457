import React, { useEffect, useRef, useState } from 'react';
import { Box, Link, List, ListItem, Tab, Tabs, Typography } from '@material-ui/core';
import UploadIcon from '../../../images/upload-img-new.svg';
import { OKRButton } from '../../Common/OKRButton';
import { DeleteIcon, DownloadIcon } from '../../../config/svg/CommonSvgIcon';
import { ErrorIcon } from '../../../config/svg/formElementIcons';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';
import { UploadDocuments } from '../../PerformanceDevelopment/Common/UploadDocuments';
import { getPerformanceGoalDocument, setPerfomanceGoalAttachmentCount } from '../../../action/adminSettings';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../../config/enums';

export const CategoryAttachments: React.FC<any> = (props) => {
	const {
		t,
		feedbackFormDetails,
		feedbackFormType,
		updateFeedbackFormDetails,
		performanceGoalsList,
		goalDocumentCount,
		setGoalDocumentCount,
		setLoader,
		loader,
	} = props;
	const inputFileRef = useRef<HTMLInputElement>(null);
	const preventDefault = (event: any) => event.preventDefault();
	const dispatch = useDispatch();
	const [performanceGoalsDocument, setPerformanceGoalsDocument] = useState<any[]>([]);
	const [performanceGoalsDeletedDocument, setPerformanceGoalsDeletedDocument] = useState<any[]>([]);

	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });
	useEffect(() => {
		getUserPerformanceGoalsList();
	}, []);
	const getUserPerformanceGoalsList = async () => {
		try {
			setLoader(true);
			let requestParam: any = `moduleId=3&moduleDetailsId=${props.kr.performanceGoalLinkedFormCategoryId}&documentLinkId=${performanceGoalsList?.performanceGoalCycleEmployeeId}`;
			const response: any = await dispatch(getPerformanceGoalDocument(requestParam));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				let docList: any[] = [],
					deletedDocList: any[] = [];
				response?.data.entityList.forEach((fileData: any) => {
					if (fileData.isActive) {
						docList.push(fileData);
					} else {
						deletedDocList.push(fileData);
					}
				});
				setPerformanceGoalsDocument(docList);
				setPerformanceGoalsDeletedDocument(deletedDocList);
				dispatch(setPerfomanceGoalAttachmentCount(response?.data.entityList.length));
				setLoader(false);
			} else {
				dispatch(setPerfomanceGoalAttachmentCount(0));
				setLoader(false);
			}
		} catch (error) {}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		setModalProps({ open: false, type: '', message: '', module: '' });
	};
	return (
		<Box className='bulk-user-content category-attachment'>
			<Box className='drawer-input-field'>
				{console.log(props.kr)}
				<UploadDocuments
					{...props}
					updatePipFormDetails={() => {}}
					handleDelete={() => {
						getUserPerformanceGoalsList();
					}}
					pipFormDetails={{
						...feedbackFormDetails,
						documents: performanceGoalsDocument,
						performanceGoalsList: performanceGoalsList,
					}}
					featureListType={6}
					moduleDetailId={props.kr.performanceGoalLinkedFormCategoryId}
					pipFormType={feedbackFormType}
					setLoader={() => {}}
					documentList={performanceGoalsDocument}
					uploadDocumentType='goalsCategoryAttachments'
					showDeletedList={true}
					deletedDocumentList={performanceGoalsDeletedDocument}
				/>
			</Box>
			{modalProps.open && (
				<DialogComponent
					module='information'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Box>
	);
};
