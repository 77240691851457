import { deleteRequest, getRequest, postRequest, putRequest, downloadXLSFile } from '../config/auth';
import {
	ADD_PERFORMANCE_CYCLE,
	PERFORMANCE_CYCLE_EMPLOYEE,
	PERFORMANCE_CYCLE_LINKED_FORM,
	PERFORMANCE_CYCLE_LIST,
	PERFORMANCE_CYCLE_OVERLAP,
	PERFORMANCE_ROLE_ASSIGNED,
	PUBLISH_PERFORMANCE_CYCLE,
	RATING_FORM,
	RATING_FORM_DETAILS,
	RATING_FORM_LIST,
	PERFORMANCE_CYCLE_CYCLE,
	PERFORMANCE_CYCLE_ACTIVE_CYCLE,
	PERFORMANCE_CYCLE_EMPLOYEE_INCLUDE,
	PERFORMANCE_ROLE_DETAIL,
	IS_NEW_PERFORMANCE_ROLE,
	USER_DEPARTMENT_LIST,
	QUESTION_TYPE_MASTER,
	ADD_UPDATE_SCALE,
	MANAGE_SCALE_LIST,
	PERFORMANCE_TAB_COUNT,
	DELETE_MANAGE_SCALE,
	UPDATE_MANAGE_SCALE_STATUS,
	ADMIN_PREVIEW_FORM,
	GET_GOALS_FORM,
	GET_GOALS_CYCLE_LIST,
	GET_GOALS_CYCLE_RECORD_LIST,
	GOAL_SETTING_WORKFLOW_REVERSAL_CYCLE_LIST,
	GET_GOALS_CYCLE_DETAILS,
	PERFORMANCE_GOALS_CYCLE,
	IS_TIMEFRAME_OVERLAP,
	ASSIGN_USER_SEARCH_BY_NAME,
	VALIDATE_MANUAL_ASSIGNED_USER,
	VALIDATE_BULK_ASSIGNED_USER,
	PERFORMANCE_GOAL_TAB_COUNT,
	PERFORMACE_GOAL_FORM,
	PERFORMACE_RESPONSE_TYPE,
	GOAL_FORM_DOWNLOAD,
	PERFORMANCE_GOAL_LIST,
	GOAL_CYCLE_SELECTOR,
	PERFORMANCE_GOAL_SAVE,
	PERFORMANCE_GOAL_DOCUMENT,
	PERFORMANCE_GOAL_FORM_DELETE_REPORT,
	EMPLOYEE_STATUS_REPORT,
	PERFORMANCE_GOAL_SETTING_REVERSAL_REPORT,
	PERFORMANCE_GOAL_TEAM_DETAILS,
	PERFORMANCE_GOAL_RESPONSE_LIST,
	GOAL_CYCLE_DOWNLOAD,
	PERFORMANCE_FORM_VALIDATE_BULK_UPLOAD,
	PERFORMANCE_FORM_BULK_UPLOAD,
	SAVE_PERFORMANCE_WORKFLOW,
	SAVE_PERFORMANCE_WORKFLOW_CURRENT_STAGE,
	POST_GOALS_REVIEW,
	GET_GOALS_REVIEW_LIST,
	EACH_GOAL_FORM_DOWNLOAD,
	PERFORMANCE_GOAL_WORKFLOW,
	SEARCH_ONGOING_EMPLOYEE,
	GOAL_CONVERSATION_ALERT,
	GET_WORKFLOW_REVERSAL_LIST,
	GET_REVIEW_WORKFLOW_REVERSAL_LIST,
	WORKFLOW_REVERSAL_DOWNLOAD,
	GET_GOAL_REVIEW_LIST,
	GET_GOALS_REVIEW_WORKFLOW_LIST,
	GET_REVIEW_USER_LIST,
	GOALS_REVIEW_CYCLE_DELETE,
	GET_SELF_REVIEW_LISTING,
	GOAL_REVIEW_SAVE,
	GET_RATING_DROPDOWN_DATA,
	TEAM_REVIEW_USER_LIST,
	PERMISSION_SEARCH,
	PERMISSION_FEEDBACK_SAVE,
	GET_SYSTEM_PERMISSION_DATA,
	PERMISSION_CATEGORY_SEARCH,
	UPDATE_GOALS_REVIEW,
	SAVE_FINAL_RATING_REVIEW,
	GET_FINAL_RATING_REVIEW,
	FINAL_RATING_LOG_LIST,
	FINAL_RECONSIDER,
	REVIEW_BULK_APPROVE,
	REVIEW_BULK_RECONSIDER,
	REVIEW_WORKFLOW_REVERSAL_CYCLE_LIST,
	SEARCH_REVIEW_WORKFLOW_ONGOING_EMPLOYEE,
	SAVE_REVIEW_WORKFLOW_REVERSAL,
	SAVE_REVIEW_WORKFLOW_CURRENT_STAGE,
	GOAL_REVIEW_CYCLE_DOWNLOAD,
} from '../config/api-url';

export const performanceCycleListAPI = (data) => postRequest(`${PERFORMANCE_CYCLE_LIST}`, data);
export const addPerformanceCycleAPI = (data) => postRequest(`${ADD_PERFORMANCE_CYCLE}`, data);
export const updatePerformanceCycleAPI = (data) => putRequest(`${ADD_PERFORMANCE_CYCLE}`, data);
export const deletePerformanceCycleAPI = (data) => deleteRequest(`${ADD_PERFORMANCE_CYCLE}?${data}`, data);
export const performanceRoleAssignedAPI = (data) => getRequest(`${PERFORMANCE_ROLE_ASSIGNED}?${data}`, data);
export const performanceRoleDetailAPI = (data) => getRequest(`${PERFORMANCE_ROLE_DETAIL}?${data}`, data);
export const userDepartmentListAPI = (data) => getRequest(`${USER_DEPARTMENT_LIST}?${data}`, data);
export const performanceCycleExcludeEmployeeListAPI = (data) => postRequest(`${PERFORMANCE_CYCLE_EMPLOYEE}`, data);
export const performanceCycleIncludeEmployeeAPI = (data) => putRequest(`${PERFORMANCE_CYCLE_EMPLOYEE_INCLUDE}`, data);
export const performanceCycleLinkedFormAPI = (data) => postRequest(`${PERFORMANCE_CYCLE_LINKED_FORM}`, data);
export const publishPerformanceCycleAPI = (data, queryParams) =>
	putRequest(`${PUBLISH_PERFORMANCE_CYCLE}?${queryParams}`, data);
export const performanceCycleCycleAPI = (data) => getRequest(`${PERFORMANCE_CYCLE_CYCLE}`, data);
export const performanceCycleActiveCycleAPI = (data) => getRequest(`${PERFORMANCE_CYCLE_ACTIVE_CYCLE}`, data);
export const isNewPerformanceRoleAPI = (data) => getRequest(`${IS_NEW_PERFORMANCE_ROLE}?${data}`, data);
export const performPreviewForm = (data) => getRequest(`${ADMIN_PREVIEW_FORM}?${data}`, data);

export const ratingFormListAPI = (data) => postRequest(`${RATING_FORM_LIST}`, data);
export const cycleDateOverlapAPI = (data) => getRequest(`${PERFORMANCE_CYCLE_OVERLAP}?${data}`, data);
export const ratingFormCreateAPI = (data) => postRequest(`${RATING_FORM}`, data);
export const ratingFormUpdateAPI = (data) => putRequest(`${RATING_FORM}`, data);
export const ratingFormDeleteAPI = (data) => deleteRequest(`${RATING_FORM}?${data}`, data);
export const ratingFormDetailsDeleteAPI = (data) => deleteRequest(`${RATING_FORM_DETAILS}`, data);

// Manage Scale
export const getQuestionTypeMasterDataAPI = (data) => getRequest(`${QUESTION_TYPE_MASTER}`, data);
export const getManageScaleListAPI = (data) => postRequest(`${MANAGE_SCALE_LIST}`, data);
export const addScaleDetailsAPI = (data) => postRequest(`${ADD_UPDATE_SCALE}`, data);
export const updateScaleDetailsAPI = (data) => putRequest(`${ADD_UPDATE_SCALE}`, data);
export const getPerformanceTabCountAPI = (data) => getRequest(PERFORMANCE_TAB_COUNT, data);
export const updateManageScaleStatusAPI = (data, queryParams) =>
	putRequest(`${UPDATE_MANAGE_SCALE_STATUS}?${queryParams}`, data);
export const deleteManageScaleAPI = (data) => deleteRequest(`${DELETE_MANAGE_SCALE}?${data}`, data);
export const goalFormListAPI = (data) => postRequest(`${GET_GOALS_FORM}`, data);
export const goalCycleListAPI = (data) => postRequest(`${GET_GOALS_CYCLE_LIST}`, data);
export const goalReviewWorkFlowListAPI = (data) => postRequest(`${GET_GOALS_REVIEW_WORKFLOW_LIST}`, data);
export const goalReviewAssignUserListAPI = (data) => postRequest(`${GET_REVIEW_USER_LIST}`, data);
export const workflowReversalListAPI = (data) => postRequest(`${GET_WORKFLOW_REVERSAL_LIST}`, data);
export const reviewWorkflowReversalListAPI = (data) => postRequest(`${GET_REVIEW_WORKFLOW_REVERSAL_LIST}`, data);
export const goalReviewListAPI = (data) => postRequest(`${GET_GOAL_REVIEW_LIST}`, data);
export const goalCycleRecordListAPI = (data) => getRequest(`${GET_GOALS_CYCLE_RECORD_LIST}`, data);
export const getWorkflowReversalCycleListAPI = (data) =>
	getRequest(`${GOAL_SETTING_WORKFLOW_REVERSAL_CYCLE_LIST}`, data);
export const getReviewWorkflowReversalCycleListAPI = (data) =>
	getRequest(`${REVIEW_WORKFLOW_REVERSAL_CYCLE_LIST}`, data);
export const goalCycleDetailsAPI = (data) => getRequest(`${GET_GOALS_CYCLE_DETAILS}?${data}`, data);
export const addGoalCycleAPI = (data) => postRequest(`${PERFORMANCE_GOALS_CYCLE}`, data);
export const isTimeframeOverlapAPI = (data) => getRequest(`${IS_TIMEFRAME_OVERLAP}?${data}`, data);
export const updateGoalCycleAPI = (data) => putRequest(`${PERFORMANCE_GOALS_CYCLE}`, data);
export const deleteGoalCycleAPI = (data) => deleteRequest(`${PERFORMANCE_GOALS_CYCLE}?${data}`, data);
export const assignUserSearchByNameAPI = (data) => postRequest(`${ASSIGN_USER_SEARCH_BY_NAME}`, data);
export const validateManualAssignedUserAPI = (data) => postRequest(`${VALIDATE_MANUAL_ASSIGNED_USER}`, data);
export const validateBulkAssignedUserAPI = (data) => postRequest(`${VALIDATE_BULK_ASSIGNED_USER}`, data);
export const getPerformanceGoalTabCountAPI = (data) => getRequest(PERFORMANCE_GOAL_TAB_COUNT, data);
export const savePerformanceGoalFormAPI = (data) => postRequest(`${PERFORMACE_GOAL_FORM}`, data);
export const updatePerformanceGoalFormAPI = (data) => putRequest(`${PERFORMACE_GOAL_FORM}`, data);
export const getPerformanceGoalResponseTypeAPI = (data) => getRequest(PERFORMACE_RESPONSE_TYPE, data);
export const exportGoalFormAPI = (data) => downloadXLSFile(`${GOAL_FORM_DOWNLOAD}${data}`, data);
export const exportGoalCycleAPI = (data) => downloadXLSFile(`${GOAL_CYCLE_DOWNLOAD}${data}`, data);
export const exportWorkflowReversalAPI = (data) => downloadXLSFile(`${WORKFLOW_REVERSAL_DOWNLOAD}${data}`, data);
export const exportEachGoalFormAPI = (data) => downloadXLSFile(`${EACH_GOAL_FORM_DOWNLOAD}?${data}`, data);
export const performanceGoalFormDeleteReportAPI = (data) =>
	downloadXLSFile(`${PERFORMANCE_GOAL_FORM_DELETE_REPORT}?${data}`, data);
export const performanceGoalFormDownloadStatusReportAPI = (data) =>
	downloadXLSFile(`${EMPLOYEE_STATUS_REPORT}?${data}`, data);
export const performanceGoalSettingReversalDownloadReportAPI = (data) =>
	downloadXLSFile(`${PERFORMANCE_GOAL_SETTING_REVERSAL_REPORT}?${data}`, data);
export const deleteGoalFormAPI = (data) => deleteRequest(`${PERFORMACE_GOAL_FORM}?${data}`, data);
export const getPerformanceGoalListAPI = (data) => getRequest(`${PERFORMANCE_GOAL_LIST}?${data}`, data);
export const getGoalCycleSelector = (data) => getRequest(`${GOAL_CYCLE_SELECTOR}?${data}`, data);
export const savePerformanceGoalUserAPI = (data) => postRequest(`${PERFORMANCE_GOAL_SAVE}`, data);
export const saveGoalReviewSaveAPI = (data) => postRequest(`${GOAL_REVIEW_SAVE}`, data);
export const getRatingDropdownDataAPI = (data) => getRequest(`${GET_RATING_DROPDOWN_DATA}?${data}`, data);
export const getTeamReviewUserListAPI = (data) => postRequest(`${TEAM_REVIEW_USER_LIST}`, data);
export const getPerformanceGoalAttachmentAPI = (data) => getRequest(`${PERFORMANCE_GOAL_DOCUMENT}?${data}`, data);
export const getPerformanceGoalTeamDetailsAPI = (data) => postRequest(`${PERFORMANCE_GOAL_TEAM_DETAILS}`, data);
export const getSelfReviewListAPI = (data) => getRequest(`${GET_SELF_REVIEW_LISTING}?${data}`, data);
export const getPermissionSearch = (data) => getRequest(`${PERMISSION_SEARCH}?${data}`, data);
export const getSystemPermissionData = (data) => getRequest(`${GET_SYSTEM_PERMISSION_DATA}?${data}`, data);
export const getCategoryAdditionalSearch = (data) => getRequest(`${PERMISSION_CATEGORY_SEARCH}?${data}`, data);
export const savePermissionSearch = (data) => postRequest(`${PERMISSION_FEEDBACK_SAVE}`, data);

export const getPerformanceGoalResponseListAPI = (data) =>
	getRequest(`${PERFORMANCE_GOAL_RESPONSE_LIST}?${data}`, data);
export const getFinalRatingLogAPI = (data) => getRequest(`${FINAL_RATING_LOG_LIST}?${data}`, data);

export const validateBulkUploadGoalFormApi = (data) => postRequest(`${PERFORMANCE_FORM_VALIDATE_BULK_UPLOAD}`, data);
export const bulkUploadGoalFormApi = (data) => postRequest(`${PERFORMANCE_FORM_BULK_UPLOAD}`, data);
export const savePerformanceWorkflowAPI = (data) => putRequest(`${SAVE_PERFORMANCE_WORKFLOW}`, data);
export const savePerformanceWorkflowCurrentStageAPI = (data) =>
	getRequest(`${SAVE_PERFORMANCE_WORKFLOW_CURRENT_STAGE}?${data}`, data);
export const searchOngoingApi = (data) => getRequest(`${SEARCH_ONGOING_EMPLOYEE}?${data}`, data);
export const getGoalConversationAlert = (data) => getRequest(`${GOAL_CONVERSATION_ALERT}`, data);
export const savePerformanceGoalReviewCycleAPI = (data) => postRequest(`${POST_GOALS_REVIEW}`, data);
export const deleteGoalReviewCycleAPI = (data) => deleteRequest(`${GOALS_REVIEW_CYCLE_DELETE}?${data}`, data);
export const getPerformanceGoalReviewCycleAPI = (data) => getRequest(`${GET_GOALS_REVIEW_LIST}`, data);
export const updatePerformanceGoalReviewCycleAPI = (data) => putRequest(`${UPDATE_GOALS_REVIEW}`, data);
export const saveFinalRatingReview = (data) => putRequest(`${SAVE_FINAL_RATING_REVIEW}`, data);
export const getFinalRatingReviewAPI = (data) => getRequest(`${GET_FINAL_RATING_REVIEW}?${data}`, data);
export const saveFinalReconsider = (data) => putRequest(`${FINAL_RECONSIDER}`, data);
export const reviewApproveBulkAPI = (data) => postRequest(`${REVIEW_BULK_APPROVE}`, data);
export const reviewReconsiderBulkAPI = (data) => postRequest(`${REVIEW_BULK_RECONSIDER}`, data);
export const searchReviewWorkflowOngoingAPI = (data) =>
	getRequest(`${SEARCH_REVIEW_WORKFLOW_ONGOING_EMPLOYEE}?${data}`, data);
export const saveReviewWorkflowReversalAPI = (data) => putRequest(`${SAVE_REVIEW_WORKFLOW_REVERSAL}`, data);
export const saveReviewWorkflowCurrentStageAPI = (data) =>
	getRequest(`${SAVE_REVIEW_WORKFLOW_CURRENT_STAGE}?${data}`, data);
export const exportGoalReviewCycleAPI = (data) => downloadXLSFile(`${GOAL_REVIEW_CYCLE_DOWNLOAD}${data}`, data);
