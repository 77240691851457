import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@material-ui/core';
import { GoalCycleIcon, GoalFormIcon, GoalWorkflowIcon } from '../../../../config/svg/MyGoalSvg';
import { getPerformanceGoalTabCount } from '../../../../action/adminSettings';
import { Enums } from '../../../../config/enums';
import { useDispatch, useSelector } from 'react-redux';

export const GoalReviewsSettingsTab: React.FC<any> = (props) => {
	const { a11yProps, tabSelected, handleTabChange } = props;

	const { t } = useTranslation();
	const dispatch = useDispatch();

	// useEffect(() => {
	// 	dispatch(getPerformanceGoalTabCount());
	// }, []);

	const handleTabClick = (event: any, newValue: Number) => {
		event.preventDefault();
		event.stopPropagation();
		handleTabChange(event, newValue);
	};

	return (
		<>
			<Tabs className='okr-tab-button align-setting-tab-button ' value={tabSelected} aria-label='Goal Review Tabs'>
				<Tab
					icon={<GoalCycleIcon />}
					label={`${t('reviewCycleLevel')}`}
					onClick={(e) => handleTabClick(e, 0)}
					value={0}
					{...a11yProps(0)}
				/>
				<Tab
					icon={<GoalWorkflowIcon />}
					label={`${t('reviewWorkflowReversal')}`}
					onClick={(e) => handleTabClick(e, 1)}
					value={1}
					{...a11yProps(0)}
				/>
			</Tabs>
		</>
	);
};
