import React, { useState, useEffect, Fragment } from 'react';
import {
	Box,
	List,
	Typography,
	Avatar,
	ListItem,
	FormControlLabel,
	Checkbox,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
} from '@material-ui/core';
import { Perform360Icon, SortingIcon, TickIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { AlertInfoIcon, DirectReportReviewIcon, PerformUserMgtIcon } from '../../../config/svg/CommonSvg';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';
import assessmentNodata from '../../../images/assessment-no-data.svg';
// import { ThreeSixtyAssessment } from './ThreeSixtyAssessment';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, getUserDetails, getMonthDateYear, getUserName } from '../../../config/utils';
import { getOkrMasterData } from '../../../action/myGoal';

import assessmentYetToBegin from '../../../images/assessment-yet-to-begin.svg';
import {
	getMultipleAssessment,
	getMultipleAssessmentForThreeSixty,
	getThreeSixtyAssessmentList,
	resetThreeSixtyAssessmentDetails,
	validateIsNomineeDeleted,
} from '../../../action/perform';
import moment from 'moment';
import { Enums } from '../../../config/enums';
import { useSnackbar } from 'notistack';
// import { MultipleThreeSixtyAssessment } from './MultipleThreeSixtyAssessment';
import { setNomineeDetailsForRedirection, showLoader } from '../../../action/common';
import AlertDialog from '../../Common/Dialog';
import { UserSnapshot } from '../../Common/UserSnapshot';
import { ThreeSixtyAssessmentDetail } from './ThreeSixtyAssessmentDetail';
import { MultipleThreeSixtyAssessmentDetails } from './MultipleThreeSixtyAssessmentDetails';
import { NoPerformanceRecord } from '../NoPerformanceRecord';

export const UserThreeSixtyList = (props: any) => {
	const { cycleData, tabSelected, setLoader, loader, handleOneOnOneClick, getEmployeeAssessmentDetails } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const userDetail = getUserDetails();
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const { performanceCycle, multipleAssessmentResponse } = useSelector((state: any) => state.performReducer);
	const { redirectedNomineeDetails } = useSelector((state: any) => state.commonReducer);
	// const {
	// 	teamAssessmentResponse,
	// 	multipleAssessmentResponse,
	// 	// employeeAssessmentCycleList,
	// 	performanceAlert,
	// 	teamAssessmentResponseStatus,
	// } = useSelector((state: any) => state.performReducer);

	const [threeSixtyAssessmentUserList, setThreeSixtyAssessmentUserList] = useState<any>(null);
	const [threeSixtyRoleList, setThreeSixtyRoleList] = useState<any>(null);
	const [dueDateDetails, setDueDateDetails] = useState<any>(null);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [performanceRatings, setPerformanceRatings] = useState<any>([]);
	const [openSnapshot, setOpenSnapshot] = useState(false);
	const [selectedUser, setSelectedUser] = useState({});
	const [selectedUserAssessment, setSelectedUserAssessment] = useState<any>({});
	const [assessmentType, setAssessmentType] = useState<string | null>(null);
	const [showAssessment, setShowAssessment] = useState(false);
	const [showMultipleThreeSixtyAssessment, setShowMultipleThreeSixtyAssessment] = useState(false);
	const [performanceRoleId, setPerformanceRoleId] = useState(0);
	const [isOkrProgressPercentageValue, setIsOkrProgressPercentageValue] = useState<boolean>(false);
	const [isThreeSixtyOpenValue, setIsThreeSixtyOpenValue] = useState<boolean>(false);
	const [isThreeSixtyOpenValueForFuture, setIsThreeSixtyOpenValueForFuture] = useState<boolean>(false);
	const [isThreeSixtyEnabledValue, setIsThreeSixtyEnabledValue] = useState<any>(null);
	// const [multipleAssessmentResponse, setMultipleAssessmentResponse] = useState<any>(null);
	const [showNote, setShowNote] = useState(false);
	const [showBulkOption, setShowBulkOption] = useState(false);
	const [checkedUser, setCheckedUser] = useState(0);
	const [alertPopUp, setAlertPopUp] = useState<boolean>(false);
	const [bottomMessageDetails, setBottomMessageDetails] = useState<any>(null);

	useEffect(() => {
		if (redirectedNomineeDetails) {
			checkIsNomineeDeleted(redirectedNomineeDetails);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectedNomineeDetails]);
	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}, []);
	useEffect(() => {
		if (performanceCycle?.performanceCycleId) {
			dispatch(resetThreeSixtyAssessmentDetails());
			const requestPayload = {
				performanceCycleId: performanceCycle?.performanceCycleId,
				performanceRoleId: 0,
				sortingText: '',
				order: '',
				finder: '',
			};
			getThreeSixtyAssessment(requestPayload, 0);
			if (!Boolean(okrMasterData)) {
				dispatch(getOkrMasterData());
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [performanceCycle]);
	useEffect(() => {
		if (okrMasterData?.performanceRatings) {
			setPerformanceRatings(okrMasterData.performanceRatings);
		}
	}, [okrMasterData]);

	const checkIsNomineeDeleted = async (nomineeDetails: any) => {
		try {
			const { cycleId, employeeId, nomineeForEmpId } = nomineeDetails || {};
			if (cycleId && employeeId && nomineeDetails) {
				const requestParams = `performanceCycleId=${cycleId}&nomineeEmployeeId=${employeeId}&nomineeForEmployeeId=${nomineeForEmpId}`;
				const response: any = await dispatch(validateIsNomineeDeleted(requestParams));
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response?.data || {};
					setAlertPopUp(!entity);
				} else {
					setAlertPopUp(true);
				}
				dispatch(setNomineeDetailsForRedirection(null));
			}
		} catch (error) {
			enqueueSnackbar(`${t('someErrorOccurred')}`, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (type === 2) {
			setAlertPopUp(false);
		} else {
			setAlertPopUp(false);
		}
	};
	const handleTeamFilter = (e: any, item: any) => {
		if (item.performanceRoleId !== performanceRoleId) {
			const requestPayload = {
				performanceCycleId: performanceCycle?.performanceCycleId,
				performanceRoleId: item.performanceRoleId,
				reportingManagerId: userDetail.employeeId,
				sortingText: sortingColumn,
				order: sortOrder,
				finder: '',
			};
			setLoader(true);
			getThreeSixtyAssessment(requestPayload, item.performanceRoleId);
		}
	};
	const getThreeSixtyAssessment = async (requestPayload: any, roleId: any) => {
		setLoader(true);
		setShowBulkOption(false);
		const response: any = await dispatch(getThreeSixtyAssessmentList(requestPayload));
		if (response && response?.status === Enums.STATUS_SUCCESS) {
			const { entity } = response?.data || {};
			if (entity) {
				threeSixtyAssessmentListData(entity, roleId);
			} else {
				setLoader(false);
				setThreeSixtyAssessmentUserList(null);
				setThreeSixtyRoleList(null);
				setDueDateDetails(null);
			}
		}
	};
	const threeSixtyAssessmentListData = (threeSixtyAssessmentList: any, roleIdValue: any) => {
		const {
			dueDate,
			nomineeDetails,
			performanceRoleEmployeeResponse,
			isOkrProgressPercentage,
			isThreeSixtyEnabled,
			isThreeSixtyOpen,
			scoreCalculatedUpon,
			threeSixtyStartDate,
			isThreeSixtyAssessmentPeriodOpenForFuture,
		} = threeSixtyAssessmentList || {};

		let diff = dueDate ? moment(new Date(formatDate(dueDate))).diff(new Date(formatDate(new Date())), 'days') : 0;
		if (dueDate && diff >= 0) {
			diff = diff + 1;
		}
		if (diff && diff > 0 && diff <= 7) {
			setDueDateDetails({
				daysRemaining: diff,
				dueDate: dueDate ? getMonthDateYear(dueDate) : null,
			});
		} else {
			setDueDateDetails({
				daysRemaining: 0,
				dueDate: dueDate ? getMonthDateYear(dueDate) : null,
			});
		}
		const roleId =
			roleIdValue === 0
				? performanceRoleEmployeeResponse.length
					? performanceRoleEmployeeResponse[0].performanceRoleId
					: 0
				: roleIdValue;

		setBottomMessageDetails({ scoreCalculatedUpon, threeSixtyStartDate, dueDate });
		setPerformanceRoleId(roleId);
		setThreeSixtyAssessmentUserList(nomineeDetails && nomineeDetails.length ? [...nomineeDetails] : []);
		let findDisabled =
			nomineeDetails && nomineeDetails.length
				? [...nomineeDetails].filter((item: any) => item.threeSixtyStatusId === Enums.TWO)
				: [];
		setShowNote(findDisabled?.length > 0 && findDisabled?.length !== nomineeDetails?.length ? true : false);
		setThreeSixtyRoleList(performanceRoleEmployeeResponse);
		setIsOkrProgressPercentageValue(isOkrProgressPercentage);
		setIsThreeSixtyEnabledValue(isThreeSixtyEnabled);
		setIsThreeSixtyOpenValue(isThreeSixtyOpen);
		setIsThreeSixtyOpenValueForFuture(isThreeSixtyAssessmentPeriodOpenForFuture || false);
		setLoader(false);
	};
	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		const requestPayload = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			performanceRoleId: performanceRoleId,
			reportingManagerId: userDetail.employeeId,
			sortingText: column,
			order: newSortOrder,
			finder: '',
		};
		getThreeSixtyAssessment(requestPayload, performanceRoleId);
		setSortingColumn(column);
	};
	const handleSnapshot = (e: any, user: any) => {
		setSelectedUser(user);
		setOpenSnapshot(true);
	};
	const handleDrawerClose = (e: any, type: any) => {
		setOpenSnapshot(false);
		setSelectedUser({});
	};
	const threeSixtyTakeAssessment = (e: any, user: any, editType: string) => {
		setAssessmentType(editType || null);
		setSelectedUserAssessment(user);
		setShowAssessment(true);
	};
	const backClicked = (resetData = false) => {
		setAssessmentType(null);
		setSelectedUserAssessment({});
		setShowAssessment(false);
		handleBack();
		if (resetData) {
			const requestPayload = {
				performanceCycleId: performanceCycle?.performanceCycleId,
				performanceRoleId: performanceRoleId,
				sortingText: '',
				order: '',
				finder: '',
			};
			getThreeSixtyAssessment(requestPayload, performanceRoleId);
		}
	};
	const renderThreeSixtyUserList = () => {
		const requestPayload = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			performanceRoleId: performanceRoleId,
			sortingText: '',
			order: '',
			finder: '',
		};
		getThreeSixtyAssessment(requestPayload, performanceRoleId);
	};

	const handleIndeterminate = () => {
		let findChecked = threeSixtyAssessmentUserList.filter(
			(item: any) => item.isChecked && (item.threeSixtyStatusId === Enums.ONE || item.threeSixtyStatusId === Enums.ZERO)
		);
		let checkableItems = threeSixtyAssessmentUserList.filter(
			(item: any) => item.threeSixtyStatusId === Enums.ONE || item.threeSixtyStatusId === Enums.ZERO
		);
		if (findChecked.length > 0 && findChecked.length !== checkableItems.length) {
			return true;
		} else {
			return false;
		}
	};
	const handleAllChecked = () => {
		let findChecked = threeSixtyAssessmentUserList.filter(
			(item: any) => item.isChecked && (item.threeSixtyStatusId === Enums.ONE || item.threeSixtyStatusId === Enums.ZERO)
		);
		let checkableItems = threeSixtyAssessmentUserList.filter(
			(item: any) => item.threeSixtyStatusId === Enums.ONE || item.threeSixtyStatusId === Enums.ZERO
		);
		if (checkableItems.length > 0 && findChecked.length === checkableItems.length) {
			return true;
		} else {
			return false;
		}
	};
	const onSelectAllClick = (e: any) => {
		let { checked } = e.target;
		let updateCheck =
			threeSixtyAssessmentUserList &&
			threeSixtyAssessmentUserList?.map((item: any) => {
				return {
					...item,
					isChecked: item.threeSixtyStatusId === Enums.TWO ? false : checked,
				};
			});
		setThreeSixtyAssessmentUserList(updateCheck);
		let checkedLength = updateCheck.filter((user: any) => user.isChecked);
		setCheckedUser(checkedLength.length);
		if (checked && checkedLength.length >= 2) {
			setShowBulkOption(true);
		} else {
			setShowBulkOption(false);
		}
	};
	const onUserSelect = (e: any, index: number) => {
		const { checked } = e.target;
		let updateCheck = [...threeSixtyAssessmentUserList];
		updateCheck[index].isChecked = checked;
		const findChecked = updateCheck.filter((item: any) => item.isChecked).length;
		setCheckedUser(findChecked);
		if (findChecked > 1) {
			setShowBulkOption(true);
		} else {
			setShowBulkOption(false);
		}
		setThreeSixtyAssessmentUserList(updateCheck);
	};
	const resetCheckList = () => {
		let updateList = [...threeSixtyAssessmentUserList].map((item: any) => {
			return {
				...item,
				isChecked: false,
			};
		});
		setThreeSixtyAssessmentUserList(updateList);
		setShowBulkOption(false);
	};
	const handleMultipleManagerAssessment = async () => {
		try {
			// setLoader(true);
			dispatch(showLoader(true));
			const userFilter: any = [];
			threeSixtyAssessmentUserList.forEach((item: any) => {
				if (item.isChecked) {
					userFilter.push(item.employeeId);
				}
			});
			// const requestPayload = {
			// 	performanceCycleId: performanceCycle?.performanceCycleId,
			// 	employeeIds: userFilter,
			// 	typeId: Enums.THREE,
			// };
			const data = {
				performanceCycleId: performanceCycle?.performanceCycleId,
				employeeIds: userFilter,
				performanceRoleId:
					threeSixtyRoleList && threeSixtyRoleList?.length
						? threeSixtyAssessmentUserList && threeSixtyAssessmentUserList?.length
							? threeSixtyAssessmentUserList[0].performanceRoleId
							: Enums.ZERO
						: Enums.ZERO,
				ratingAssessmentType: Enums.THREE,
			};
			dispatch(getMultipleAssessment(data));
			setShowMultipleThreeSixtyAssessment(true);
			resetCheckList();
			// const response: any = await dispatch(getMultipleAssessmentForThreeSixty(requestPayload));
			// if (response && response?.status === Enums.STATUS_SUCCESS) {
			// 	const { entityList } = response?.data || {};
			// 	setMultipleAssessmentResponse(entityList);
			// 	setLoader(false);
			// 	setShowMultipleThreeSixtyAssessment(true);
			// 	resetCheckList();
			// } else {
			// 	setLoader(false);
			// 	enqueueSnackbar(`${t('someErrorOccurred')}`, {
			// 		variant: 'error',
			// 		autoHideDuration: 5000,
			// 	});
			// }
		} catch (error) {
			setLoader(false);
			enqueueSnackbar(`${t('someErrorOccurred')}`, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleBack = () => {
		resetCheckList();
		setShowMultipleThreeSixtyAssessment(false);
	};

	return (
		<>
			{(!cycleData || !cycleData.performanceCycleId) && !loader ? (
				<>
					<Box className='assessment-no-record'>
						<Box className='no-record-message'>
							<Typography variant='h2'>{t('threeSixtyPerformCycleNotStartedTitle')}</Typography>
							<Typography>{t('threeSixtyPerformCycleNotStartedSubTitle')}</Typography>
							<img width='470' alt={t('assessmentYetToBegin1')} src={assessmentYetToBegin} />
						</Box>
					</Box>
				</>
			) : showAssessment ? (
				<ThreeSixtyAssessmentDetail
					performanceCycle={performanceCycle}
					setLoader={setLoader}
					okrMasterData={okrMasterData}
					performanceRatings={performanceRatings}
					setPerformanceRatings={setPerformanceRatings}
					employeeId={selectedUserAssessment?.employeeId}
					backClicked={backClicked}
					handleOneOnOneClick={handleOneOnOneClick}
					performanceRoleId={performanceRoleId}
					getThreeSixtyAssessment={renderThreeSixtyUserList}
					selectedUserAssessment={selectedUserAssessment}
					canEditQuestionsWithRating={!performanceCycle?.isCycleCompleted}
					assessmentType={assessmentType}
					getEmployeeAssessmentDetails={getEmployeeAssessmentDetails}
				/>
			) : showMultipleThreeSixtyAssessment ? (
				<>
					<MultipleThreeSixtyAssessmentDetails
						callingType={'ThreeSixtyBulkAssessment'}
						performanceCycle={performanceCycle}
						loader={loader}
						setLoader={setLoader}
						backClicked={backClicked}
						employeeId={selectedUserAssessment?.employeeId}
						selectedUserAssessment={selectedUserAssessment}
						handleBack={() => {
							backClicked(true);
							handleBack();
						}}
						userDetail={userDetail}
						multipleAssessmentResponse={multipleAssessmentResponse}
						performanceCycleId={performanceCycle?.performanceCycleId}
						getTeamAssessList={renderThreeSixtyUserList}
						performanceRoleId={performanceRoleId}
						performanceRatings={performanceRatings}
						handleOneOnOneClick={handleOneOnOneClick}
						handleSnapshot={handleSnapshot}
						getSelfAssessmentDetails={renderThreeSixtyUserList}
						dueDateDetails={dueDateDetails}
					/>
					{/* <MultipleThreeSixtyAssessment
						handleBack={handleBack}
						userDetail={userDetail}
						multipleAssessmentResponse={multipleAssessmentResponse}
						performanceCycleId={performanceCycle?.performanceCycleId}
						getTeamAssessList={renderThreeSixtyUserList}
						performanceCycle={performanceCycle}
						performanceRoleId={performanceRoleId}
						performanceRatings={performanceRatings}
					/> */}
				</>
			) : (
				<Box className='three-sixty-perform'>
					{isThreeSixtyEnabledValue && threeSixtyAssessmentUserList && threeSixtyAssessmentUserList.length ? (
						<>
							<Box className='my-teams-assessment-head'>
								<Box className='my-teams-assessment-head-left'>
									<Box className='my-teams-assessment-head-left-inner'>
										{/* {dueDateDetails && dueDateDetails?.dueDate && !dueDateDetails?.daysRemaining ? (
										<Box className='days-remaining'>
											<Typography className='due-date' variant='h6'>
												{`Due Date: ${dueDateDetails?.dueDate}`}
											</Typography>
										</Box>
									) : (
										<></>
									)} */}
										{dueDateDetails && dueDateDetails?.daysRemaining && dueDateDetails?.dueDate ? (
											<Box className='days-remaining alert-with-due-text'>
												<Typography className='alert-text' variant='h6'>
													{dueDateDetails?.daysRemaining !== 1 ? (
														<>
															<AlertInfoIcon /> {`${dueDateDetails?.daysRemaining} days Remaining`}
														</>
													) : null}
												</Typography>
												{/* <Typography className='due-date' variant='h6'>
												{`Due Date: ${dueDateDetails?.dueDate}`}
											</Typography> */}
												<Typography className='alert-text' variant='h6'>
													{dueDateDetails?.daysRemaining === 1 ? (
														<>
															<AlertInfoIcon /> {'Last day to submit 360 assessment'}
														</>
													) : null}
												</Typography>
											</Box>
										) : (
											<></>
										)}
										<List disablePadding>
											{threeSixtyRoleList && threeSixtyRoleList.length ? (
												threeSixtyRoleList?.map((role: any) => (
													<ListItem key={'index'}>
														<OKRButton
															disabled={performanceRoleId === role.performanceRoleId}
															className={
																performanceRoleId === role.performanceRoleId
																	? 'filter-grey-btn active'
																	: 'filter-grey-btn'
															}
															handleClick={(e) => handleTeamFilter(e, role)}
															text={
																<>
																	{role?.performanceRoleName} ({role?.employeeCount})
																</>
															}
														/>
													</ListItem>
												))
											) : (
												<></>
											)}
										</List>
									</Box>
								</Box>
							</Box>
							<TableContainer className='team-assessment-listing'>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align='center' className='user-col1 '>
												<Box className='cell-wrap'>
													<FormControlLabel
														control={
															<Checkbox
																icon={<BorderCheckboxIcon />}
																checkedIcon={<CheckedIcon />}
																indeterminateIcon={<UnCheckedIcon />}
																inputProps={{ 'aria-label': 'select Name' }}
																indeterminate={handleIndeterminate()}
																checked={handleAllChecked()}
																onChange={onSelectAllClick}
																disabled={performanceCycle?.isCycleCompleted || !isThreeSixtyOpenValue}
															/>
														}
														label=''
													/>
													<Typography
														onClick={(e) => sortUserList(e, 'fullName')}
														variant='body2'
														className={sortingColumn === 'fullName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
													>
														<span className='sortable-label'>
															<span className='txt-wrap'>{t('nameLabel')}</span> <SortingIcon />
														</span>
													</Typography>
												</Box>
											</TableCell>
											<TableCell align='center' className='user-col2'>
												<Box className='cell-wrap'>
													<Typography
														onClick={(e) => sortUserList(e, 'nomineeByFullName')}
														variant='body2'
														className={
															sortingColumn === 'nomineeByFullName' ? `sort-title ${sortOrder}` : 'sort-title asc'
														}
													>
														<span className='sortable-label'>
															<span className='txt-wrap'>{t('nominationBy')}</span> <SortingIcon />
														</span>
													</Typography>
												</Box>
											</TableCell>
											<TableCell align='center' className='user-col3 sixty-column'>
												<Box className='cell-wrap'>
													<Typography
														onClick={(e) => sortUserList(e, 'threeSixtyStatusId')}
														variant='body2'
														className={
															sortingColumn === 'threeSixtyStatusId' ? `sort-title ${sortOrder}` : 'sort-title asc'
														}
													>
														<span className='sortable-label'>
															<span
																className='txt-wrap'
																dangerouslySetInnerHTML={{ __html: t('assessment360degree') }}
															></span>
															<SortingIcon />
														</span>
													</Typography>
												</Box>
											</TableCell>
											{isOkrProgressPercentageValue ? (
												<TableCell align='center' className='user-col4'>
													<Box className='cell-wrap'>
														<Typography
															onClick={(e) => sortUserList(e, 'commitedOkrScore')}
															variant='body2'
															className={
																sortingColumn === 'commitedOkrScore' ? `sort-title ${sortOrder}` : 'sort-title asc'
															}
														>
															<span className='sortable-label'>
																<span className='txt-wrap'>{t('committedOKRs')}</span> <SortingIcon />
															</span>
														</Typography>
													</Box>
												</TableCell>
											) : (
												<></>
											)}
											{/* <TableCell align='center' className='user-col5'></TableCell> */}
										</TableRow>
									</TableHead>
									<TableBody>
										{threeSixtyAssessmentUserList?.map((rec: any, index: number) => (
											<Fragment key={`360_assessment_${index}_${rec.employeeId}`}>
												<TableRow className={'team-assessment-row'}>
													<TableCell className='user-col1'>
														<Box className='cell-wrap'>
															<FormControlLabel
																control={
																	<Checkbox
																		icon={<BorderCheckboxIcon />}
																		checkedIcon={<CheckedIcon />}
																		name={'list'}
																		checked={rec?.isChecked === undefined ? false : rec?.isChecked}
																		onChange={(e) => onUserSelect(e, index)}
																		key={index}
																		disabled={
																			rec.threeSixtyStatusId === Enums.TWO ||
																			performanceCycle?.isCycleCompleted ||
																			!isThreeSixtyOpenValue
																		}
																	/>
																}
																label=''
															/>

															<Box className='user-info'>
																<Box className='user-img'>
																	{rec?.imagePath ? (
																		<Avatar src={rec?.imagePath} />
																	) : (
																		<Avatar>
																			{getUserName({
																				fullName: '',
																				firstName: rec?.firstName,
																				lastName: rec?.lastName,
																			})}
																		</Avatar>
																	)}
																</Box>
																<Box className='user-info-details'>
																	<Typography>{`${rec?.firstName} ${rec?.lastName}`}</Typography>
																</Box>
															</Box>
														</Box>
													</TableCell>
													<TableCell className='user-col2'>
														<Box className='cell-wrap'>
															<Box className='user-info'>
																<Box className='user-img'>
																	{rec?.imagePath ? (
																		<Avatar src={rec?.imagePath} />
																	) : (
																		<Avatar>
																			{getUserName({
																				fullName: '',
																				firstName: rec?.nomineeByFirstName,
																				lastName: rec?.nomineeByLastName,
																			})}
																		</Avatar>
																	)}
																</Box>
																<Box className='user-info-details'>
																	<Typography>{`${rec?.nomineeByFirstName} ${rec?.nomineeByLastName}`}</Typography>
																</Box>
															</Box>
														</Box>
													</TableCell>
													<TableCell className='user-col3 sixty-column'>
														<Box className='cell-wrap'>
															{!rec?.threeSixtyScore && isThreeSixtyOpenValue ? (
																<OKRButton
																	className={`${performanceCycle?.isCycleCompleted && 'grey-btn'} btn-link`}
																	buttonType={performanceCycle?.isCycleCompleted ? 'disableButtonTooltip' : null}
																	text={
																		rec?.threeSixtyStatus === Enums.ONE ? t('Resume Assessment') : t('takeAssessment')
																	}
																	handleClick={(e: any) => {
																		threeSixtyTakeAssessment(e, rec, 'add');
																	}}
																	title={
																		performanceCycle?.isCycleCompleted
																			? 'Cannot take assessment as the performance cycle is over'
																			: null
																	}
																	disabled={performanceCycle?.isCycleCompleted}
																/>
															) : rec?.threeSixtyScore && isThreeSixtyOpenValue ? (
																<>
																	<Typography
																		onClick={(e: any) => {
																			threeSixtyTakeAssessment(e, rec, 'show');
																		}}
																		className='cursor-pointer clickable-text'
																	>
																		{rec?.threeSixtyScore}
																	</Typography>
																	{/* <OKRButton
																	className={'btn-link edit-button'}
																	text={t('showBtn')}
																	handleClick={(e: any) => {
																		threeSixtyTakeAssessment(e, rec, 'show');
																	}}
																/> */}
																</>
															) : !isThreeSixtyOpenValue ? (
																<OKRButton
																	className={`grey-btn btn-link`}
																	buttonType={'disableButtonTooltip'}
																	text={t('takeAssessment')}
																	handleClick={(e: any) => {}}
																	title={
																		isThreeSixtyOpenValueForFuture
																			? 'Assessment is yet to begin'
																			: 'Cannot take assessment as the last day for 360 assessment is over'
																	}
																	disabled={true}
																/>
															) : (
																<></>
															)}
														</Box>
													</TableCell>
													{isOkrProgressPercentageValue ? (
														<TableCell className='user-col4 blue-color-column'>
															<Box className='cell-wrap'>
																<Typography>{rec?.commitedOkrScore || 0}%</Typography>
															</Box>
														</TableCell>
													) : (
														<></>
													)}
													{/* <TableCell className='user-col5'>
														<Box className='cell-wrap'>
															<OKRButton
																className='icon-btn'
																icon={<PerformUserMgtIcon />}
																title={t('viewPerformanceSnapshot')}
																handleClick={(e) => handleSnapshot(e, rec)}
																//tooltipClassName='tooltip-layout3'
															/>
														</Box>
													</TableCell> */}
												</TableRow>
											</Fragment>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					) : isThreeSixtyEnabledValue &&
					  threeSixtyAssessmentUserList &&
					  threeSixtyRoleList &&
					  (threeSixtyAssessmentUserList.length === 0 || threeSixtyRoleList.length === 0) ? (
						<Box className='assessment-no-record'>
							<Box className='no-record-message'>
								<Typography variant='h2'>
									<span dangerouslySetInnerHTML={{ __html: t('noDataMsg360degree') }}></span>
								</Typography>
								<img width='470' alt={t('noDataMsg360degree')} src={assessmentNodata} />
							</Box>
						</Box>
					) : isThreeSixtyEnabledValue === false ? (
						<>
							<NoPerformanceRecord
								heading={t('noMsg360degree')}
								subHeading={t('Your score will be visible based on organization settings.')}
								imgAlt={t('noMsg360degree')}
							/>
							{/* <Box className='no-record-message'>
								<Typography variant='h2'>
									<span
										dangerouslySetInnerHTML={{
											__html: performanceCycle?.isCycleCompleted ? t('noMsg360degreeForPast') : t('noMsg360degree'),
										}}
									></span>
								</Typography>
								<img
									width='470'
									alt={performanceCycle?.isCycleCompleted ? t('noMsg360degreeForPast') : t('noMsg360degree')}
									src={assessmentNodata}
								/>
							</Box> */}
						</>
					) : (
						<></>
					)}
					{bottomMessageDetails &&
					bottomMessageDetails?.scoreCalculatedUpon &&
					bottomMessageDetails?.threeSixtyStartDate &&
					bottomMessageDetails?.dueDate &&
					isThreeSixtyEnabledValue &&
					threeSixtyAssessmentUserList &&
					threeSixtyAssessmentUserList.length ? (
						<Box className='team-assessment-note'>
							<Box className='note-box'>
								<Typography variant='h6'>Note:</Typography>
								<List>
									<ListItem>
										<Typography
											variant='h6'
											className='font-weight-normal'
											dangerouslySetInnerHTML={{
												__html: t('threeSixtyDuedates', {
													dueDate: `${getMonthDateYear(
														bottomMessageDetails?.threeSixtyStartDate
													)} to ${getMonthDateYear(bottomMessageDetails?.dueDate)}`,
												}),
											}}
										></Typography>
									</ListItem>
									<ListItem>
										<Typography
											variant='h6'
											className='font-weight-normal'
											dangerouslySetInnerHTML={{
												__html: t('threeSixtyNote', { count: bottomMessageDetails?.scoreCalculatedUpon || Enums.ZERO }),
											}}
										></Typography>
									</ListItem>
									<ListItem>
										<Typography variant='h6' className='font-weight-normal'>
											{t('threeSixtyNote1')}
										</Typography>
									</ListItem>{' '}
									<ListItem>
										<Typography variant='h6' className='font-weight-normal'>
											{t('threeSixtyNote2')}
										</Typography>
									</ListItem>
								</List>
							</Box>
						</Box>
					) : (
						<></>
					)}
					{/* {showNote && !performanceCycle?.isCycleCompleted && isThreeSixtyOpenValue && (
						<Box className={showBulkOption ? 'user-note bulk-active' : 'user-note'}>
							<Box className='note-box'>
								<Typography variant='h6' className='font-weight-normal'>
									{t('teamAssessmentNote')}
								</Typography>
							</Box>
						</Box>
					)} */}
					{showBulkOption && isThreeSixtyOpenValue ? (
						<Box className='bulk-user-edit-bar bulk-user-edit-bar-type1'>
							<Box className='bulk-user-selected'>
								<Typography variant='body1'>
									<TickIcon />
									{checkedUser} {t('selectedLabel')}
								</Typography>
							</Box>
							<Box className='bulk-user-action'>
								<List component='ul' disablePadding>
									<ListItem>
										<OKRButton
											icon={<Perform360Icon />}
											title={
												<>
													<span dangerouslySetInnerHTML={{ __html: t('takeThreeSixtyAssessment') }}></span>
												</>
											}
											handleClick={handleMultipleManagerAssessment}
											className='action-btn'
										></OKRButton>
									</ListItem>
								</List>
							</Box>
						</Box>
					) : (
						<></>
					)}
				</Box>
			)}

			{/* {openSnapshot ? (
				<UserSnapshotDrawer
					openSnapshot={openSnapshot}
					selectedUser={selectedUser}
					performanceCycle={performanceCycle}
					handleDrawerClose={handleDrawerClose}
					isTeamAssessment={true}
				/>
			) : (
				<></>
			)} */}
			{openSnapshot ? (
				<UserSnapshot
					openSnapshot={openSnapshot}
					selectedUser={selectedUser}
					// performanceCycle={performanceCycle}
					handleDrawerClose={handleDrawerClose}
					// isTeamAssessment={props.fromTeamAssessment ? true : false}
				/>
			) : (
				<></>
			)}
			{alertPopUp && isThreeSixtyEnabledValue && threeSixtyAssessmentUserList ? (
				<AlertDialog
					module='deleteMyObjective'
					message={t('isNomineeDeleted')}
					handleCloseModal={handleCloseModal}
					modalOpen={alertPopUp}
					isCancel={true}
				/>
			) : (
				<></>
			)}
		</>
	);
};
