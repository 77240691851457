import React, { useEffect, useRef, useState } from 'react';
import { Box, List, ListItem, Typography, Avatar } from '@material-ui/core';
import { getFinalRatingLog, getPerformanceGoalResponseLog } from '../../../action/adminSettings';
import { useDispatch } from 'react-redux';
import { Enums } from '../../../config/enums';
import { getMonthDateYearTime, getTime, getUserName } from '../../../config/utils';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import NotesImg from '../../../images/no-found-notes.svg';
import { ReportsTooltipIcon } from '../../../config/svg/CommonSvgIcon';

export const FinalRatingLog: React.FC<any> = (props) => {
	const { drUserData, t, kr } = props;
	const inputFileRef = useRef<HTMLInputElement>(null);
	const preventDefault = (event: any) => event.preventDefault();
	const dispatch = useDispatch();
	const [finalRatingResponse, setFinalRatingResponse] = useState<any[]>([]);

	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });
	useEffect(() => {
		responseLog();
	}, []);
	const responseLog = async () => {
		try {
			//let requestParam: any = `performanceGoalRatingAssessmentId=${props.kr.goalList.performanceGoalLinkedFormDetailId}&performanceGoalSetId=${performanceGoalsList?.performanceGoalSetId}`;
			let requestParam: any = `performanceGoalRatingAssessmentId=${kr.performanceGoalRatingAssessmentId}`;
			const response: any = await dispatch(getFinalRatingLog(requestParam));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setFinalRatingResponse(response?.data.entityList);
				//setLoader(false);
			} else {
				//setLoader(false);
			}
		} catch (error) {}
	};

	return (
		<Box className='response-log-area'>
			<Box className='information-message'>
				<Typography variant='h6' className='no-sentence-case'>
					<ReportsTooltipIcon />
					<span dangerouslySetInnerHTML={{ __html: t('finalRatingLogNotes') }}></span>
				</Typography>
			</Box>
			{finalRatingResponse && finalRatingResponse?.length ? (
				<List disablePadding>
					{finalRatingResponse?.map((item: any) => {
						return (
							<ListItem>
								<Box className='user-info v-center'>
									<Box className='user-img'>
										{item?.imagePath ? (
											<Avatar
												className='avatar-default'
												alt={`${item?.firstName} ${item?.lastName}`}
												src={item?.imagePath}
											/>
										) : (
											<Avatar className='avatar-default'>
												{getUserName({
													firstName: item?.firstName,
													lastName: item?.lastName,
													fullName: item?.fullname,
												})}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Typography variant='h4' className='font-weight-normal'>
											{item?.firstName} {item?.lastName}
										</Typography>
									</Box>
								</Box>
								<Box className='response-details'>
									<Box className='response-created-on'>
										<Typography variant='subtitle1' className='font-weight-normal'>
											{getTime(item?.createdOn)}
										</Typography>
									</Box>
									{/* {item.responseType === Enums.ONE ? (
										<></>
									) : item.responseType === Enums.TWO ? (
										<Box className='response-type-box'>
											<Typography variant='subtitle1' className='font-weight-normal'>
												Details:
											</Typography>
											<Box
												className='response-type-content'
												dangerouslySetInnerHTML={{
													__html: item.description || '',
												}}
											></Box>
										</Box>
									) : item.responseType === Enums.THREE ? (
										<Box className='response-type-box'>
											<Typography variant='subtitle1' className='font-weight-normal'>
												Target:
											</Typography>
											<Box className='response-type-content'>{item.description}</Box>
										</Box>
									) : item.responseType === Enums.FOUR ? (
										<Box className='response-type-box'>
											<Typography variant='subtitle1' className='font-weight-normal'>
												Date:
											</Typography>
											<Box className='response-type-content'>
												{item.descriptionDate ? getMonthDateYearTime(item.descriptionDate) : ''}
											</Box>
										</Box>
									) : item.responseType === Enums.FIVE ? (
										<Box className='response-type-box'>
											<Box className='response-type-box-col'>
												<Typography variant='subtitle1' className='font-weight-normal'>
													Target:
												</Typography>
												<Box className='response-type-content'>{item.description}</Box>
											</Box>
											<Box className='response-type-box-col'>
												<Typography variant='subtitle1' className='font-weight-normal'>
													Date:
												</Typography>
												<Box className='response-type-content'>
													{item.descriptionDate ? getMonthDateYearTime(item.descriptionDate) : ''}
												</Box>
											</Box>
										</Box>
									) : (
										<></>
									)} */}
									<Box className='response-type-box'>
										{item?.oneonOneDate && (
											<Box className='response-type-box-col'>
												<Typography variant='subtitle1' className='font-weight-normal'>
													{t('requestOnetoOne')}:
												</Typography>
												<Box className='response-type-content'>{getMonthDateYearTime(item?.oneonOneDate)}</Box>
											</Box>
										)}
										{item?.rating && (
											<Box className='response-type-box-col'>
												<Typography variant='subtitle1' className='font-weight-normal'>
													{t('ratingLabel')}:
												</Typography>
												<Box className='response-type-content'>{item?.rating}</Box>
											</Box>
										)}
									</Box>
									{item?.justificationToChangeTheRating && (
										<Box className='response-type-box'>
											<Typography variant='subtitle1' className='font-weight-normal'>
												{t('justifyCommentTitle')}:
											</Typography>
											<Box className='response-type-content'>{item?.justificationToChangeTheRating}</Box>
										</Box>
									)}
									<Box className='response-type-box'>
										<Box className='response-type-box-col'>
											<Typography variant='subtitle1' className='font-weight-normal'>
												{t('salaryIncrement')}:
											</Typography>
											<Box className='response-type-content'>
												{item?.eligibleForSalaryIncrement ? t('yesLabel') : t('noLabel')}{' '}
												{item?.salaryIncrement ? '(' + item?.salaryIncrement + ')' : ''}
											</Box>
										</Box>
										<Box className='response-type-box-col'>
											<Typography variant='subtitle1' className='font-weight-normal'>
												{t('promotionLabel')}:
											</Typography>
											<Box className='response-type-content'>
												{item?.eligibleForPromotion ? t('yesLabel') : t('noLabel')}{' '}
												{item?.promotionDesignation ? '(' + item?.promotionDesignation + ')' : ''}
											</Box>
										</Box>
									</Box>
									<Box className='response-type-box'>
										<Typography variant='subtitle1' className='font-weight-normal'>
											{t('commentsText')}:
										</Typography>
										<Box
											className='response-type-content'
											dangerouslySetInnerHTML={{
												__html: item?.finalComment || '',
											}}
										></Box>
									</Box>
								</Box>
							</ListItem>
						);
					})}
				</List>
			) : (
				<Box className='welcome-content'>
					<Box textAlign='center'>
						<Typography variant='h2'>{t('noFinalRatingLogHead')}</Typography>
						<Typography>{t('noFinalRatingLogSubHead')}</Typography>
						<AnimatedIcon
							className='no-response-image'
							width='280px'
							type='image/svg+xml'
							alt={t('noTaskHead')}
							imagePath={NotesImg}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};
