import {
	courseSearchAPI,
	getAllCourseListAPI,
	getEmployeeCourseListAPI,
	getCategoryCompunnelListAPI,
} from '../services/courses';

export const courseSearch = (payload) => {
	return () => {
		return courseSearchAPI(payload);
	};
};

export const getAllCourseList = (payload) => {
	return () => {
		return getAllCourseListAPI(payload);
	};
};
export const getEmployeeTrainingData = (payload) => {
	return () => {
		return getEmployeeCourseListAPI(payload);
	};
};
export const getCompunnelTrainingCategoryApi = (payload) => {
	return () => {
		return getCategoryCompunnelListAPI(payload);
	};
};
