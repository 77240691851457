import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Tooltip, Typography } from '@material-ui/core';
import { Enums } from '../../../config/enums';
import { OKRButton } from '../../Common/OKRButton';
import { CalendarIcon, EmojiDoneIcon } from '../../../config/svg/CommonSvgIcon';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { useTranslation } from 'react-i18next';
import { getUserDetails } from '../../../config/utils';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

export const WeekRangeTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

WeekRangeTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any,
	value: PropTypes.any,
};

function a11yProps(index: number, item: any) {
	return {
		id: item.checkInWeekType === 1 ? 'week-future-tab' : `weekrange-tabpanel-${index}`,
		'aria-controls': `weekrange-tabpanel-${index}`,
	};
}

export const WeekRangeTabs: React.FC<any> = (props) => {
	const {
		handleTabChange = {},
		tabSelected,
		checkInDates,
		handleSelectDate,
		selectedUser,
		totalWeeksCount,
		getCheckInWeekDate,
		checkinNewChip,
		showFutureWeekMessage = '',
		checkInFutureDates = [],
	} = props;
	const [showCheckinsTooltip, setShowCheckinsTooltip] = useState(false);
	const [completeByDate, setCompleteBy] = useState<any>(null);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);
	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 6);
	const { t } = useTranslation();
	const userDetail: any = getUserDetails();

	const checkWeekType: any = (checkInDates: any) => {
		checkInDates &&
			checkInDates.length > 0 &&
			checkInDates.some((element: any) => {
				if (element.checkInWeekType === -1) {
					return true;
				} else {
					return false;
				}
			});
	};

	const scrollTabs = () => {
		if (checkInDates && checkInDates.length > 0) {
			setTimeout(() => {
				const selectedEl = document.getElementById('week-future-tab');
				if (selectedEl) {
					selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
				}
			}, 400);
		}
	};

	useEffect(() => {
		scrollTabs();
	}, [checkInDates]);

	useEffect(() => {
		scrollTabs();
	}, []);

	useEffect(() => {
		if (showFutureWeekMessage) {
			setShowCheckinsTooltip(true);
		}
		const timer = setTimeout(() => {
			setShowCheckinsTooltip(false);
		}, 10000);

		return () => clearTimeout(timer);
	}, [showFutureWeekMessage]);

	const moment = extendMoment(Moment);
	const [stateDefinitions, setStateDefinitions] = useState<any>(null);
	const [dateRanges, setDateRanges] = useState<any>([]);
	// {
	// 	state: 'unavailable',
	// 	range: moment.range(moment().add(3, 'weeks'), moment().add(3, 'weeks').add(5, 'days')),
	// },
	useEffect(() => {
		if (checkInFutureDates && checkInFutureDates.length) {
			let futureDate: any = [];
			[...checkInFutureDates]?.forEach((rec: any) => {
				if (rec?.startDate && rec?.endDate) {
					futureDate.push({
						state: 'enquire',
						range: moment.range(new Date(rec?.startDate), new Date(rec?.endDate)),
					});
				}
			});
			if (futureDate && futureDate?.length) {
				setDateRanges([...futureDate]);
				setStateDefinitions({
					available: {
						color: null,
						label: 'Available',
					},
					enquire: {
						color: '#0CBC74',
						label: 'Enquire',
					},
					// unavailable: {
					// 	selectable: false,
					// 	color: '#78818b',
					// 	label: 'Unavailable',
					// },
				});
			} else {
				setDateRanges([]);
				setStateDefinitions(null);
			}
		} else {
			setDateRanges([]);
			setStateDefinitions(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkInFutureDates]);

	return (
		<>
			<Box className='tab-scroller'>
				{checkWeekType && totalWeeksCount > 2 && <span className='accomplished-text'>Accomplished Weeks</span>}

				<Tabs
					value={tabSelected}
					onChange={handleTabChange}
					indicatorColor='primary'
					textColor='primary'
					variant='scrollable'
					scrollButtons='on'
					aria-label='scrollable auto tabs example'
				>
					{checkInDates &&
						checkInDates.length > 0 &&
						checkInDates.map((item: any, index: number) => (
							<Tooltip
								key={index}
								arrow
								title={`${item.checkInStatusDetails}`}
								classes={{ popper: 'tooltip-layout-margin' }}
							>
								<Tab
									label={
										<>
											<span
												className={`week-status ${
													item.checkInStatus === Enums.CHECKED_IN_MISSED
														? 'checked-in-missed'
														: item.checkInStatus === Enums.NOT_YET_CHECKED_IN
														? 'not-yet-checked'
														: item.checkInStatus === Enums.STATUS_CHECKED_ID
														? 'checked-in'
														: item.checkInStatus === Enums.CHECKED_IN_NO_DATA
														? 'checked-no-data'
														: ''
												}`}
											></span>
											<span>{item.displayDate}</span>
										</>
									}
									className={
										item.checkInWeekType === -1
											? 'accomplished-tab'
											: item.checkInWeekType === 1
											? 'future-plan-tab planning-tab'
											: 'exc-plan-tab planning-tab'
									}
									{...a11yProps(index, item)}
									onClick={(e) => handleSelectDate(e, item)}
								/>
							</Tooltip>
						))}
				</Tabs>
				<Box className='select-checkin'>
					{/* <OKRButton className='select-week-btn' icon={<CalendarIcon />} title='Select Week' /> */}
					{!checkinNewChip ? (
						!showFutureWeekMessage ? (
							<Box className='select-week-btn'>
								<ReactDateRangePicker
									type={'FutureCheckIns'}
									startDateValue={null}
									selectedCycleDetails={{}}
									minimumDate={minDateValue}
									maximumDate={maxDateValue}
									handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
										if (range) {
											getCheckInWeekDate(range, start);
										}
									}}
									isDateRangeTextFieldVisible={false}
									isOnlyDueDateVisible={true}
									allCycleDetails={[]}
									isCycleVisible={false}
									isEndOfMonthVisible={false}
									numberOfCalendars={Enums.ONE}
									selectionType={'single'}
									selectedDateRange={completeByDate}
									setSelectedDateRange={setCompleteBy}
									placeholderText={'View Check-in weeks'}
									isDatePickerDisable={false}
									extraParams={
										dateRanges && dateRanges.length
											? { stateDefinitions: stateDefinitions, dateStates: dateRanges, defaultState: 'available' }
											: {}
									}
									popperClassName={'calender-control-popover future-checkins-calender'}
								/>
							</Box>
						) : (
							<Tooltip
								open={showCheckinsTooltip}
								title={
									<Box className='tooltip-text'>
										<EmojiDoneIcon />
										<Typography dangerouslySetInnerHTML={{ __html: showFutureWeekMessage }}></Typography>
									</Box>
								}
								placement='top'
								arrow
								classes={{ popper: 'checkins-future-week-tooltip' }}
							>
								<Box className='select-week-btn'>
									<ReactDateRangePicker
										type={'FutureCheckIns'}
										startDateValue={null}
										selectedCycleDetails={{}}
										minimumDate={minDateValue}
										maximumDate={maxDateValue}
										handleDateSelection={(
											range: any,
											start: any,
											end: any,
											selectionType: any,
											isCycleChanged: any
										) => {
											if (range) {
												getCheckInWeekDate(range, start);
											}
										}}
										isDateRangeTextFieldVisible={false}
										isOnlyDueDateVisible={true}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'single'}
										selectedDateRange={completeByDate}
										setSelectedDateRange={setCompleteBy}
										placeholderText={'View Check-in weeks'}
										isDatePickerDisable={false}
										extraParams={
											dateRanges && dateRanges.length
												? { stateDefinitions: stateDefinitions, dateStates: dateRanges, defaultState: 'available' }
												: {}
										}
									/>
								</Box>
							</Tooltip>
						)
					) : (
						<ReactDateRangePicker
							type={'FutureCheckIns'}
							startDateValue={null}
							selectedCycleDetails={{}}
							minimumDate={minDateValue}
							maximumDate={maxDateValue}
							handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
								if (range) {
									getCheckInWeekDate(range, start);
								}
							}}
							isDateRangeTextFieldVisible={false}
							isOnlyDueDateVisible={true}
							allCycleDetails={[]}
							isCycleVisible={false}
							isEndOfMonthVisible={false}
							numberOfCalendars={Enums.ONE}
							selectionType={'single'}
							selectedDateRange={completeByDate}
							setSelectedDateRange={setCompleteBy}
							placeholderText={'View Check-in weeks'}
							isDatePickerDisable={false}
							extraParams={
								dateRanges && dateRanges.length
									? { stateDefinitions: stateDefinitions, dateStates: dateRanges, defaultState: 'available' }
									: {}
							}
							futureCheckInsDisplayDate={checkinNewChip.displayDate}
							futureCheckInsIcon={
								<span
									className={`week-status ${
										checkinNewChip.checkInStatus === Enums.CHECKED_IN_MISSED
											? 'checked-in-missed'
											: checkinNewChip.checkInStatus === Enums.NOT_YET_CHECKED_IN
											? 'not-yet-checked'
											: checkinNewChip.checkInStatus === Enums.STATUS_CHECKED_ID
											? 'checked-in'
											: checkinNewChip.checkInStatus === Enums.CHECKED_IN_NO_DATA
											? 'checked-no-data'
											: ''
									}`}
								></span>
							}
						/>
					)}
				</Box>
			</Box>
		</>
	);
};
