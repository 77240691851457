import axios from 'axios';
import {
	clearLocalStorage,
	getLocalStorageItem,
	removeLocalStorageItem,
	setLocalStorageItem,
} from '../services/StorageService';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { CYCLE_DETAILS, ORG_CURRENT_CYCLE, TENANT_DATA_OKR } from './api-url';
// import { loginRequest, msalConfig } from '../config/azureAuthConfig';
// import { PublicClientApplication } from '@azure/msal-browser';
import { DefaultOrigin } from '../config/constant';
import { userIdentity, currentCycle, getCryptoToken } from '../config/utils';
import CryptoJS from 'crypto-js';
import store from '../store/configureStore';
import { cycleDetailsUpdated, showIdleStatePopup } from '../action/common';
import { WithoutHttpDefaultOrigin } from '../config/constant';

// const msalInstance = new PublicClientApplication(msalConfig);
const origin = window.location.host.indexOf('localhost') >= 0 ? WithoutHttpDefaultOrigin : window.location.host;
let myToken = '';

axios.interceptors.request.use((req) => {
	if (!req.isProfileUrl) {
		// we will not be require all these headers for loading profile image
		//set token in header
		const tokenId = getLocalStorageItem('tokenId');
		const accessTokenId = JSON.parse(getLocalStorageItem('accessTokenId'));
		req.headers['Authorization'] = 'Bearer ' + JSON.parse(tokenId);
		req.headers['AccessTokenId'] = accessTokenId;
	}
	return req;
});

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		const originalRequest = error.config;
		if (error.response.status === 500) {
			//window.location = '/500';
		} else if (error.response.status === 403) {
			window.location = '/forbidden';
		} else if (error.response.status === 401 && !originalRequest._retry) {
			return new Promise(function (resolve, reject) {
				refreshTokenDbLogin(originalRequest, 'resp', resolve, reject);
			});
		} else if (error.response.status === 400) {
			return error;
		} else {
			// reloadApplication();
		}
	}
);
// const refreshToken = (orgReq, type, resolve, reject) => {
// const msalAccounts = JSON.parse(getLocalStorageItem('msalAccounts'));
// msalInstance
// 	.acquireTokenSilent({
// 		...loginRequest,
// 		account: msalAccounts,
// 	})
// 	.then((response) => {
// 		const token = jwtDecode(response.idToken);
// 		if (token === null) {
// 			clearLocalStorage();
// 			sessionStorage.clear();
// 			window.location.href = window.location.origin;
// 		} else {
// 			const tokenExpTime = toDate(token.exp); // get current date
// 			if (tokenExpTime <= toDate(Date.now())) {
// 				clearLocalStorage();
// 				sessionStorage.clear();
// 				window.location.href = window.location.origin;
// 			} else {
// 				setLocalStorageItem('tokenId', JSON.stringify(response.idToken));
// 				setLocalStorageItem('accessTokenId', JSON.stringify(response.accessToken));
// 				if (type === 'resp') {
// 					axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.idToken;
// 					orgReq.headers['Authorization'] = 'Bearer ' + response.idToken;
// 					response.idToken && resolve(axios(orgReq));
// 				}
// 			}
// 		}
// 	})
// 	.catch((error) => {
// 		clearLocalStorage();
// 		sessionStorage.clear();
// 		window.location.href = window.location.origin;
// 	});
// };
// const refreshTokenPreRequest = (req) => {
// const msalAccounts = JSON.parse(getLocalStorageItem('msalAccounts'));
// return msalInstance.acquireTokenSilent({
// 	...loginRequest,
// 	account: msalAccounts,
// 	forceRefresh: true,
// });
// };

const refreshTokenDbLogin = (orgReq, type, resolve, reject) => {
	getTenantDataRequest()
		.then((response) => {
			const token = response.data.entity.accessToken;
			if (token === null) {
				clearLocalStorage();
				sessionStorage.clear();
				window.location.href = window.location.origin;
			} else {
				setLocalStorageItem('tokenId', JSON.stringify(token));
				setLocalStorageItem('accessTokenId', JSON.stringify(token));
				if (type === 'resp') {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
					orgReq.headers['Authorization'] = 'Bearer ' + token;
					token && resolve(axios(orgReq));
				}
			}
		})
		.catch((error) => {
			clearLocalStorage();
			sessionStorage.clear();
			window.location.href = window.location.origin;
		});
};

const refreshTokenDbLoginPreRequest = () => {
	return getTenantDataRequest();
};

const getTenantDataRequest = async () => {
	return await getRequestForTenantDetail(`${TENANT_DATA_OKR}`, { origin: origin, emailId: false });
};

export const getRequestForTenantDetail = (path, data, axiosConfig) => requestOKR(path, data, 'POST', axiosConfig);

const toDate = (eObj) => {
	let currentDate = new Date();
	let mEpoch = eObj;
	if (mEpoch < 10000000000) mEpoch *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)

	currentDate.setTime(mEpoch);
	return currentDate;
};

export const downloadXLSFile = async (path, data, method, axiosConfig) => {
	// Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const tenantId = getLocalStorageItem('TenantId');

	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	const token = jwtDecode(myToken || tokenId);
	let tokenExpTime = token && token?.exp ? toDate(token.exp) : 0; // get current date
	tokenExpTime.setHours(tokenExpTime.getHours(), tokenExpTime.getMinutes() - 5, 0, 0); //set token exp time to 5 min before
	axiosConfig = { ...axiosConfig, responseType: 'arraybuffer' };
	const headers = {
		'Content-Type': 'blob',
		OriginHost: originHost,
		TenantId: tenantId,
		UserIdentity: userIdentity(),
		CurrentCycle: currentCycle(),
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	};

	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};

export const downloadZIPile = async (path, data, method, axiosConfig) => {
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const tenantId = getLocalStorageItem('TenantId');

	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	const token = jwtDecode(myToken || tokenId);
	let tokenExpTime = token && token?.exp ? toDate(token.exp) : 0;
	tokenExpTime.setHours(tokenExpTime.getHours(), tokenExpTime.getMinutes() - 5, 0, 0);
	axiosConfig = { ...axiosConfig, responseType: 'arraybuffer' };
	const headers = {
		'Content-Type': 'application/json',
		OriginHost: originHost,
		TenantId: tenantId,
		UserIdentity: userIdentity(),
		CurrentCycle: currentCycle(),
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	};

	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};

export const request = async (path, data, method, axiosConfig) => {
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const loginRequired = getLocalStorageItem('signInStatus');
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const tenantId = getLocalStorageItem('TenantId');

	let headers = {};
	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	const token = jwtDecode(myToken || tokenId);
	let tokenExpTime = token && token?.exp ? toDate(token.exp) : 0; // get current date
	tokenExpTime.setHours(tokenExpTime.getHours(), tokenExpTime.getMinutes() - 5, 0, 0); //set token exp time to 5 min before

	if (loginRequired) {
		headers = {
			'Content-Type': 'application/json',
			OriginHost: originHost,
			TenantId: tenantId,
			UserIdentity: userIdentity(),
			CurrentCycle: currentCycle(),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		};
	} else {
		headers = {
			'Content-Type': 'application/json',
			OriginHost: originHost,
			TenantId: tenantId,
			UserIdentity: userIdentity(),
			CurrentCycle: currentCycle(),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		};
	}

	// console.log('request ==> tokenExpTime ==> ', tokenExpTime, toDate(Date.now()));
	if (loginRequired && userDetail) {
		if (!Boolean(token) || tokenExpTime <= toDate(Date.now())) {
			try {
				const isSSO = getLocalStorageItem('isSSO');
				// if (isSSO === 'false') {
				const response = await refreshTokenDbLoginPreRequest();
				if (response) {
					const idToken = response.data.entity.accessToken ? response.data.entity.accessToken : null;
					const accessToken = response.data.entity.accessToken ? response.data.entity.accessToken : null;
					if (!Boolean(idToken)) {
						reloadApplication();
					} else {
						if (idToken && accessToken) {
							myToken = idToken;
							setLocalStorageItem('tokenId', JSON.stringify(idToken));
							setLocalStorageItem('accessTokenId', JSON.stringify(accessToken));
							const getCycleDetailResp = await getNextCycleDetails();
							if (getCycleDetailResp) {
								return initiateAxiosCall(method, path, headers, data, axiosConfig);
							}
						} else {
							reloadApplication();
						}
					}
				} else {
					reloadApplication();
				}
				// } else {
				// 	const response = await refreshTokenPreRequest();
				// 	if (response) {
				// 		const idToken = response.idToken ? response.idToken : null;
				// 		const accessToken = response.accessToken ? response.accessToken : null;
				// 		const token = idToken ? jwtDecode(idToken) : null;
				// 		if (!Boolean(token)) {
				// 			reloadApplication();
				// 		} else {
				// 			const tokenExpTime = token.exp ? toDate(token.exp) : null;
				// 			if (tokenExpTime && tokenExpTime <= toDate(Date.now())) {
				// 				reloadApplication();
				// 			} else if (idToken && accessToken) {
				// 				myToken = idToken;
				// 				setLocalStorageItem('tokenId', JSON.stringify(idToken));
				// 				setLocalStorageItem('accessTokenId', JSON.stringify(accessToken));
				// 				const getCycleDetailResp = await getNextCycleDetails();
				// 				if (getCycleDetailResp) {
				// 					return initiateAxiosCall(method, path, headers, data, axiosConfig);
				// 				}
				// 			} else {
				// 				reloadApplication();
				// 			}
				// 		}
				// 	} else {
				// 		reloadApplication();
				// 	}
				// }
			} catch (error) {
				console.debug(error);
				store.dispatch(showIdleStatePopup(true));
			}
		} else {
			// const getCycleDetailResp = await getNextCycleDetails();
			// if (getCycleDetailResp) {
			return initiateAxiosCall(method, path, headers, data, axiosConfig);
			// }
		}
	} else {
		// const getCycleDetailResp = await getNextCycleDetails();
		// if (getCycleDetailResp) {
		return initiateAxiosCall(method, path, headers, data, axiosConfig);
		// }
	}
};

const reloadApplication = () => {
	clearLocalStorage();
	sessionStorage.clear();
	window.location.href = window.location.origin;
};

const initiateAxiosCall = (method, path, headers, data, axiosConfig) => {
	return axios({
		method,
		url: path,
		headers: headers,
		data,
		...(axiosConfig || {}),
	});
};

/**
 * Created new method for handling profile image api call
 * @param {string} path
 * @param {obkect*} data
 * @param {string*} method
 * @param {*object} axiosConfig
 * @returns promise
 */
const requestImage = (path, data, method, axiosConfig) => {
	return axios({
		method,
		url: path,
		data,
		...(axiosConfig || {}),
	});
};

export const requestFreeTrial = (path, data, method, axiosConfig) => {
	let headers = {};
	headers = {
		'Ocp-Apim-Subscription-Key': '00bcb85ed6ee444eb0abeed43f1b75fe',
		token: getCryptoToken(),
	};
	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};

export const requestOKR = async (path, data, method, axiosConfig) => {
	let headers = {};
	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;

	headers = {
		'Content-Type': 'application/json',
		OriginHost: originHost,
		'Ocp-Apim-Subscription-Key': process.env.REACT_APP_AZURE_SUBSCRIPTIONKEY,
		token: getCryptoToken(),
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	};

	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};

export const requestAIBot = async (path, data, method, originHost, axiosConfig) => {
	let headers = {};
	headers = {
		Authorization: '',
		AccessTokenId: '',
		OriginHost: originHost,
		'Ocp-Apim-Subscription-Key': process.env.REACT_APP_AZURE_SUBSCRIPTIONKEYBOT,
		token: getCryptoToken(),
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	};

	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};

export const requestAIBotDataReader = async (path, data, method, originHost, axiosConfig) => {
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const tenantId = getLocalStorageItem('TenantId');
	let headers = {};
	data.apim_header = {
		token: `Bearer ${tokenId}`,
		tenant: tenantId,
		origin_host: originHost,
	};
	headers = {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	};

	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};

export const postRequestFreeTrial = (path, data, axiosConfig) => requestFreeTrial(path, data, 'POST', axiosConfig);

export const getRequest = (path, data, axiosConfig) => request(path, data, 'GET', axiosConfig);
export const getRequestImage = (path, data, axiosConfig) => requestImage(path, data, 'GET', axiosConfig);

export const postRequest = (path, data) => request(path, data, 'POST');
export const postRequestOKR = (path, data) => requestOKR(path, data, 'POST');
export const patchRequest = (path, data) => request(path, data, 'PATCH');
export const deleteRequest = (path, data) => request(path, data, 'DELETE');
export const putRequest = (path, data) => request(path, data, 'PUT');
export const postRequestZip = (path, data) => downloadZIPile(path, data, 'POST');

export const getHeaders = () => {
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const tenantId = getLocalStorageItem('TenantId');
	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	return {
		//'Content-Type': 'multipart/form-data',
		//Origin: originHost,
		OriginHost: originHost,
		TenantId: tenantId,
		UserIdentity: userIdentity(),
		CurrentCycle: currentCycle(),
		Authorization: `Bearer ${tokenId}`,
		controlerheader: `${tokenId}`,
	};
};
export const uploadFile = (url, formData) => {
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const tenantId = getLocalStorageItem('TenantId');

	let headers = {};
	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	headers = {
		'Content-Type': 'multipart/form-data',
		Origin: originHost,
		TenantId: tenantId,
		UserIdentity: userIdentity(),
		CurrentCycle: currentCycle(),
		Authorization: `Bearer ${tokenId}`,
		controlerheader: `${tokenId}`,
	};

	return axios.post(url, formData, {
		headers: headers,
	});
};

const getNextCycleDetails = async () => {
	const curCycle = getLocalStorageItem('currentCycle');
	const curCycleData = curCycle ? JSON.parse(curCycle) : null;
	const { cycleEndDate } = curCycleData || {};
	const todayDate = moment(new Date(), 'DD-MM-YYYY');
	const endDate = moment(new Date(cycleEndDate), 'DD-MM-YYYY');
	const dayCount = todayDate.diff(endDate, 'days');
	if (dayCount >= 0) {
		const getCycleDetailResp = await orgCurrentCycleAction();
		if (getCycleDetailResp && getCycleDetailResp?.data?.entity) {
			const { organisationCycleId, cycleYear } = getCycleDetailResp?.data?.entity || {};
			// Set Current Cycle details into storage for further use
			const { cycleEndDate, cycleStartDate } = getCycleDetailResp?.data?.entity || {};
			setLocalStorageItem('currentCycle', JSON.stringify(getCycleDetailResp.data.entity));
			setLocalStorageItem('cycleId', JSON.stringify(organisationCycleId));
			setLocalStorageItem('currentCycleId', JSON.stringify(organisationCycleId));
			setLocalStorageItem('year', cycleYear);
			setLocalStorageItem('currentYear', cycleYear);
			setLocalStorageItem('cycleStartDate', cycleStartDate);
			setLocalStorageItem('currentCycleEndDate', cycleEndDate);
			setLocalStorageItem('cycleEndDate', cycleEndDate);
			removeLocalStorageItem('navigationId');

			const reqPayload = `organisationId=${organisationCycleId}`;
			const getCycleDetailsResp = await getCycleDetails(reqPayload);
			if (getCycleDetailsResp && getCycleDetailsResp?.data?.entity) {
				// Update all cycle details into storage
				setLocalStorageItem('cycleDetail', JSON.stringify(getCycleDetailsResp.data.entity));
				store.dispatch(cycleDetailsUpdated(true));
				setTimeout(() => {
					window.location.reload(true);
				}, [2000]);
				return true;
			} else {
				return true;
			}
		} else {
			return true;
		}
	} else {
		return true;
	}
};

const orgCurrentCycleAction = async () => {
	return await getRequestForCycleDetail(`${ORG_CURRENT_CYCLE}`, {});
};
const getCycleDetails = async (reqPayload) => {
	return await getRequestForCycleDetail(`${CYCLE_DETAILS}`, reqPayload);
};

export const getRequestForCycleDetail = (path, data, axiosConfig) =>
	requestForCycleDetail(path, data, 'GET', axiosConfig);

export const requestForCycleDetail = async (path, data, method, axiosConfig) => {
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const loginRequired = getLocalStorageItem('signInStatus');
	const tenantId = getLocalStorageItem('TenantId');

	let headers = {};
	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	const token = jwtDecode(myToken || tokenId);
	let tokenExpTime = token && token?.exp ? toDate(token.exp) : 0; // get current date
	tokenExpTime.setHours(tokenExpTime.getHours(), tokenExpTime.getMinutes() - 5, 0, 0); //set token exp time to 5 min before

	if (loginRequired) {
		headers = {
			'Content-Type': 'application/json',
			OriginHost: originHost,
			TenantId: tenantId,
			UserIdentity: userIdentity(),
			CurrentCycle: currentCycle(),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		};
	} else {
		headers = {
			'Content-Type': 'application/json',
			OriginHost: originHost,
			TenantId: tenantId,
			UserIdentity: userIdentity(),
			CurrentCycle: currentCycle(),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		};
	}

	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};
