import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Logo } from '../../config/svg/GlobalSvg';
import { MenuIcon } from '../../config/svg/HeaderActionSvg';
import { LeftArrow } from '../../config/svg/ArrowSvg';
import DrawerList from './DrawerList';
import PrimarySearch from './PrimarySearch';
import UserNavigation from './UserNavigation';
import MyAccount from './MyAccount';
import Notification from './Notification';
import TenureNavigation from './TenureNavigation';
import LinearLoader from '../Common/Loader';
import { GoalCycleTab } from './GoalCycleTab';
import { checkSupportPermissionType } from '../../config/utils';

import {
	AppBar,
	Toolbar,
	IconButton,
	Drawer,
	Box,
	Typography,
	Tooltip,
	Dialog,
	Slide,
	DialogContent,
	DialogContentText,
	Button,
} from '@material-ui/core';
import {
	clearLocalStorage,
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from '../../services/StorageService';
import {
	DASHBOARD,
	ENGAGE,
	HOME,
	LOGIN_INITIATOR_URL,
	RECOGNIZE,
	PERFORM,
	GLOBAL_Task,
	PERFORM_DEVELOPMENT,
	LETTER_GEN,
	GOAL_REVIEW,
} from '../../config/app-url';
import '../../styles/layout/header.scss';
import '../../styles/layout/global-task.scss';
import { ClientLogo } from '../Common/ClientLogo';
import {
	getCurrentUserDetails,
	getDifferenceBetweenDates,
	getEncryptString,
	unlockEncryptString,
	openPost,
	signalRConnection,
	getLearningUUrl,
} from '../../config/utils';
import { Enums } from '../../config/enums';
import { OKRButton } from '../Common/OKRButton';
import { CancelIcon } from '../../config/svg/Action';
import { HayHandIcon, LockIcon, TasksIcon } from '../../config/svg/CommonSvg';
import { useDispatch, useSelector } from 'react-redux';
import { currentDashboardTabForSO, updateUserVersion, showOnCreatePopup } from '../../action/common';
import { LeftTopMenu } from './LeftTopMenu';
import LeftBottomMenu from './LeftBottomMenu';
import jwtDecode from 'jwt-decode';
import CryptoJS from 'crypto-js';
import { LEARNING, LEARNINGU, UNLOCKAPPNAME, UNLOCKUTOKEN } from '../../config/constant';
import { GlobalTask } from './GlobalTask';
import { supportModuleAlignGoals, supportModuleAlignOkr } from '../Admin/AdminConstant';
export default function Header(props) {
	const isGuidedTourShow = process.env.REACT_APP_ISGUIDEDTOURSHOW;
	const {
		loaderStatus,
		listOkrResult,
		giveFeedbackStatus,
		askFeedbackStatus,
		resetFeedback,
		showGuidedTour,
		guideTourVisible,
		listOkrSuccess,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const { userVersion } = useSelector((state) => state?.commonReducer);
	const dispatch = useDispatch();
	const history = useHistory();
	const [profileOpen, setProfileOpen] = useState(false);
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const [open, setOpen] = useState(false);
	const [impersonateUserToastOpen, setImpersonateUserToastOpen] = useState(false);
	const [alreadyShown, setAlreadyShown] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [showTrialDialog, setShowTrialDialog] = useState(false);
	const { t } = useTranslation();
	const currentUser = getCurrentUserDetails();
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const accessTokenId = JSON.parse(getLocalStorageItem('accessTokenId'));
	const { okrCreationInProgress } = useSelector((state) => state.commonReducer);
	const [loader, setLoader] = useState(false);
	const { performanceGoalCycle } = useSelector((state) => state?.adminSettingReducer);

	//SignalR implement
	useEffect(() => {
		// console.log('Header ==> Values ===> ', history.location.pathname, props.isSignalRConnected, props.fromTab);
		const routeArr = [HOME, DASHBOARD, ENGAGE, RECOGNIZE];
		if (routeArr.includes(history.location.pathname) && !props.isSignalRConnected && !props.fromTab) {
			props.signalRConnectionStatus(true);
			signalRConnection(Enums.topicNotesTag);
			signalRConnection(Enums.topicAddContributor);
			signalRConnection(Enums.topicDeleteOKR);
			signalRConnection(Enums.topicConversationTag);
			signalRConnection(Enums.topicNudgeTeam);
			signalRConnection(Enums.topicUpdateOKRDueDate);
			// signalRConnection(Enums.topicOKRCoach);
			signalRConnection(Enums.topicAcceptOKR);
			signalRConnection(Enums.topicDeclineOKR);
			signalRConnection(Enums.topicGivePersonalFeedback);
			signalRConnection(Enums.topicPersonalFeedback);
			signalRConnection(Enums.topicFeedbackOneOnOne);
			signalRConnection(Enums.topicUpdateKrScore);
			signalRConnection(Enums.topicUpdateKrValueAlignmentMap);

			// Added new topic for new modules
			signalRConnection(Enums.topicRecognition);
			signalRConnection(Enums.topicRecognitionNotifications);
			signalRConnection(Enums.topicRequestOneToOne);
		}

		// signalRConnection(Enums.topicSignalR);
	}, [history.location]);

	useEffect(() => {
		if (userVersion && userVersion?.showTooltip) {
			setTimeout(() => {
				dispatch(updateUserVersion());
			}, 3000);
		}
	}, [userVersion]);

	useEffect(() => {
		if (props.getPermissionStatus === '') {
			//==== Get Employee profile related permission
			props.getEmployeePermission(undefined, history);
		}
		if (props.currentRoute === '/admin') {
			document.body.classList.add('admin-body-class');
			document.body.classList.remove('user-body-class');
		} else {
			document.body.classList.add('user-body-class');
			document.body.classList.remove('admin-body-class');
		}
		// return () => {
		// 	document.body.classList.remove('user-body-class');
		// };
	}, [history.location]);

	useEffect(() => {
		// if (listOkrSuccess === 'success') {
		// 	props.getGuidedTourActivity().then((res) => {
		// 		if (res.data.entity.toShowGuidedTour) {
		// 			showGuidedTour(true);
		// 		}
		// 	});
		// }
		if (
			props.getPermissionResult &&
			props.getPermissionResult.employeePermissions &&
			!props.getPermissionResult.employeePermissions.length
		) {
			props.getEmployeePermission(undefined, history);
		}
	}, [listOkrResult]);

	if (!userDetail) {
		//==== if clearing storage then
		//==== save redirect Url before clearing storage

		let redirectUrl = getLocalStorageItem('redirectUrl');
		let otherCycleObjective = getLocalStorageItem('otherCycleObjective');
		let empId = getLocalStorageItem('empId');
		clearLocalStorage();
		if (redirectUrl) {
			setLocalStorageItem('redirectUrl', redirectUrl);
			setLocalStorageItem('otherCycleObjective', otherCycleObjective);
		}
		if (empId) {
			setLocalStorageItem('empId', empId);
		}
		window.location = LOGIN_INITIATOR_URL;
	}

	const handleDialogCloseTrialExpiry = () => {
		setShowTrialDialog(false);
	};

	useEffect(() => {
		let isExpiryDialogShown = JSON.parse(getLocalStorageItem('expiryAlertShown'));
		if (tenantData && tenantData.demoExpiryDate && !isExpiryDialogShown) {
			setLocalStorageItem('expiryAlertShown', 'true');
			let today = new Date();
			let diffrenceInDate = getDifferenceBetweenDates(today, tenantData.demoExpiryDate);
			if (diffrenceInDate <= 2) {
				setShowTrialDialog(true);
			}
			setTimeout(() => {
				setShowTrialDialog(false);
			}, 3000);
		}
	}, [tenantData]);

	useEffect(() => {
		if (!currentUser && userDetail?.isImpersonatedUser && props.isImpersonateToastShowed === false) {
			setOpenDialog(true);
			setAlreadyShown(true);
			props.updateImpersonateToastStatus(true);
			setTimeout(() => {
				setOpenDialog(false);
			}, 3000);
		}
		return () => clearTimeout();
	}, [userDetail]);

	useEffect(() => {
		if (guideTourVisible) {
			setOpen(false);
		} else if (!guideTourVisible && props.isClosedByUser) {
			setOpen(true);
			props.userClosedGuidedTour(false);
		}
	}, [guideTourVisible]);

	useEffect(() => {
		if (open) {
			document.body.classList.add('guide-me-check');
		} else {
			document.body.classList.remove('guide-me-check');
		}
	}, [open]);

	const handleToggle = (flag) => {
		if (flag === 1) {
			setOpen((prevOpen) => !prevOpen);
		} else {
			setOpen(false);
		}
	};

	const openGuidedMenu = (e) => {
		e.preventDefault();
		setOpen(false);
		showGuidedTour(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const navigateToHomePage = (event) => {
		let navigate = checkEditOkr(event);
		if (navigate) {
			removeLocalStorageItem('currentUser');
			props.resetOkr();
			// history.push(HOME);
			// props.updateRoute(HOME);
			dispatch(currentDashboardTabForSO(false));
			props.currentDashboardTab(0);
			props.updateRoute(DASHBOARD);
			history.push(DASHBOARD);
		}
	};

	const checkDrawer = (event) => {
		let navigate = checkEditOkr(event);
		if (!navigate) {
			return false;
		}
		if (open) {
			setOpen(false);
		}
	};

	const checkSupportPermission = (moduleId) => {
		const { modules } = tenantData || {};
		const permission = modules && modules?.length ? modules.filter((item) => item === moduleId) : [];
		if (permission.length) {
			return true;
		} else {
			return false;
		}
	};

	// useEffect(() => {
	// 	window.onfocus = function () {
	// 		const url = getLocalStorageItem('currentRoute');
	// 		if (url && url !== props.currentRoute) {
	// 			props.updateRoute(url);
	// 			window.location.href = url;
	// 		} else if (
	// 			getLocalStorageItem('userDetail') &&
	// 			userDetail &&
	// 			userDetail.employeeId !== JSON.parse(getLocalStorageItem('userDetail'))?.employeeId
	// 		) {
	// 			props.updateRoute(url);
	// 			window.location.href = url;
	// 		}
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props]);

	useEffect(() => {
		if (giveFeedbackStatus === 'success') {
			enqueueSnackbar(t('givenFeedback'), {
				variant: 'success',
			});
		} else if (giveFeedbackStatus === 'error') {
			enqueueSnackbar(t('givingFeedbackError'), { variant: 'error' });
		}

		return () => {
			try {
				resetFeedback();
			} catch (err) {}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [giveFeedbackStatus]);

	useEffect(() => {
		if (askFeedbackStatus === 'success') {
			enqueueSnackbar(t('feedbackRequest'), {
				variant: 'success',
				autoHideDuration: 3000,
			});
		} else if (askFeedbackStatus === 'error') {
			enqueueSnackbar(t('feedbackRequestError'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}

		return () => {
			try {
				resetFeedback();
			} catch (err) {}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [askFeedbackStatus]);

	const closeSignalRUpdateDialog = () => {
		props.showRefreshSnackBar(false);
	};

	const handleDialogClose = () => {
		setAlreadyShown(true);
		setOpenDialog(false);
	};

	const handleNavigateToLearning = () => {
		const unique_name = jwtDecode(getLocalStorageItem('accessTokenId'))?.unique_name || '';
		const static_token = 'poiuytrewq0987654321';
		const body = `${unique_name}${static_token}`;
		const hash = CryptoJS.MD5(body);
		const paidServiceName =
			tenantData && tenantData.paidServiceName ? getEncryptString(tenantData.paidServiceName) : '';
		const payloadToSend = `?access_token=${accessTokenId}&securitykey(${hash})&subscription=${paidServiceName}`;
		const params = [];
		params['payload'] = payloadToSend;
		openPost(LEARNING, params);
	};

	const handleNavigateToUnlockU = (event, target) => {
		let navigate = checkEditOkr(event);
		if (navigate) {
			setLoader(true);
			let username = unlockEncryptString(tenantData.userEmail);
			if (userDetail?.isImpersonatedUser && userDetail?.emailId) {
				username = unlockEncryptString(userDetail?.emailId);
			}
			const company_identifier = unlockEncryptString(tenantData.tenantId);
			const static_token = unlockEncryptString(UNLOCKUTOKEN);
			const app_name = unlockEncryptString(UNLOCKAPPNAME);
			const payloadToSend = `?username=${username}&company_identifier=${company_identifier}&token=${static_token}&app_name=${app_name}${
				target ? '&okrCurrentState=' + unlockEncryptString(window.location.href) : ''
			}`;
			const params = [];
			const finalUrl = LEARNINGU ? LEARNINGU : getLearningUUrl();
			params['requestParams'] = payloadToSend;
			openPost(finalUrl, params, target || undefined);
		}
	};
	const handleClickGlobalTask = (event) => {
		let navigate = checkEditOkr(event);
		if (navigate) {
			props.updateRoute('/task');
			props.switchUser(event, 'user', GLOBAL_Task);
		}
	};
	const checkEditOkr = (event) => {
		if (okrCreationInProgress) {
			event.preventDefault();
			event.stopPropagation();
			dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...event } }));
			return false;
		}
		return true;
	};
	const { redirectedPerformanceGoalDetails, hidePerformaceCycle } = useSelector((state) => state.commonReducer);

	return (
		<Fragment>
			{userDetail && (
				<Fragment>
					{loader && (
						<Box className='loader-wrap'>
							<LinearLoader data={true} />
						</Box>
					)}
					{!props.fromTab && (
						<>
							<header className='app-header'>
								<Box className='left-menu-top'>
									{/* {userVersion?.showTooltip ? (
									<Tooltip
										open={true}
										title={
											<Box className='tooltip-text'>
												<HayHandIcon />
												<Typography dangerouslySetInnerHTML={{ __html: t('whatsNewTooltip') }}></Typography>
											</Box>
										}
										placement='bottom-start'
										arrow
										classes={{ popper: 'custom-tooltip-icon' }}
									>
										<IconButton
											color='inherit'
											aria-label={t('HeaderMainAriaLabel')}
											onClick={() => handleToggle(1)}
											edge='start'
											className='drawer-open'
											id='side-nav-bar'
										>
											{open ? <LeftArrow /> : <MenuIcon />}
										</IconButton>
									</Tooltip>
								) : (
									<IconButton
										color='inherit'
										aria-label={t('HeaderMainAriaLabel')}
										onClick={() => handleToggle(1)}
										edge='start'
										className='drawer-open'
										id='side-nav-bar'
									>
										{open ? <LeftArrow /> : <MenuIcon />}
									</IconButton>
								)} */}
									<Box className='app-logo'>
										<Typography variant='h1'>
											{/* <Tooltip title={`${t('dashboardLabel')}`} arrow placement={'right'}> */}
											<IconButton color='inherit' onClick={(e) => navigateToHomePage(e)} id='logo'>
												<Logo />
												<ClientLogo className='client-logo' />
											</IconButton>
											{/* </Tooltip> */}
										</Typography>
									</Box>
									<LeftTopMenu
										{...props}
										userDetail={userDetail}
										handleNavigateToLearning={handleNavigateToLearning}
										handleNavigateToUnlockU={handleNavigateToUnlockU}
										checkSupportPermission={checkSupportPermission}
									/>
								</Box>
								<Box className='left-menu-bottom'>
									{/* <LeftBottomMenu {...props} checkEditOkr={checkEditOkr} /> */}
									<MyAccount
										{...props}
										profileOpen={profileOpen}
										setProfileOpen={setProfileOpen}
										loginAs={props.loginAs}
									/>
								</Box>
							</header>
							<Box className='page-loader'>
								{loaderStatus && <LinearLoader data={listOkrSuccess === 'success' ? false : true} />}
							</Box>
						</>
					)}

					{props.isDashboard &&
					(props.currentRoute === DASHBOARD ||
						window?.location?.pathname === DASHBOARD ||
						window?.location?.pathname === HOME ||
						props.currentRoute === '/') ? (
						<Box onClick={(event) => checkDrawer(event)} className={`${props.fromTab ? '' : 'hideVisibility'}`}>
							<Box className='page-subheader-right-inner'>
								{/* <PrimarySearch {...props} />
								{props.listOkrResult && props.listOkrResult.isLocked && !currentUser && (
									<Tooltip title={`${t('lockTooltipText')}`} arrow>
										<Button
											disabled={currentUser ? true : false}
											className='btn-circle-white lockPurple'
											onClick={() => props.sendUnlockRequest()}
										>
											<LockIcon />
										</Button>
									</Tooltip>
								)}
								<TenureNavigation {...props} handleToggle={handleToggle} /> */}
								{window?.location?.pathname === HOME &&
								!hidePerformaceCycle &&
								checkSupportPermission(supportModuleAlignGoals) ? (
									<GoalCycleTab {...props} />
								) : (
									window?.location?.pathname === HOME &&
									(checkSupportPermission(supportModuleAlignOkr) ||
										checkSupportPermission(supportModuleAlignGoals)) && (
										<>
											<PrimarySearch {...props} />
											<TenureNavigation {...props} handleToggle={handleToggle} />
										</>
									)
								)}
								<OKRButton
									className='global-task-btn'
									icon={<TasksIcon />}
									title={t('tasksLabel')}
									handleClick={handleClickGlobalTask}
								/>
								<Notification {...props} />
							</Box>
						</Box>
					) : props.isEngage && props.currentRoute === ENGAGE ? (
						<Box onClick={(event) => checkDrawer(event)} className={`${props.fromTab ? '' : 'hideVisibility'}`}>
							<Box className='page-subheader-right-inner'>
								<OKRButton
									className='global-task-btn'
									icon={<TasksIcon />}
									title={t('tasksLabel')}
									handleClick={handleClickGlobalTask}
								/>
								<Notification {...props} />
							</Box>
						</Box>
					) : props.isLetter && props.currentRoute === LETTER_GEN ? (
						<Box onClick={(event) => checkDrawer(event)} className={`${props.fromTab ? '' : 'hideVisibility'}`}>
							<Box className='page-subheader-right-inner'>
								<OKRButton
									className='global-task-btn'
									icon={<TasksIcon />}
									title={t('tasksLabel')}
									handleClick={handleClickGlobalTask}
								/>
								<Notification {...props} />
							</Box>
						</Box>
					) : props.isRecognize && props.currentRoute === RECOGNIZE ? (
						<Box onClick={(event) => checkDrawer(event)} className={`${props.fromTab ? '' : 'hideVisibility'}`}>
							<Box className='page-subheader-right-inner'>
								<OKRButton
									className='global-task-btn'
									icon={<TasksIcon />}
									title={t('tasksLabel')}
									handleClick={handleClickGlobalTask}
								/>
								<Notification {...props} />
							</Box>
						</Box>
					) : props.isPerform && props.currentRoute === PERFORM ? (
						<Box onClick={(event) => checkDrawer(event)} className={`${props.fromTab ? '' : 'hideVisibility'}`}>
							<Box className='page-subheader-right-inner'>
								<OKRButton
									className='global-task-btn'
									icon={<TasksIcon />}
									title={t('tasksLabel')}
									handleClick={handleClickGlobalTask}
								/>
								<Notification {...props} />
							</Box>
						</Box>
					) : props.isPerformDevelopment && props.currentRoute === PERFORM_DEVELOPMENT ? (
						<Box onClick={(event) => checkDrawer(event)} className={`${props.fromTab ? '' : 'hideVisibility'}`}>
							<Box className='page-subheader-right-inner'>
								<OKRButton
									className='global-task-btn'
									icon={<TasksIcon />}
									title={t('tasksLabel')}
									handleClick={handleClickGlobalTask}
								/>
								<Notification {...props} />
							</Box>
						</Box>
					) : props.isGoalReview && props.currentRoute === GOAL_REVIEW ? (
						<Box onClick={(event) => checkDrawer(event)} className={`${props.fromTab ? '' : 'hideVisibility'}`}>
							<Box className='page-subheader-right-inner'>
								<Notification {...props} />
							</Box>
						</Box>
					) : props.isTask && (props.currentRoute === GLOBAL_Task || window?.location?.pathname === GLOBAL_Task) ? (
						<Box onClick={(event) => checkDrawer(event)} className={`${props.fromTab ? '' : 'hideVisibility'}`}>
							<Box className='page-subheader-right-inner'>
								<OKRButton
									className='global-task-btn'
									icon={<TasksIcon />}
									title={t('tasksLabel')}
									handleClick={handleClickGlobalTask}
								/>
								<Notification {...props} />
							</Box>
						</Box>
					) : (
						<Box onClick={(event) => checkDrawer(event)} className={`${props.fromTab ? '' : 'hideVisibility'}`}>
							<Box className='page-subheader-right-inner'>
								{!checkSupportPermission(supportModuleAlignGoals) && <PrimarySearch {...props} />}
								{props.listOkrResult && props.listOkrResult.isLocked && !currentUser && (
									<Tooltip
										title={`${
											props.listOkrResult && props.listOkrResult.isScoreLocked === true
												? t('lockTooltipText')
												: t('lockTooltipGraceText')
										}`}
										classes={{
											popper:
												props.listOkrResult && props.listOkrResult.isScoreLocked === true
													? 'tooltip-layout7'
													: 'tooltip-layout11',
										}}
										arrow
									>
										<Button
											disabled={currentUser ? true : false}
											className='btn-circle-white lockPurple'
											onClick={() => props.sendUnlockRequest()}
										>
											<LockIcon />
										</Button>
									</Tooltip>
								)}
								{/* {checkSupportPermission(supportModuleAlignGoals) ? (
									<GoalCycleTab {...props} />
								) : ( */}

								<TenureNavigation {...props} handleToggle={handleToggle} />

								{/* )} */}

								<OKRButton
									className='global-task-btn'
									icon={<TasksIcon />}
									title={t('tasksLabel')}
									handleClick={handleClickGlobalTask}
								/>
								<Notification {...props} />
							</Box>
						</Box>
					)}

					{/* <Drawer
						className='drawer-nav'
						anchor='left'
						open={open}
						onClose={handleDrawerClose}
						// onEscapeKeyDown={handleDrawerClose}
						// onBackdropClick={handleDrawerClose}
					>
						<DrawerList {...props} handleToggle={handleToggle} openGuidedMenu={openGuidedMenu} />
					</Drawer> */}
				</Fragment>
			)}

			{props.showRefreshOptionSnackBar && props.currentRoute === HOME && props.okrType === 'goal' && (
				<Dialog
					className='search-dialog'
					open={openDialog}
					onClose={closeSignalRUpdateDialog}
					aria-labelledby='responsive-dialog-title'
					TransitionComponent={Slide}
					transitionDuration={{ enter: 500, exit: 800 }}
					BackdropProps={{ invisible: true }}
				>
					<DialogContent>
						<Typography variant='h4' className='signalR'>
							{t('refreshUpdate')}
						</Typography>
						{/* <OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={handleDialogClose} /> */}
					</DialogContent>
				</Dialog>
			)}

			{userDetail?.isImpersonatedUser && !props.fromTab && (
				<Dialog
					className='search-dialog'
					open={openDialog}
					onClose={handleDialogClose}
					aria-labelledby='responsive-dialog-title'
					TransitionComponent={Slide}
					transitionDuration={{ enter: 500, exit: 800 }}
					BackdropProps={{ invisible: true }}
				>
					<DialogContent>
						<Typography className='signalR'>
							{t('impersonatingUser', { name: `${userDetail?.firstName} ${userDetail?.lastName}` })}
						</Typography>
						<OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={handleDialogClose} />
					</DialogContent>
				</Dialog>
			)}

			{/* {showTrialDialog && (
				<Dialog
					className='search-dialog'
					open={showTrialDialog}
					onClose={handleDialogCloseTrialExpiry}
					aria-labelledby='responsive-dialog-title'
					TransitionComponent={Slide}
					transitionDuration={{ enter: 500, exit: 800 }}
					BackdropProps={{ invisible: true }}
				>
					<DialogContent>
						<DialogContentText>
							<Typography variant='h4' className='signalR'>
								{t('instanceExpiryMsg')}
							</Typography>
							<OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={handleDialogCloseTrialExpiry} />
						</DialogContentText>
					</DialogContent>
				</Dialog>
			)} */}
		</Fragment>
	);
}
