import React, { useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CkEditor from '../Common/CkEditor/CkEditor';
import { AddIconSvg, CalendarIcon, CrossIcon, TimeIcon, TrainingIcon } from '../../config/svg/CommonSvgIcon';
import DummyImage from '../../images/dummy-training-image.jpg';
import { GreyLogo } from '../../config/svg/GlobalSvg';
import { Skeleton } from '@material-ui/lab';
import { OKRButton } from '../Common/OKRButton';

export const TeamReviewTrainings: React.FC<any> = (props) => {
	const {
		handleAddMoreTraining,
		trainingDataFinal,
		setTrainingDataFinal,
		finalRatingData,
		setFinalRatingData,
		finalRatingDataError,
		isEditable,
	} = props;
	const { t } = useTranslation();
	const formDisabled = false;
	const htmlContent = '<b>Approved</b> with <i>minor feedback</i>';
	const removeCourse = (item: any, index: number) => {
		let trainingDataFinalCopy = [...trainingDataFinal];
		trainingDataFinalCopy.splice(index, 1);
		setTrainingDataFinal(trainingDataFinalCopy);
	};
	return (
		<>
			<Box className='personalized-growth-card full-rectangular-card'>
				<Box className='personalized-growth-head'>
					<Box
						className={`personalized-growth-head-left ${
							finalRatingDataError && finalRatingDataError?.otherTrainings ? 'helperText-bottom' : ''
						}`}
					>
						<Typography variant='h4' className='font-weight-normal'>
							{t('personalizedGrowthPathways')}
						</Typography>
						<Typography variant='subtitle2' className='subtitle3'>
							{t('personalizedGrowthPathwaysText')}
						</Typography>
						{finalRatingDataError && finalRatingDataError?.otherTrainings && (
							<Typography className='error-field'>Please select either of 2 options</Typography>
						)}
					</Box>
					<Box className='personalized-growth-head-right'>
						{finalRatingData?.isSubmitBtnVissible && (
							<OKRButton
								className='add-btn'
								icon={<AddIconSvg />}
								title={t('Add Trainings')}
								handleClick={handleAddMoreTraining}
							/>
						)}
					</Box>
				</Box>
				{trainingDataFinal && trainingDataFinal?.length > 0 ? (
					<Box className='course-card-panel-list'>
						{trainingDataFinal.map((item: any, index: number) => (
							<Box className='course-card-panel'>
								<Box className='courses-img'>
									<img src={item.thumbnail} alt='course-img' />
									{finalRatingData?.isSubmitBtnVissible && (
										<OKRButton
											icon={<CrossIcon />}
											className={'btn-cross'}
											id={'btn-close'}
											handleClick={() => {
												removeCourse(item, index);
											}}
										/>
									)}
								</Box>
								<Box className='courses-content-section'>
									<Box className='course-content-header'>
										<Typography variant='subtitle2'>{item?.category}</Typography>
										<Box className='course-title'>
											<Tooltip classes={{ popper: 'tooltip-layout-trainings' }} title={item?.shortName} arrow>
												<Typography variant='h4' className='font-weight-normal'>
													{item?.shortName}{' '}
												</Typography>
											</Tooltip>
										</Box>
									</Box>
									<Box className='course-session'>
										<Box className='course-published'>
											<CalendarIcon />
											<Typography variant='h6'>{item?.startDate}</Typography>
										</Box>
									</Box>
									<Box className='course-description'>
										<Tooltip
											classes={{ popper: 'tooltip-layout-trainings' }}
											title={item?.description && <span dangerouslySetInnerHTML={{ __html: item?.description }} />}
											arrow
										>
											<Box dangerouslySetInnerHTML={{ __html: item?.description }}></Box>
										</Tooltip>
									</Box>
								</Box>
							</Box>
						))}
					</Box>
				) : (
					<Box className='course-card-panel-list'>
						{/* No Course section */}
						<Box className='course-card-panel'>
							<Box className='courses-img no-course-img'>
								<Box className='no-img-content'>
									{/* <GreyLogo />
								<Typography variant='h6'>{t('moreAacademiesText')}</Typography> */}
								</Box>
							</Box>
							<Box className='courses-content-section'>
								<Box className='course-content-header'>
									<Skeleton animation={false} width='70px'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
								<Box className='course-session'>
									<Box className='course-published'>
										<Skeleton animation={false} width='70px'>
											<Typography>.</Typography>
										</Skeleton>
									</Box>
									<Box className='courses-duration'>
										<Skeleton animation={false} width='70px'>
											<Typography>.</Typography>
										</Skeleton>
									</Box>
								</Box>
								<Box className='course-description'>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
							</Box>
						</Box>
						<Box className='course-card-panel'>
							<Box className='courses-img no-course-img'>
								<Box className='no-img-content'>
									{/* <GreyLogo />
								<Typography variant='h6'>{t('moreAacademiesText')}</Typography> */}
								</Box>
							</Box>
							<Box className='courses-content-section'>
								<Box className='course-content-header'>
									<Skeleton animation={false} width='70px'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
								<Box className='course-session'>
									<Box className='course-published'>
										<Skeleton animation={false} width='70px'>
											<Typography>.</Typography>
										</Skeleton>
									</Box>
									<Box className='courses-duration'>
										<Skeleton animation={false} width='70px'>
											<Typography>.</Typography>
										</Skeleton>
									</Box>
								</Box>
								<Box className='course-description'>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
							</Box>
						</Box>
						<Box className='course-card-panel'>
							<Box className='courses-img no-course-img'>
								<Box className='no-img-content'>
									{/* <GreyLogo />
								<Typography variant='h6'>{t('moreAacademiesText')}</Typography> */}
								</Box>
							</Box>
							<Box className='courses-content-section'>
								<Box className='course-content-header'>
									<Skeleton animation={false} width='70px'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
								<Box className='course-session'>
									<Box className='course-published'>
										<Skeleton animation={false} width='70px'>
											<Typography>.</Typography>
										</Skeleton>
									</Box>
									<Box className='courses-duration'>
										<Skeleton animation={false} width='70px'>
											<Typography>.</Typography>
										</Skeleton>
									</Box>
								</Box>
								<Box className='course-description'>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
							</Box>
						</Box>
						<Box className='course-card-panel'>
							<Box className='courses-img no-course-img'>
								<Box className='no-img-content'>
									{/* <GreyLogo />
								<Typography variant='h6'>{t('moreAacademiesText')}</Typography> */}
								</Box>
							</Box>
							<Box className='courses-content-section'>
								<Box className='course-content-header'>
									<Skeleton animation={false} width='70px'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
								<Box className='course-session'>
									<Box className='course-published'>
										<Skeleton animation={false} width='70px'>
											<Typography>.</Typography>
										</Skeleton>
									</Box>
									<Box className='courses-duration'>
										<Skeleton animation={false} width='70px'>
											<Typography>.</Typography>
										</Skeleton>
									</Box>
								</Box>
								<Box className='course-description'>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton animation={false} width='100%'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
							</Box>
						</Box>
					</Box>
				)}
				<Box className='other-trainings-comment-box'>
					<Box className='other-trainings-label'>
						<TrainingIcon />
						<Typography variant='h4' className='font-weight-normal'>
							{t('otherTrainings')}
						</Typography>
					</Box>
					<Box className={`other-trainings-comment-field helperText-bottom editior-pane long-text preview-overlay`}>
						{finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 || !finalRatingData.isSubmitBtnVissible ? (
							<Box className='view-only-comment long-text'>
								<span dangerouslySetInnerHTML={{ __html: finalRatingData.otherTrainings || 'No comment' }}></span>
							</Box>
						) : (
							<>
								{isEditable ? (
									<CkEditor
										placeholder={t('recommendSomeOtherTrainings')}
										showEmoticons={true}
										value={finalRatingData.otherTrainings}
										handleEditorChange={(value: string) => {
											if (value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>') {
												setFinalRatingData({ ...finalRatingData, otherTrainings: '' });
											} else {
												setFinalRatingData({ ...finalRatingData, otherTrainings: value });
											}
										}}
									/>
								) : (
									<span dangerouslySetInnerHTML={{ __html: finalRatingData.otherTrainings }}></span>
								)}
							</>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};
