import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormControlLabel, IconButton, Link, Radio, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import exportFromJSON from 'export-from-json';
import { AssignUsersTab, AssignUsersTabPanel } from './AssignUsersTab';
import { GoalCycleAssignUsersList } from '../GoalCycle/GoalCycleAssignUsersList';
import { MultiSelect } from '../../../CommonComponent/MultiSelect';
import { OKRButton } from '../../../../Common/OKRButton';
import { CancelIcon, TickIcon } from '../../../../../config/svg/Action';
import { EnumPerformanceGoalCycleStatus, Enums } from '../../../../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import {
	assignUserSearchByName,
	validateBulkAssignedUser,
	validateManualAssignedUser,
} from '../../../../../action/adminSettings';
import { NoSearchRecord } from '../../../CommonComponent/NoSearchRecord';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { AlertInfoIcon, DownloadIcon, HayHandIcon } from '../../../../../config/svg/CommonSvg';
import ReactDateRangePicker from '../../../../Common/ReactDateRangePicker';
import { formatFeedbackDate } from '../../../../../config/utils';
import { UploadDocuments } from '../../../../PerformanceDevelopment/Common/UploadDocuments';
import { BulkAssignUsers } from './BulkAssignUsers';

export const AssignUsers: React.FC<any> = (props: any) => {
	const {
		setIsGoalCycleFormEdited,
		selectedUsersOptions,
		setSelectedUsersOptions,
		setLoader,
		loader,
		goalCycleAssignedUserError,
		setGoalCycleAssignedUserError,
		goalCycleData,
		setGoalCycleData,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const minDateValue = new Date();
	minDateValue.setFullYear(minDateValue.getFullYear() - 50);
	const maxDateValue = new Date();

	const [selectedAssignUsersTab, setSelectedAssignUsersTab] = useState<any>(0);
	const [selectedUser, setSelectedUser] = useState([]);
	const [userListOptions, setUserListOptions] = useState<any>([]);
	const [emptyRecords, setEmptyRecords] = useState<any>({
		user: false,
	});
	const [focussed, setFocussed] = React.useState<boolean>(false);
	const [errorLogMessage, setErrorLogMessage] = useState([]);
	const [errorRecordCount, setErrorRecordCount] = useState<any>({});
	const [isSingleDateRangePicker, setIsSingleDateRangePicker] = useState<boolean>(false);
	const [radioButtonDefaultValue, setRadioButtonDefaultValue] = useState<any>('1');
	const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
	const [disableToggle, setDisableToggle] = useState<boolean>(false);
	const [currentUsersAddedCount, setCurrentUsersAddedCount] = useState<number>(0);
	const [currentUsersRemovedCount, setCurrentUsersRemovedCount] = useState<number>(0);
	const [currentUsersErrorCount, setCurrentUsersErrorCount] = useState<number>(0);

	const [uploadFileData, setUploadFileData] = useState({
		filePath: '',
		fileName: '',
		fileUploaded: false,
	});
	useEffect(() => {
		if (currentUsersErrorCount > 0) {
			setTimeout(() => {
				setCurrentUsersErrorCount(0);
			}, 10000);
		}
	}, [currentUsersErrorCount]);

	const setTabValue = (newValue: Number) => {
		setSelectedAssignUsersTab(newValue);
	};

	const handleAssignUsersTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		setTabValue(newValue);
	};

	// ==============================================================================
	//               User name Tab Start
	// ===============================================================================
	const handleRadioButtonChange = (e: any) => {
		const { target } = e;
		setSelectedEndDate(null);
		setRadioButtonDefaultValue(target.value);
		setIsSingleDateRangePicker(target.checked && target.value === '1' ? false : true);
	};

	const onSelectedUser = (user: any) => {
		setSelectedUser(user);
		setUserListOptions([]);
		setIsGoalCycleFormEdited(true);
		setFocussed(true);
		setEmptyRecords({ ...emptyRecords, user: false });
	};

	const getUserListDetails = async (requestPayload: any) => {
		const response: any = await dispatch(assignUserSearchByName(requestPayload));
		if (response) {
			if (response && response.data && response.data.status === 200) {
				const { entity } = response.data;
				const { records } = entity;
				let optionsList = records.map((data: any) => {
					return {
						...data,
						label: data.firstName + ' ' + data.lastName,
						value: data.employeeId,
						secondaryText: data.emailId,
					};
				});
				setEmptyRecords({ ...emptyRecords, user: false });
				setUserListOptions(optionsList);
			} else {
				setEmptyRecords({ ...emptyRecords, user: true });
				setUserListOptions([]);
			}
		} else {
			setLoader(false);
			setEmptyRecords({ ...emptyRecords, user: false });
		}
	};

	const validateManualAssignedUserList = async (requestPayload: any, type: any = 0) => {
		setLoader(true);
		const response: any =
			type === 4 //4 for bulk
				? await dispatch(validateBulkAssignedUser(requestPayload))
				: await dispatch(validateManualAssignedUser(requestPayload));
		if (response) {
			setLoader(false);
			if (Boolean(response) && response.data && response.data && response.data.status === 200) {
				if (response.data.entity && response.data.entity.errorRecordCount > 0) {
					const errorDetail = response.data.entity.errors;
					setErrorLogMessage(errorDetail);
					setErrorRecordCount(response.data.entity.errorRecordCount);
					setCurrentUsersErrorCount(response.data.entity.errorRecordCount);
					setUserListOptions([]);
				}

				if (response.data.entity && response.data.entity.assignedUserCount > 0) {
					const { entity } = response.data;
					const uniqueSelectedUsers: any = [];
					entity?.performanceGoalCycleEmployeeResponse.forEach((item: any) => {
						const isExist =
							selectedUsersOptions && selectedUsersOptions?.find((rec: any) => rec?.employeeId === item?.employeeId);
						if (!isExist) {
							uniqueSelectedUsers.push({ ...item });
						}
					});
					const allUsers = [...uniqueSelectedUsers, ...selectedUsersOptions];
					setCurrentUsersAddedCount(allUsers?.length - selectedUsersOptions?.length > 0 ? allUsers?.length : 0);
					setSelectedUsersOptions(allUsers);
					setGoalCycleAssignedUserError({
						error: '',
						helperText: '',
						type: '',
					});
				}
				setLoader(false);
			}
		} else {
			setLoader(false);
		}
	};

	const handleUserSearch = async (data: string) => {
		if (data && data.length >= 3) {
			await getUserListDetails({
				finder: data,
				pageIndex: Enums.ONE,
				pageSize: Enums.MAX_USER_SIZE,
				sortingText: 'firstname',
				order: 'asc',
			});
		}
	};

	const handleUsersSave = async (type: any, fileObj: any = null) => {
		if (type === 1 && selectedUser && selectedUser?.length) {
			const employeeIds = selectedUser.map((emp: any) => emp.employeeId);
			await validateManualAssignedUserList(
				{
					employeeIds: employeeIds,
					joiningDateSelectionType: Enums.ZERO,
					isAssignToAllEmployee: false,
					performanceGoalCycleId: goalCycleData?.performanceGoalCycleId || Enums.ZERO,
					startDate: goalCycleData?.startDate ? formatFeedbackDate(goalCycleData?.startDate) : null,
					endDate: goalCycleData?.endDate ? formatFeedbackDate(goalCycleData?.endDate) : null,
					joiningDateStart: null,
					joiningDateEnd: null,
				},
				type
			);
			setSelectedUser([]);
		}
		if (type === 2 && selectedEndDate) {
			await validateManualAssignedUserList(
				{
					employeeIds: [],
					joiningDateSelectionType: parseInt(radioButtonDefaultValue),
					isAssignToAllEmployee: false,
					performanceGoalCycleId: goalCycleData?.performanceGoalCycleId || Enums.ZERO,
					startDate: goalCycleData?.startDate ? formatFeedbackDate(goalCycleData?.startDate) : null,
					endDate: goalCycleData?.endDate ? formatFeedbackDate(goalCycleData?.endDate) : null,
					joiningDateStart: isSingleDateRangePicker
						? selectedEndDate
							? formatFeedbackDate(selectedEndDate)
							: null
						: selectedEndDate.start
						? formatFeedbackDate(selectedEndDate.start)
						: null,
					joiningDateEnd: selectedEndDate.end ? formatFeedbackDate(selectedEndDate.end) : null,
				},
				type
			);
			setSelectedEndDate(null);
		}
		if (type === 3) {
			setDisableToggle(true);
			await validateManualAssignedUserList(
				{
					employeeIds: [],
					joiningDateSelectionType: Enums.ZERO,
					isAssignToAllEmployee: true,
					performanceGoalCycleId: goalCycleData?.performanceGoalCycleId || Enums.ZERO,
					startDate: goalCycleData?.startDate ? formatFeedbackDate(goalCycleData?.startDate) : null,
					endDate: goalCycleData?.endDate ? formatFeedbackDate(goalCycleData?.endDate) : null,
					joiningDateStart: null,
					joiningDateEnd: null,
				},
				type
			);
			setDisableToggle(false);
		}
		if (type === 4) {
			if (fileObj.fileName !== '') {
				const formData = new FormData();
				formData.append('formFile', fileObj.filePath);
				formData.append('performanceGoalCycleId', goalCycleData?.performanceGoalCycleId || Enums.ZERO);
				formData.append('startDate', goalCycleData?.startDate ? formatFeedbackDate(goalCycleData?.startDate) : '');
				formData.append('endDate', goalCycleData?.endDate ? formatFeedbackDate(goalCycleData?.endDate) : '');
				await validateManualAssignedUserList(formData, type);
			}
			setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
			scrollTo('container-assigned-users-list-area');
		}
	};

	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 50 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 300);
	};
	const handleUsersCancel = (type: any) => {
		if (type === 1) {
			setSelectedUser([]);
		}
		if (type === 2) {
			setSelectedEndDate(null);
		}
		if (type === 3) {
			setCurrentUsersRemovedCount(selectedUsersOptions?.length - goalCycleData?.assignedUsersHistory?.length);
			setSelectedUsersOptions(goalCycleData?.assignedUsersHistory || []);
			setErrorLogMessage([]);
			setCurrentUsersErrorCount(0);
		}
		if (type === 4) {
			setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
		}
	};

	const exportToExcel = () => {
		const updatedErrorLogMessage = errorLogMessage.map((item: any) => {
			return {
				'Record Row & Name': item.key, // Replace 'key' with 'Record Row & Name'
				'Error Description': item.value, // Replace 'value' with 'Error Description'
			};
		});
		exportFromJSON({ data: updatedErrorLogMessage, fileName: 'error_report', exportType: 'csv' });
	};

	// ==============================================================================
	//               User name Tab End
	// ===============================================================================

	return (
		<Box className='full-rectangular-card assign-users-card'>
			<Box
				className={`assign-users-header ${
					goalCycleAssignedUserError?.type === 'assignedUser' ? 'helperText-bottom' : ''
				}`}
			>
				<Typography variant='h4' className='font-weight-normal'>
					{t('assigneUser')}
				</Typography>
				{goalCycleAssignedUserError?.type === 'assignedUser' && goalCycleAssignedUserError?.helperText ? (
					<Typography className='error-field'>
						<ErrorIcon /> {goalCycleAssignedUserError?.helperText}
					</Typography>
				) : (
					<></>
				)}
			</Box>
			<Box className='assign-users-tab-section'>
				<AssignUsersTab
					{...props}
					selectedAssignUsersTab={selectedAssignUsersTab}
					handleAssignUsersTabChange={handleAssignUsersTabChange}
					handleUsersSave={handleUsersSave}
					handleUsersCancel={handleUsersCancel}
					setSelectedUsersOptions={setSelectedUsersOptions}
					disableToggle={disableToggle}
					goalCycleData={goalCycleData}
				/>
			</Box>
			<Box className='assign-users-tab-content'>
				<AssignUsersTabPanel value={selectedAssignUsersTab} index={0}>
					<Box className={`select-role-fields ${selectedUser && selectedUser?.length ? 'selected' : ''}`}>
						<MultiSelect
							key={'default_okr_assign_users_search'}
							id='default_okr_assign_users_search'
							selectedOptions={selectedUser}
							optionsList={userListOptions}
							onSelectOption={(value: any) => {
								onSelectedUser(value);
							}}
							blurCalled={(value: any) => {
								setUserListOptions([]);
							}}
							blurInputOnSelect={true}
							isMulti={true}
							customFilter={() => {}}
							setOptionsList={userListOptions}
							isLoadMore={false}
							placeHolder={t('userPlaceholder')}
							noOptionsMessage={t('userNotFound')}
							showNoOptions={emptyRecords.user === true}
							fetchAsyncData={true}
							isSingleSelection={false}
							closeMenuOnSelect={true}
							labelTemplate={'avatarLabel'}
							selectClassName={`select-search-dropdown select-search-icon`}
							selectClassNamePrefix={'react-select'}
							onInputFocus={() => {
								setEmptyRecords({ ...emptyRecords, user: false });
							}}
							performSearch={handleUserSearch}
							hideLoggedIn={true}
							loggedInUserIds={[]}
							emptyRecords={emptyRecords}
							setEmptyRecords={setEmptyRecords}
							disabled={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
						/>
						{selectedUser && selectedUser?.length ? (
							<Box className='textfield-action-btn'>
								<OKRButton
									className='cancel-btn'
									id={'cancel-btn'}
									icon={<CancelIcon />}
									handleClick={() => {
										handleUsersCancel(1);
									}}
								/>
								<OKRButton
									className='save-btn'
									id={'save-btn'}
									icon={<TickIcon />}
									handleClick={() => {
										handleUsersSave(1);
									}}
								/>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</AssignUsersTabPanel>
				{/* <AssignUsersTabPanel value={selectedAssignUsersTab} index={1}></AssignUsersTabPanel> */}
				<AssignUsersTabPanel value={selectedAssignUsersTab} index={2}>
					<Box className='select-doj-fields'>
						<RadioGroup
							row
							aria-label='cycleDuration'
							name='cycleDuration'
							value={`${radioButtonDefaultValue}`}
							onChange={(e) => handleRadioButtonChange(e)}
						>
							<FormControlLabel value={'1'} control={<Radio />} label={t('inBetweenLabel')} />
							<FormControlLabel value={'2'} control={<Radio />} label={t('beforeLabel')} />
							<FormControlLabel value={'3'} control={<Radio />} label={t('afterLabel')} />
						</RadioGroup>
						<Box className={`select-doj-range ${selectedEndDate ? 'date-range-selected' : ''}`}>
							<ReactDateRangePicker
								type={'ContributorList'}
								selectedCycleDetails={{}}
								minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
								maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
								handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
									if (range) {
										setSelectedEndDate(range);
										//updateFeedbackFormDetails('timeframeEndDate', range);
									}
								}}
								isDateRangeTextFieldVisible={true}
								isOnlyDueDateVisible={false}
								allCycleDetails={[]}
								isCycleVisible={false}
								isEndOfMonthVisible={false}
								numberOfCalendars={isSingleDateRangePicker ? Enums.ONE : Enums.TWO}
								selectionType={isSingleDateRangePicker ? 'single' : 'range'}
								calendarTitle={isSingleDateRangePicker ? t('dateCalenderTitle') : t('dateRangeTitle')}
								selectedDateRange={selectedEndDate || null}
								setSelectedDateRange={(range: any) => {
									if (range) {
										//updateFeedbackFormDetails('timeframeEndDate', range);
										setSelectedEndDate(range);
									}
								}}
								placeholderText={isSingleDateRangePicker ? t('selectedDate') : t('selectedDateRange')}
								isDatePickerDisable={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
							/>
							{selectedEndDate ? (
								<Box className='textfield-action-btn'>
									<OKRButton
										className='cancel-btn'
										id={'cancel-btn-joining-date'}
										icon={<CancelIcon />}
										handleClick={() => {
											handleUsersCancel(2);
										}}
									/>
									<OKRButton
										className='save-btn'
										id={'save-btn-joining-date'}
										icon={<TickIcon />}
										handleClick={() => {
											handleUsersSave(2);
										}}
									/>
								</Box>
							) : (
								<></>
							)}
						</Box>
					</Box>
				</AssignUsersTabPanel>
				<AssignUsersTabPanel value={selectedAssignUsersTab} index={3}>
					<Box className='assign-users-bulk-field'>
						<BulkAssignUsers
							{...props}
							uploadFileData={uploadFileData}
							setUploadFileData={setUploadFileData}
							handleUsersCancel={handleUsersCancel}
							handleUsersSave={handleUsersSave}
						></BulkAssignUsers>
					</Box>
				</AssignUsersTabPanel>
				<Box className='assigned-users-list-area-outer'>
					{errorLogMessage && errorLogMessage.length > 0 && (
						<Box className='upload-error-result'>
							<Box className='upload-error-mssg'>
								{currentUsersErrorCount > 0 ? (
									<Tooltip
										placement='right'
										open={true}
										arrow
										classes={{ popper: 'assign-user-error-tooltip' }}
										title={
											<Box className='tooltip-text'>
												<HayHandIcon />
												<Typography
													dangerouslySetInnerHTML={{
														__html: t('assignedUsersErrorText', { userCount: currentUsersErrorCount }),
													}}
												></Typography>
											</Box>
										}
									>
										<Box className='upload-error-mssg-title'>
											<AlertInfoIcon />

											<Typography variant='h6' className='text-upper-case'>
												{errorRecordCount && `${t('erroredRecords')} (${errorRecordCount})`}
											</Typography>
										</Box>
									</Tooltip>
								) : (
									<Box className='upload-error-mssg-title'>
										<AlertInfoIcon />
										<Typography variant='h6' className='text-upper-case'>
											{errorRecordCount && `${t('erroredRecords')} (${errorRecordCount})`}
										</Typography>
									</Box>
								)}
								<Box className='upload-error-mssg-desc'>
									<Typography variant='h6'>{t('erroredRecordsNote')}</Typography>
								</Box>
							</Box>
							<OKRButton
								id='download-error-btn'
								className='btn-circle-white download-btn'
								icon={<DownloadIcon />}
								title={t('downloadErrorReportsText')}
								handleClick={exportToExcel}
							/>
						</Box>
					)}
					<GoalCycleAssignUsersList
						{...props}
						selectedUsersOptions={selectedUsersOptions}
						setSelectedUsersOptions={setSelectedUsersOptions}
						setIsGoalCycleFormEdited={setIsGoalCycleFormEdited}
						goalCycleData={goalCycleData}
						loader={loader}
						setGoalCycleData={setGoalCycleData}
						currentUsersAddedCount={currentUsersAddedCount}
						setCurrentUsersAddedCount={setCurrentUsersAddedCount}
						currentUsersRemovedCount={currentUsersRemovedCount}
						setCurrentUsersRemovedCount={setCurrentUsersRemovedCount}
					/>
				</Box>
			</Box>
		</Box>
	);
};
